import {Link} from 'react-router-dom';
import {transformBetString} from '../../../utils/sportStats/oddsFunctions';
import {formatDateRelativeToToday} from '../../../utils/dateUtils';

export const Schedule = ({schedules, team, bgColor}) => {
  return (
    <div className="w-full bg-white">
      <table className="w-full border-collapse border-2 border-gray-400">
        <thead className="bg-slate-200" style={{backgroundColor: bgColor}}>
          <tr>
            <th
              colSpan="4"
              className="py-2 px-4 font-bold text-lg border-2 border-gray-400 text-start"
            >
              {schedules[0]?.season_type}
            </th>
          </tr>
          <tr>
            <th className="font-bold px-1 py-2 border-2 border-gray-400">
              Date
            </th>
            <th className="font-bold px-1 py-2 border-2 border-gray-400">
              Teams (H-A)
            </th>
            <th className="font-bold px-1 py-2 border-2 border-gray-400">
              ATS
            </th>
            <th className="font-bold px-1 py-2 border-2 border-gray-400">
              O/U
            </th>
          </tr>
        </thead>
        <tbody>
          {schedules &&
            schedules.length > 0 &&
            schedules.map((game, index) => (
              <tr key={index} className="border-2 border-gray-400">
                <td className="px-3 py-1 border-2 border-gray-400">
                  {formatDateRelativeToToday(game.start_date)}
                </td>
                <td className="px-3 py-1 border-2 border-gray-400">
                  <Link
                    to={`/sports/propBets/${game.game_id}`}
                    className="flex items-center justify-around"
                  >
                    <div>
                      <img
                        className="w-6 lg:w-8 h-6 lg:h-8"
                        src={
                          game.home_team_info?.logo ||
                          'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png'
                        }
                        alt="Home Team"
                        loading="lazy"
                      />
                      <span className="font-bold underline text-xs lg:text-base">
                        {game.home_team_info?.team_abbreviation}
                      </span>
                    </div>
                    <span className="font-bold text-xs lg:text-base">VS</span>
                    <div>
                      <img
                        className="w-6 lg:w-8 h-6 lg:h-8"
                        src={
                          game.away_team_info?.logo ||
                          'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png'
                        }
                        alt="Away Team"
                        loading="lazy"
                      />
                      <span className="font-bold underline text-xs lg:text-base">
                        {game.away_team_info?.team_abbreviation}
                      </span>
                    </div>
                  </Link>
                </td>
                <td className="px-3 py-1 border-2 border-gray-400 font-bold">
                  {game.ats}
                </td>
                <td className="px-3 py-1 border-2 border-gray-400 text-black text-lg font-normal leading-relaxed tracking-tight">
                  {transformBetString(game.odds, team)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
