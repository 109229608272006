import React from 'react';
import { useNavigate } from 'react-router-dom';

const Tooltip = ({ message }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/register'); 
  };

  return (
    <button 
      onClick={handleClick}
      className="cursor-pointer fixed bottom-5 left-1/2 -translate-x-1/2 bg-primary text-black text-sm py-2 px-4 rounded-md shadow-lg z-50"
    >
      <p>{message}</p>
    </button>
  );
};

export default Tooltip;
