import axiosInstance from "../../core/axios/axiosInstance";

const getLatestNews = async () => {
  const response = await axiosInstance.get('/news/latest-news');
  return response.data;
};

const searchNews = async (topic) => {
  const response = await axiosInstance.get('/news/search', { topic });
  return response.data;
};

export const newsService = {
  getLatestNews,
  searchNews
}