import { useEffect, useState } from 'react';
import { Feed } from '../../components/FeedComponent';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { TrendingTicker } from '../../components/TrendingTicker';
import { TopicsToFollow } from './components/TopicsToFollow';
import { Trending } from './components/Trending';
import { WhoToFollow } from './components/WhoToFollow';
import { useDispatch, useSelector } from 'react-redux';
// import { BottomBar } from '../../components/Sidebar/BottomBar';
import { getLatestNews } from '../../redux/actions/NewsActions';
import { checkAccountIsPremium } from '../../redux/actions/UserActions';
import { PaywallComponent } from '../../components/PaywallComponent';
import '../../styles/mobile/feedMobile.css'

export const FeedView = ({ darkMode }) => {
  const latestNews = useSelector(state => state.latestNews.latest);
  const [isPremium, setIsPremium] = useState(false);
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo && userInfo.id) {
      dispatch(checkAccountIsPremium(userInfo.id)).then((result) => {
        setIsPremium(result.isPremium);
      });
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    dispatch(getLatestNews());
  }, [dispatch]);

  return (
    <div className={`w-full h-screen ${darkMode ? 'bg-black text-white' : 'bg-neutral-500 text-gray-900'} flex flex-row overflow-hidden`}>
      <Sidebar darkMode={darkMode}/>
      <div className="flex flex-col h-screen overflow-y-auto overflow-x-hidden">
        <div className={`sticky top-0 z-10 ${darkMode ? 'bg-black' : 'bg-white'}`}>
          <Navbar title="Feed" darkMode={darkMode}/>
        </div>
        <div className={`TrendingTicker sticky top-24 ${darkMode ? 'bg-black' : 'bg-white'}`}>
          <TrendingTicker selectedButton="NBA" darkMode={darkMode}/>
        </div>
        <div className="mt-10 sm:ml-5 lg:ml-10 flex flex-col sm:flex-row sm:justify-center sm:h-full gap-3">
          <div className="w-full sm:w-2/5 lg:w-1/4">
            <Trending latestNews={latestNews} darkMode={darkMode} />
          </div>
          <div className="w-full sm:w-3/5 lg:w-2/5 mr-3 sm:mr-0 mb-24 sm:mb-0">
            {isPremium ? <Feed darkMode={darkMode} /> : <PaywallComponent isPremium={isPremium} darkMode={darkMode} />}
          </div>
          <div className="hidden lg:block w-1/4 mr-3 sm:mr-0 ">
            <WhoToFollow darkMode={darkMode} />
            <TopicsToFollow darkMode={darkMode} />
          </div>
        </div>
      </div>
      {/* <BottomBar darkMode={darkMode} /> */}
    </div>
  );
};
