import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSuggestedUsers } from '../../../redux/actions/UserActions';
import { followTarget } from '../../../redux/actions/FollowsActions';
import { Loading } from '../../../components/LoadingComponent';
import Tooltip from '../../../components/Tooltip';

export const WhoToFollow = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const suggestedUsers = useSelector(state => state.suggestedUsers);
  const { loading, error, users } = suggestedUsers;

  useEffect(() => {
    dispatch(getSuggestedUsers(userInfo?.id));
  }, [dispatch, userInfo?.id]);

  const handleFollow = async followedId => {
    try {
      if (userInfo && userInfo.id) {
        await dispatch(followTarget(userInfo?.id, followedId));
        dispatch(getSuggestedUsers(userInfo?.id)); // Refresh the suggested users list
      } else {
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 3000); // Hide tooltip after 3 seconds
      }
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  const handleProfileClick = userId => {
    if (userInfo && userInfo.id) {
      navigate(`/profile/${userId}`);
    } else {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 6000); // Hide tooltip after 3 seconds
    }
  };

  return users && users.length > 0 ? (
    <div className="border border-primary rounded-lg overflow-hidden">
      <div className="flex flex-col items-start rounded-t-lg bg-white w-full mb-6 ">
        <div className="flex justify-start items-center rounded-t-lg w-full h-12 px-4 py-3 bg-neutral-900  ">
          <div className="text-white text-xl font-extrabold leading-normal border-b-4 border-primary">
            Who to follow
          </div>
        </div>
        <div className="w-full flex flex-col bg-white space-y-4 ">
          {loading ? (
            <Loading />
          ) : error ? (
            <div>{error}</div>
          ) : (
            users.map(user => (
              <div
                key={user._id}
                className="w-full h-16 px-4 py-3 bg-white rounded-b-lg shadow flex items-center justify-between"
              >
                <div
                  onClick={() => handleProfileClick(user._id)}
                  className="flex items-start gap-2 w-3/4 justify-start cursor-pointer"
                >
                  <img
                    className="w-12 h-12 rounded-full shadow"
                    src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png"
                    alt="Round placeholder"
                    loading="lazy"
                  />
                  <div className="flex flex-col w-3/4">
                    <p className="text-neutral-900 text-base font-bold truncate">
                      {user.name}
                    </p>
                    <p className="text-zinc-500 text-xs font-normal leading-none truncate">
                      @{user.name} {/* Assuming the username field exists */}
                    </p>
                  </div>
                </div>
                <button
                  className="w-24 h-8 flex bg-primary items-center justify-center p-2.5 rounded-2xl border border-neutral-900"
                  onClick={() => handleFollow(user._id)}
                >
                  <p className="text-neutral-900 text-base font-bold leading-tight">
                    Follow
                  </p>
                </button>
              </div>
            ))
          )}
        </div>
        {showTooltip && (
          <Tooltip message="Join us to view other people's profiles!" />
          // Replace Tooltip with your tooltip or popup component
        )}
      </div>
    </div>
  ) : null;
};
