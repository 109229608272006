import {useState} from 'react';
import {sendSupportEmail} from '../../redux/actions/UserActions';
import {useDispatch, useSelector} from 'react-redux';

export const FeedbackModal = ({isVisible, onClose}) => {
  const [userMessage, setUserMessage] = useState('');
  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

  const dispatch = useDispatch();

  let userName;
  let userEmail;

  if (userInfo && userInfo.isVerified) {
    userName = userInfo?.name;
    userEmail = userInfo?.email;
  }

  // Handler for sending feedback
  const handleSendFeedback = async () => {
    if (!userMessage.trim()) {
      alert('Please enter a message before sending.');
      return;
    }
    try {
      await dispatch(sendSupportEmail({userEmail, userName, userMessage}));
      alert('Feedback sent successfully!');
      onClose();
    } catch (error) {
      console.error('Failed to send feedback:', error);
      alert(
        'There was a problem sending your feedback. Please try again later.',
      );
    }
  };

  return (
    isVisible && (
      <div
        className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
        onClick={onClose}
      >
        <div
          className="bg-white rounded-xl shadow-lg z-50 overflow-hidden w-3/5 lg:w-1/2 xl:w-2/5"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col w-full p-5 h-full justify-center items-center">
            <h1 className="opacity-95 text-neutral-900 text-3xl font-bold mb-2">
              Give Feedback
            </h1>
            <textarea
              className="w-full h-40 p-2 border rounded-lg"
              placeholder="Your feedback here..."
              value={userMessage}
              onChange={e => setUserMessage(e.target.value)}
            ></textarea>
            <button
              className="mt-4 px-5 py-2 bg-primary text-black rounded-lg"
              onClick={handleSendFeedback}
            >
              Submit Feedback
            </button>
          </div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      </div>
    )
  );
};
