export const FOLLOW_TARGET_REQUEST = 'FOLLOW_TARGET_REQUEST';
export const FOLLOW_TARGET_SUCCESS = 'FOLLOW_TARGET_SUCCESS';
export const FOLLOW_TARGET_FAIL = 'FOLLOW_TARGET_FAIL';

export const UNFOLLOW_TARGET_REQUEST = 'UNFOLLOW_TARGET_REQUEST';
export const UNFOLLOW_TARGET_SUCCESS = 'UNFOLLOW_TARGET_SUCCESS';
export const UNFOLLOW_TARGET_FAIL = 'UNFOLLOW_TARGET_FAIL';

export const GET_TARGET_FOLLOWERS_REQUEST = 'GET_TARGET_FOLLOWERS_REQUEST';
export const GET_TARGET_FOLLOWERS_SUCCESS = 'GET_TARGET_FOLLOWERS_SUCCESS';
export const GET_TARGET_FOLLOWERS_FAIL = 'GET_TARGET_FOLLOWERS_FAIL';

export const GET_USER_FOLLOWERS_REQUEST = 'GET_USER_FOLLOWERS_REQUEST';
export const GET_USER_FOLLOWERS_SUCCESS = 'GET_USER_FOLLOWERS_SUCCESS';
export const GET_USER_FOLLOWERS_FAIL = 'GET_USER_FOLLOWERS_FAIL';

export const GET_USER_FRIENDS_DATA_REQUEST = 'GET_USER_FRIENDS_DATA_REQUEST';
export const GET_USER_FRIENDS_DATA_SUCCESS = 'GET_USER_FRIENDS_DATA_SUCCESS';
export const GET_USER_FRIENDS_DATA_FAIL = 'GET_USER_FRIENDS_DATA_FAIL';

export const AM_I_FOLLOWING_REQUEST = 'AM_I_FOLLOWING_REQUEST';
export const AM_I_FOLLOWING_SUCCESS = 'AM_I_FOLLOWING_SUCCESS';
export const AM_I_FOLLOWING_FAIL = 'AM_I_FOLLOWING_FAIL';

export const AM_I_FOLLOWED_BY_REQUEST = 'AM_I_FOLLOWED_BY_REQUEST';
export const AM_I_FOLLOWED_BY_SUCCESS = 'AM_I_FOLLOWED_BY_SUCCESS';
export const AM_I_FOLLOWED_BY_FAIL = 'AM_I_FOLLOWED_BY_FAIL';

export const REMOVE_FOLLOWER_REQUEST = 'REMOVE_FOLLOWER_REQUEST';
export const REMOVE_FOLLOWER_SUCCESS = 'REMOVE_FOLLOWER_SUCCESS';
export const REMOVE_FOLLOWER_FAIL = 'REMOVE_FOLLOWER_FAIL';
