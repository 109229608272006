import React, { useState, createContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import icons from '../../assets/icons';
import '../../styles/Dashboard/dashboardStyle.css';
import { NotificationsModal } from '../modals/NotificationsModal';
import { SupportModal } from '../modals/SupportModal';
import { GetPremiumModal } from '../modals/GetPremiumModal';
import { ProfileModal } from '../modals/ProfileModal';
import { SidebarFooter } from './SidebarFooter';
import SidebarMainItems from './SidebarMainItems';

export const SidebarContext = createContext();

export const Sidebar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth > 768);
  const [bottomBar, setBottomBar] = useState(window.innerWidth > 640);
  const [expanded, setExpanded] = useState(!isMobile);
  const [isNotificationsModalVisible, setNotificationsModalVisible] = useState(false);
  const [isSupportModalVisible, setSupportModalVisible] = useState(false);
  const [isPremiumModalVisible, setPremiumModalVisible] = useState(false);
  const [isProfileModalVisible, setProfileModalVisible] = useState(false);

  const userDetails = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

  const location = useLocation();
  const activePath = location.pathname;

  
  // Event listener to update 'isMobile' based on window width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setBottomBar(window.innerWidth < 640);
      if (window.innerWidth < 768) {
        setExpanded(false); // Automatically set to collapsed on small screens
      }
    };

    window.addEventListener('resize', handleResize);

    // Initialize the isMobile state on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    // Only expand if not on mobile
    if (!isMobile) {
      setExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    // Only collapse if not on mobile
    if (!isMobile) {
      setExpanded(false);
    }
  };

  return (
    <aside
      className={`${bottomBar ? 'hidden' : 'flex'} h-screen`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <nav
        className={`Sidebar h-full flex flex-col justify-between bg-neutral-800 border-r shadow-sm transition-all duration-500 ease-in-out ${
          expanded ? 'w-64' : 'w-20'
        }`}
      >
        <div>
          <Link
            to={`/`}
            className="p-4 pb-2 flex justify-between items-center"
          >
            {expanded ? (
              <img
                className="LogoHorWhiteColor w-44 h-10 mb-8 mt-4"
                src={icons.sidebarLogo}
                alt="img"
                loading="lazy"
              />
            ) : (
              <img
                className="LogoHorWhiteColor h-10 mb-8 mt-4"
                src={icons.logo}
                alt="img"
                loading="lazy"
              />
            )}
          </Link>

          <SidebarContext.Provider
            value={{ activePath, expanded }}
          >
            <SidebarMainItems />
          </SidebarContext.Provider>
        </div>

        <SidebarContext.Provider
          value={{ activePath, expanded }}
        >
          <SidebarFooter
            setNotificationsModalVisible={setNotificationsModalVisible}
            setSupportModalVisible={setSupportModalVisible}
            setPremiumModalVisible={setPremiumModalVisible}
            setProfileModalVisible={setProfileModalVisible}
            userDetails={userDetails}
          />
        </SidebarContext.Provider>
      </nav>
      
      <NotificationsModal
        isVisible={isNotificationsModalVisible}
        onClose={() => {
          setNotificationsModalVisible(false);
        }}
      />

      <SupportModal
        isVisible={isSupportModalVisible}
        onClose={() => {
          setSupportModalVisible(false);
        }}
      />

      <GetPremiumModal
        isVisible={isPremiumModalVisible}
        onClose={() => {
          setPremiumModalVisible(false);
        }}
      />

      <ProfileModal
        isVisible={isProfileModalVisible}
        onClose={() => {
          setProfileModalVisible(false);
        }}
        userId={userDetails?.id}
      />
    </aside>
  );
};


