import axiosInstance from "../../core/axios/axiosInstance";

const followTarget = async (followerId, followedId) => {
  const response = await axiosInstance.post(`/follows/followTarget`, {followerId, followedId});
  return response.data;
};

const unfollowTarget = async (followerId, followedId) => {
  const response = await axiosInstance.delete(`/follows/unfollowTarget`, {followerId, followedId});
  return response.data;
};

const getTargetFollowers = async (targetId) => {
  const response = await axiosInstance.get(`/follows/getTargetFollowers/${targetId}`);
  return response.data;
};

const getUserFriends = async (userId) => {
  const response = await axiosInstance.get(`/follows/getUserFriends/${userId}`);
  return response.data;
};

const getUserFollowers = async (userId) => {
  const response = await axiosInstance.get(`/follows/getUserFollowers/${userId}`);
  return response.data;
};

const amIFollowing = async (myId, targetUserId) => {
  const response = await axiosInstance.get(`/follows/amIFollowing/${myId}/${targetUserId}`, {checkType: 'following'});
  return response.data;
};

const amIFollowedBy = async (myId, targetUserId) => {
  const response = await axiosInstance.get(`/follows/amIFollowedBy/${myId}/${targetUserId}`, {checkType: 'followedBy'});
  return response.data;
};

const removeFollower = async (followerId) => {
  const response = await axiosInstance.get(`/follows/removeFollower/${followerId}`);
  return response.data;
};

export const followService = {
  followTarget,
  unfollowTarget,
  getTargetFollowers,
  getUserFriends,
  getUserFollowers,
  amIFollowing,
  amIFollowedBy,
  removeFollower
}