import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGameOdds} from '../../redux/actions/SportsActions';
import { PgaOddsView } from './PgaOddsView';
import { Loading } from '../../components/LoadingComponent';

export const PgaOddsPage = () => {
  const { id: matchupId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [fighters, setFighters] = useState([]);

  const oddsData = useSelector(state => state.getGameOdds);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getGameOdds(matchupId));
      setLoading(false);
    };

    fetchData();
  }, [dispatch, matchupId]);

  if (loading || oddsData.loading) {
    return (
      <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
        <Loading />
      </div>
    );
  }

  if (!oddsData.odds || oddsData.odds.length === 0) {
    return (
      <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
        <p>No data available</p>
      </div>
    );
  }

  return (
    <div className="w-full h-screen flex flex-col overflow-hidden">
      <PgaOddsView data={oddsData.odds} fighters={fighters} />
    </div>
  );
};