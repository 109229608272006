import React, { useEffect, useState } from 'react';
import '../../../styles/Dashboard/dashboardStyle.css';
import { Link } from 'react-router-dom';
import { getMoneylineForTeam, getSpreadOrTotalGoalsForTeam, getTotalForTeam, getTotalRounds, getMethodOfVictory } from '../../../utils/sportStats/oddsFunctions';
import { useSelector } from 'react-redux';
import { formatFullDateWithOrdinalSuffix } from '../../../utils/dateUtils';

export const MobileUpcomingBettingOdds = ({ selectedSport, darkMode }) => {
  const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups[selectedSport]);
  const [upcomingMatchups, setUpcomingMatchups] = useState([]);
  const [noGamesMessage, setNoGamesMessage] = useState('');

  function getGamesByDays(gamesArray) {
    const parseDate = dateString => dateString.split('T')[0];
    const gamesByDate = {};
    const filteredGames = gamesArray.filter(game => game.status === 'Unplayed');
    filteredGames.forEach(game => {
      const date = parseDate(game.start_date);
      if (!gamesByDate[date]) {
        gamesByDate[date] = [];
      }
      gamesByDate[date].push({ game });
    });
    return gamesByDate;
  }

  useEffect(() => {
    if (matchupsFromStore && matchupsFromStore.length > 0) {
      const upcoming = getGamesByDays(matchupsFromStore);
      if (Object.keys(upcoming).length > 0) {
        const firstDate = Object.keys(upcoming)[0];
        setUpcomingMatchups(upcoming[firstDate]);
        setNoGamesMessage('');
      } else {
        setUpcomingMatchups([]);
        setNoGamesMessage('No upcoming games for the selected sport.');
      }
    } else {
      setUpcomingMatchups([]);
      setNoGamesMessage('No upcoming games for the selected sport.');
    }
  }, [matchupsFromStore, selectedSport]);

  return (
    <div className="w-full rounded-lg overflow-hidden">
      <div className={`upcoming-odds-header py-2 pl-4 font-semibold text-xs uppercase ${darkMode ? 'bg-primary text-black' : 'bg-black text-white'}`}>
        <div className="grid grid-cols-5 font-semibold py-2">
          <span className="date-span font-manrope col-span-1">
            {upcomingMatchups?.length > 0
              ? formatFullDateWithOrdinalSuffix(upcomingMatchups[0]?.game?.start_date)
              : ''}
          </span>
          {/* Header */}
          <div className="col-span-1 ml-7">Team</div>
          <div className="col-span-1 text-center">{selectedSport === 'golf' ? '' : 'Moneyline'}</div>
          <div className="col-span-1 text-center">
            {selectedSport === 'golf' ? '' : selectedSport === 'mma' ? 'Method Of Victory' : 'Spread'}
          </div>
          <div className="col-span-1 text-center">
          {selectedSport === 'golf' ? '' : selectedSport === 'mma' ? 'Total Rounds' : 'Total'}
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col">
        {noGamesMessage && (
          <div className="flex justify-center items-center h-20">
            <span className="text-gray-500 text-sm">{noGamesMessage}</span>
          </div>
        )}

        {(!noGamesMessage && upcomingMatchups?.length > 0) &&
          upcomingMatchups.map((game, index) => {
            if (!game.game || !game.game.odds || !game.game.odds?.odds[0]) return null;

            const homeMoneyline = getMoneylineForTeam(game.game.odds.odds, game.game.home_team);
            const awayMoneyline = getMoneylineForTeam(game.game.odds.odds, game.game.away_team);

            const matchingSpreads = getSpreadOrTotalGoalsForTeam(
              game.game.odds.odds,
              game.game.home_team,
              game.game.away_team,
              game.game.sport
            );

            const homeTotal = getTotalForTeam(game.game.odds.odds, 'under');
            const awayTotal = getTotalForTeam(game.game.odds.odds, 'over');
            
            const methodOfVictoryHome = getMethodOfVictory(game.game.odds.odds, game.game.home_team);
            const methodOfVictoryAway = getMethodOfVictory(game.game.odds.odds, game.game.away_team);

            const totalRoundsU = getTotalRounds(game.game.odds.odds, 'under');
            const totalRoundsO = getTotalRounds(game.game.odds.odds, 'over');

            const isMMA = game.game.sport === 'mma';
            const WrapperComponent = isMMA ? 'div' : Link;
            const wrapperProps = isMMA ? {} : { to: `/sports/propBets/${game.game.game_id}` };

            return (
              <WrapperComponent key={index} {...wrapperProps} className="matchup-card block bg-neutral-50 hover:bg-gray-200 m-2 rounded-lg shadow">
                <div className="h-1/3 mt-2">
                  <div className="w-full flex items-center">
                    <div className="w-44 ml-5 flex items-center space-x-10">
                      <div className="w-14 h-5 flex flex-col justify-start items-start">
                        <span className="text-zinc-400 text-xs font-normal capitalize">matchup</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-5 items-center py-2">
                  <div className="col-span-1 text-center">
                    <span className="matchup-date flex justify-center items-center text-zinc-500 text-xs xs:text-xs font-normal">
                      {(() => {
                        const startDate = new Date(game.game.start_date);
                        const isToday = startDate.toDateString() === new Date().toDateString();
                        const formatter = new Intl.DateTimeFormat('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        });

                        const formattedDate = formatter.format(startDate);
                        const formattedTimeWithUpperCaseAMPM = formattedDate.replace('AM', 'AM').replace('PM', 'PM');

                        return isToday ? formattedTimeWithUpperCaseAMPM : startDate.toLocaleDateString();
                      })()}
                    </span>
                  </div>

                  <div className="col-span-1 flex flex-col items-start justify-between">
                    <div className="flex items-center gap-2">
                      <img
                        className="w-6 h-6 object-cover"
                        src={game.game.away_team_details?.logo}
                        alt="Away Team Logo"
                        loading="lazy"
                      />
                      <span className="matchup-team text-indigo-950 text-xs xs:text-xs sm:text-base font-bold">
                        {game.game.away_team}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img
                        className="w-6 h-6 object-cover"
                        src={game.game.home_team_details?.logo}
                        alt="Home Team Logo"
                        loading="lazy"
                      />
                      <span className="matchup-team text-indigo-950 text-xs xs:text-xs sm:text-base font-bold">
                        {game.game.home_team}
                      </span>
                    </div>
                  </div>

                  <div className="col-span-1 ml-8">
                    <div className="flex justify-center items-center space-x-3 mb-1">
                      <span className="matchup-odds text-zinc-500 text-xs xs:text-xs sm:text-base font-normal">
                        {awayMoneyline?.price}
                      </span>
                    </div>
                    <div className="flex justify-center items-center space-x-3">
                      <span className="matchup-odds text-zinc-500 text-xs xs:text-xs sm:text-base font-normal">
                        {homeMoneyline?.price}
                      </span>
                    </div>
                  </div>

                  {game.game.sport === 'mma' ? (
                    <div className="col-span-1">
                      <div className="flex justify-around items-center space-x-1 mb-1">
                        <span className="matchup-odds text-zinc-500 text-xs sm:text-xs lg:text-xs font-normal">
                          {methodOfVictoryAway[0]?.name.split(' - ')[1]}
                        </span>
                        <span className="matchup-odds text-zinc-400 text-xs sm:text-xs lg:text-xs font-normal">
                          {methodOfVictoryAway[0]?.price}
                        </span>
                      </div>
                      <div className="flex justify-around items-center space-x-1">
                        <span className="matchup-odds text-zinc-500 text-xs sm:text-xs lg:text-xs font-normal">
                          {methodOfVictoryHome[0]?.name.split(' - ')[1]}
                        </span>
                        <span className="matchup-odds text-zinc-400 text-xs sm:text-xs lg:text-xs font-normal">
                          {methodOfVictoryHome[0]?.price}
                        </span>
                      </div>
                    </div>
                  ) : (
                  <div className="col-span-1 ml-1">
                    <div className="flex justify-around items-center space-x-1 mb-1">
                      <span className="matchup-odds text-zinc-500 text-xs xs:text-xs sm:text-xs font-normal">
                        {matchingSpreads?.awayOdds?.selection_points}
                      </span>
                      <span className="matchup-odds text-zinc-400 text-xs xs:text-xs sm:text-xs font-normal">
                        {matchingSpreads?.awayOdds?.price}
                      </span>
                    </div>
                    <div className="flex justify-around items-center space-x-1">
                      <span className="matchup-odds text-zinc-500 text-xs xs:text-xs sm:text-xs font-normal">
                        {matchingSpreads?.homeOdds?.selection_points}
                      </span>
                      <span className="matchup-odds text-zinc-400 text-xs xs:text-xs sm:text-xs font-normal">
                        {matchingSpreads?.homeOdds?.price}
                      </span>
                    </div>
                  </div>
                  )}

                  {game.game.sport === 'mma' ? (
                    <div className="col-span-1">
                      <div className="flex justify-around items-center space-x-1 mb-1">
                        <span className="matchup-odds text-zinc-500 text-xs sm:text-xs lg:text-xs font-normal">
                          {totalRoundsO ? <span className="text-green-600">O</span> : null}  {totalRoundsO?.selection_points}
                        </span>
                        <span className="matchup-odds text-zinc-400 text-xs sm:text-xs lg:text-xs font-normal">
                          {totalRoundsO?.price}
                        </span>
                      </div>
                      <div className="flex justify-around items-center space-x-1">
                        <span className="matchup-odds text-zinc-500 text-xs sm:text-xs lg:text-xs font-normal">
                          {totalRoundsU ? <span className="text-red-600">U</span> : null}  {totalRoundsU?.selection_points}
                        </span>
                        <span className="matchup-odds text-zinc-400 text-xs sm:text-xs lg:text-xs font-normal">
                          {totalRoundsU?.price}
                        </span>
                      </div>
                    </div>
                  ) : (
                  <div className="col-span-1 ml-1">
                    <div className="flex justify-around items-center space-x-1 mb-1">
                      <span className="matchup-odds text-zinc-500 text-xs xs:text-xs sm:text-xs font-normal">
                        {awayTotal ? <span className="text-green-600">O</span> : null}  {awayTotal?.selection_points}
                      </span>
                      <span className="matchup-odds text-zinc-400 text-xs xs:text-xs sm:text-xs font-normal">
                        {awayTotal?.price}
                      </span>
                    </div>
                    <div className="flex justify-around items-center space-x-1">
                      <span className="matchup-odds text-zinc-500 text-xs xs:text-xs sm:text-xs font-normal">
                        {homeTotal ? <span className="text-red-600">U</span> : null}  {homeTotal?.selection_points}
                      </span>
                      <span className="matchup-odds text-zinc-400 text-xs xs:text-xs sm:text-xs font-normal">
                        {homeTotal?.price}
                      </span>
                    </div>
                  </div>
                  )}
                </div>
              </WrapperComponent>
            );
          })}
      </div>
    </div>
  );
};
