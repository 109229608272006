// construction message
// import { Navbar } from '../../components/Navbar';
// import { Sidebar } from '../../components/Sidebar/Sidebar';

// export const OddsView = ({ darkMode }) => {
//   return (
//     <div className={`w-full h-screen ${darkMode ? 'bg-black text-white' : 'bg-[#ECECF4] text-gray-900'} flex flex-row overflow-hidden`}>
//       <Sidebar />
//       <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
//         <div className={`sticky top-0 z-10 ${darkMode ? 'bg-black' : 'bg-white'}`}>
//           <Navbar title="Sportsbooks" darkMode={darkMode}/>
//         </div>

//         {/* Construction message */}
//         <div className="flex-grow p-4">
//           <div className="bg-yellow-200 border border-yellow-400 text-yellow-800 p-4 rounded-md shadow-md">
//             <p className="font-semibold">This page is under construction.</p>
//             <p className="text-sm">We're working hard to bring you the best content. Check back soon!</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };


// got the three buttons, the matchups and the odds. 
// basically a Trending ticker table. before attempting other sportsbooks
// import React, { useEffect, useMemo, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { Navbar } from '../../components/Navbar';
// import { Sidebar } from '../../components/Sidebar/Sidebar';
// import { getUpcomingMatchups } from '../../redux/actions/SportsActions';
// import { formatDateToMonthDay } from '../../utils/dateUtils';
// import { getMoneylineForTeam, getSpreadOrTotalGoalsForTeam, getTotalForTeam, getTotalRounds, getMethodOfVictory } from '../../utils/sportStats/oddsFunctions';
// import '../../styles/Odds/oddsPage.css';

// const categoryToSportLeagueMap = {
//   NBA: { sport: 'basketball', league: 'NBA' },
//   NFL: { sport: 'football', league: 'NFL' },
//   NHL: { sport: 'hockey', league: 'NHL' },
//   MLB: { sport: 'baseball', league: 'MLB' },
//   UFC: { sport: 'mma', league: 'UFC' },
//   PGA: { sport: 'golf', league: 'PGA' },
//   WNBA: { sport: 'basketball', league: 'WNBA' },
// };

// const today = moment();
// const yesterday = moment().subtract(1, 'days');
// const tomorrow = moment().add(1, 'days');

// const toValueFormat = date => moment(date).format('YYYY-MM-DD');

// const dateOptions = [
//   { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
//   { value: toValueFormat(today), label: formatDateToMonthDay(today) },
//   { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
// ];

// const oddsOptions = {
//   default: [
//     { value: 'money_line', label: 'Moneyline' },
//     { value: 'point_spread', label: 'Spread' },
//     { value: 'total_points', label: 'Total' },
//   ],
//   mma: [
//     { value: 'money_line', label: 'Moneyline' },
//     { value: 'method_of_victory', label: 'Decision' },
//     { value: 'total_rounds', label: 'Total Rounds' },
//   ],
// };

// export const OddsView = ({ darkMode }) => {
//   const [selectedLeague, setSelectedLeague] = useState('All');
//   const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
//   const [selectedOddsType, setSelectedOddsType] = useState('money_line');
//   const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     Object.values(categoryToSportLeagueMap).forEach(sportLeague => {
//       dispatch(getUpcomingMatchups(sportLeague.sport, sportLeague.league));
//     });
//   }, [dispatch, selectedDate]);

//   useEffect(() => {
//   }, [matchupsFromStore]);

//   const filteredMatchups = useMemo(() => {
//     let allFilteredMatchups = [];
//     Object.keys(matchupsFromStore).forEach(category => {
//       const filtered = matchupsFromStore[category]?.filter(matchup => {
//         const gameDate = moment.utc(matchup.start_date).local().format('YYYY-MM-DD');
//         return gameDate === selectedDate;
//       });
//       allFilteredMatchups = [...allFilteredMatchups, ...filtered];
//     });
//     if (selectedLeague !== 'All') {
//       allFilteredMatchups = allFilteredMatchups.filter(matchup => matchup.league === selectedLeague);
//     }
//     return allFilteredMatchups;
//   }, [matchupsFromStore, selectedDate, selectedLeague]);

//   const handleCategoryChange = category => {
//     setSelectedLeague(category);
//     if (category === 'UFC') {
//       setSelectedOddsType('money_line');
//     }
//   };

//   const options = [
//     { value: 'All', label: 'All' },
//     ...Object.keys(categoryToSportLeagueMap).map(key => ({ value: key, label: key }))
//   ];

//   const currentOddsOptions = selectedLeague === 'UFC' ? oddsOptions.mma : oddsOptions.default;

//   const getOddsForMatchup = (matchup, selectedOddsType) => {
//     let awayOdds, homeOdds;
//     switch (selectedOddsType) {
//       case 'money_line':
//         awayOdds = getMoneylineForTeam(matchup?.odds?.odds, matchup?.away_team_details?.team_name);
//         homeOdds = getMoneylineForTeam(matchup?.odds?.odds, matchup?.home_team_details?.team_name);
//         break;
//       case 'point_spread':
//         const matchingSpreads = matchup?.odds?.odds ? getSpreadOrTotalGoalsForTeam(
//           matchup?.odds?.odds,
//           matchup?.home_team_details?.team_name,
//           matchup?.away_team_details?.team_name,
//           matchup?.sport
//         ) : {};
//         awayOdds = matchingSpreads?.awayOdds;
//         homeOdds = matchingSpreads?.homeOdds;
//         break;
//       case 'total_points':
//         awayOdds = getTotalForTeam(matchup?.odds?.odds, 'over');
//         homeOdds = getTotalForTeam(matchup?.odds?.odds, 'under');
//         break;
//       case 'method_of_victory':
//         awayOdds = getMethodOfVictory(matchup?.odds?.odds, matchup?.away_team_details?.team_name)?.[0];
//         homeOdds = getMethodOfVictory(matchup?.odds?.odds, matchup?.home_team_details?.team_name)?.[0];
//         break;
//       case 'total_rounds':
//         awayOdds = getTotalRounds(matchup?.odds?.odds, 'over');
//         homeOdds = getTotalRounds(matchup?.odds?.odds, 'under');
//         break;
//       default:
//         break;
//     }
//     return { awayOdds, homeOdds };
//   };

//   return (
//     <div className={`w-full h-screen ${darkMode ? 'bg-black text-white' : 'bg-[#ECECF4] text-gray-900'} flex flex-row overflow-hidden`}>
//       <Sidebar />
//       <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
//         <div className={`sticky top-0 z-10 ${darkMode ? 'bg-black' : 'bg-white'}`}>
//           <Navbar title="Sportsbooks" darkMode={darkMode}/>
//         </div>
//         <div className={`w-full p-4 ${darkMode ? 'bg-black text-white' : 'bg-white text-black'}`}>
//           <div className="flex flex-row gap-4 mb-4">
//             <select value={selectedLeague} onChange={e => handleCategoryChange(e.target.value)} className="p-2 rounded">
//               {options.map((option, index) => (
//                 <option key={index} value={option.value}>{option.label}</option>
//               ))}
//             </select>
//             <select value={selectedDate} onChange={e => setSelectedDate(e.target.value)} className="p-2 rounded">
//               {dateOptions.map((option, index) => (
//                 <option key={index} value={option.value}>{option.label}</option>
//               ))}
//             </select>
//             <select value={selectedOddsType} onChange={e => setSelectedOddsType(e.target.value)} className="p-2 rounded">
//               {currentOddsOptions.map((option, index) => (
//                 <option key={index} value={option.value}>{option.label}</option>
//               ))}
//             </select>
//           </div>
//           <table className="w-full text-left">
//             <thead>
//               <tr>
//                 <th className="border p-2">Matchup</th>
//                 <th className="border p-2">Odds ({currentOddsOptions.find(option => option.value === selectedOddsType)?.label})</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredMatchups.length > 0 ? (
//                 filteredMatchups.map((matchup, index) => {
//                   const { awayOdds, homeOdds } = getOddsForMatchup(matchup, selectedOddsType);
//                   return (
//                     <tr key={index}>
//                       <td className="border p-2">
//                         <div className="flex items-center">
//                           <img src={matchup.away_team_details?.logo} alt={`${matchup.away_team} logo`} className="h-6 w-6 mr-2" />
//                           {matchup.away_team} vs {matchup.home_team}
//                           <img src={matchup.home_team_details?.logo} alt={`${matchup.home_team} logo`} className="h-6 w-6 ml-2" />
//                         </div>
//                       </td>
//                       <td className="border p-2">
//                         {selectedOddsType === 'money_line' && awayOdds && homeOdds && (
//                           <>
//                             <div>{awayOdds.price}</div>
//                             <div>{homeOdds.price}</div>
//                           </>
//                         )}
//                         {selectedOddsType === 'point_spread' && awayOdds && homeOdds && (
//                           <>
//                             <div>{awayOdds.selection_points} ({awayOdds.price})</div>
//                             <div>{homeOdds.selection_points} ({homeOdds.price})</div>
//                           </>
//                         )}
//                         {selectedOddsType === 'total_points' && awayOdds && homeOdds && (
//                           <>
//                             <div>O {awayOdds.selection_points} ({awayOdds.price})</div>
//                             <div>U {homeOdds.selection_points} ({homeOdds.price})</div>
//                           </>
//                         )}
//                         {selectedOddsType === 'method_of_victory' && awayOdds && homeOdds && (
//                           <>
//                             <div>Method of Victory (Away): {awayOdds.name.split(' - ')[1]} ({awayOdds.price})</div>
//                             <div>Method of Victory (Home): {homeOdds.name.split(' - ')[1]} ({homeOdds.price})</div>
//                           </>
//                         )}
//                         {selectedOddsType === 'total_rounds' && awayOdds && homeOdds && (
//                           <>
//                             <div>O {awayOdds.selection_points} ({awayOdds.price})</div>
//                             <div>U {homeOdds.selection_points} ({homeOdds.price})</div>
//                           </>
//                         )}
//                       </td>
//                     </tr>
//                   );
//                 })
//               ) : (
//                 <tr>
//                   <td className="border p-2" colSpan="2">No matchups for this day</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };





// added odds from multiple sportsbooks
// import React, { useEffect, useMemo, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { Navbar } from '../../components/Navbar';
// import { Sidebar } from '../../components/Sidebar/Sidebar';
// import { getUpcomingMatchups } from '../../redux/actions/SportsActions';
// import { formatDateToMonthDay } from '../../utils/dateUtils';
// import { getMoneylineForTeam, getSpreadOrTotalGoalsForTeam, getTotalForTeam, getTotalRounds, getMethodOfVictory } from '../../utils/sportStats/oddsFunctions';
// import '../../styles/Odds/oddsPage.css';

// const categoryToSportLeagueMap = {
//   NBA: { sport: 'basketball', league: 'NBA' },
//   NFL: { sport: 'football', league: 'NFL' },
//   NHL: { sport: 'hockey', league: 'NHL' },
//   MLB: { sport: 'baseball', league: 'MLB' },
//   UFC: { sport: 'mma', league: 'UFC' },
//   PGA: { sport: 'golf', league: 'PGA' },
//   WNBA: { sport: 'basketball', league: 'WNBA' },
// };

// const today = moment();
// const yesterday = moment().subtract(1, 'days');
// const tomorrow = moment().add(1, 'days');

// const toValueFormat = date => moment(date).format('YYYY-MM-DD');

// const dateOptions = [
//   { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
//   { value: toValueFormat(today), label: formatDateToMonthDay(today) },
//   { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
// ];

// const oddsOptions = {
//   default: [
//     { value: 'money_line', label: 'Moneyline' },
//     { value: 'point_spread', label: 'Spread' },
//     { value: 'total_points', label: 'Total' },
//   ],
//   mma: [
//     { value: 'money_line', label: 'Moneyline' },
//     { value: 'method_of_victory', label: 'Decision' },
//     { value: 'total_rounds', label: 'Total Rounds' },
//   ],
// };

// const getOddsForMatchup = (matchup, selectedOddsType) => {
//   const sportsbooks = ['DraftKings', 'FanDuel', 'BetESPN', 'Tipico', 'BetMGM', 'Bet365', 'Unibet']; 
//   const oddsBySportsbook = {};

//   sportsbooks.forEach(sportsbook => {
//     let awayOdds, homeOdds;
//     switch (selectedOddsType) {
//       case 'money_line':
//         awayOdds = getMoneylineForTeam(matchup?.odds?.odds, matchup?.away_team_details?.team_name);
//         homeOdds = getMoneylineForTeam(matchup?.odds?.odds, matchup?.home_team_details?.team_name);
//         break;
//       case 'point_spread':
//         const matchingSpreads = matchup?.odds?.odds ? getSpreadOrTotalGoalsForTeam(
//           matchup?.odds?.odds,
//           matchup?.home_team_details?.team_name,
//           matchup?.away_team_details?.team_name,
//           matchup?.sport
//         ) : {};
//         awayOdds = matchingSpreads?.awayOdds;
//         homeOdds = matchingSpreads?.homeOdds;
//         break;
//       case 'total_points':
//         awayOdds = getTotalForTeam(matchup?.odds?.odds, 'over');
//         homeOdds = getTotalForTeam(matchup?.odds?.odds, 'under');
//         break;
//       case 'method_of_victory':
//         awayOdds = getMethodOfVictory(matchup?.odds?.odds, matchup?.away_team_details?.team_name)?.[0];
//         homeOdds = getMethodOfVictory(matchup?.odds?.odds, matchup?.home_team_details?.team_name)?.[0];
//         break;
//       case 'total_rounds':
//         awayOdds = getTotalRounds(matchup?.odds?.odds, 'over');
//         homeOdds = getTotalRounds(matchup?.odds?.odds, 'under');
//         break;
//       default:
//         break;
//     }
//     oddsBySportsbook[sportsbook] = { awayOdds, homeOdds };
//   });

//   return oddsBySportsbook;
// };

// export const OddsView = ({ darkMode }) => {
//   const [selectedLeague, setSelectedLeague] = useState('All');
//   const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
//   const [selectedOddsType, setSelectedOddsType] = useState('money_line');
//   const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     Object.values(categoryToSportLeagueMap).forEach(sportLeague => {
//       dispatch(getUpcomingMatchups(sportLeague.sport, sportLeague.league));
//     });
//   }, [dispatch, selectedDate]);

//   useEffect(() => {
//     console.log('Matchups from store:', matchupsFromStore);
//   }, [matchupsFromStore]);

//   const filteredMatchups = useMemo(() => {
//     let allFilteredMatchups = [];
//     Object.keys(matchupsFromStore).forEach(category => {
//       const filtered = matchupsFromStore[category]?.filter(matchup => {
//         const gameDate = moment.utc(matchup.start_date).local().format('YYYY-MM-DD');
//         return gameDate === selectedDate;
//       });
//       allFilteredMatchups = [...allFilteredMatchups, ...filtered];
//     });
//     if (selectedLeague !== 'All') {
//       allFilteredMatchups = allFilteredMatchups.filter(matchup => matchup.league === selectedLeague);
//     }
//     console.log('Filtered Matchups:', allFilteredMatchups); // Added logging
//     return allFilteredMatchups;
//   }, [matchupsFromStore, selectedDate, selectedLeague]);

//   const handleCategoryChange = category => {
//     setSelectedLeague(category);
//     if (category === 'UFC') {
//       setSelectedOddsType('money_line');
//     }
//   };

//   const options = [
//     { value: 'All', label: 'All' },
//     ...Object.keys(categoryToSportLeagueMap).map(key => ({ value: key, label: key }))
//   ];

//   const currentOddsOptions = selectedLeague === 'UFC' ? oddsOptions.mma : oddsOptions.default;

//   return (
//     <div className={`w-full h-screen ${darkMode ? 'bg-black text-white' : 'bg-[#ECECF4] text-gray-900'} flex flex-row overflow-hidden`}>
//       <Sidebar />
//       <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
//         <div className={`sticky top-0 z-10 ${darkMode ? 'bg-black' : 'bg-white'}`}>
//           <Navbar title="Sportsbooks" darkMode={darkMode}/>
//         </div>
//         <div className={`w-full p-4 ${darkMode ? 'bg-black text-white' : 'bg-white text-black'}`}>
//           <div className="flex flex-row gap-4 mb-4">
//             <select value={selectedLeague} onChange={e => handleCategoryChange(e.target.value)} className="p-2 rounded">
//               {options.map((option, index) => (
//                 <option key={index} value={option.value}>{option.label}</option>
//               ))}
//             </select>
//             <select value={selectedDate} onChange={e => setSelectedDate(e.target.value)} className="p-2 rounded">
//               {dateOptions.map((option, index) => (
//                 <option key={index} value={option.value}>{option.label}</option>
//               ))}
//             </select>
//             <select value={selectedOddsType} onChange={e => setSelectedOddsType(e.target.value)} className="p-2 rounded">
//               {currentOddsOptions.map((option, index) => (
//                 <option key={index} value={option.value}>{option.label}</option>
//               ))}
//             </select>
//           </div>
//           <table className="w-full text-left">
//             <thead>
//               <tr>
//                 <th className="border p-2">Matchup</th>
//                 <th className="border p-2">Odds ({currentOddsOptions.find(option => option.value === selectedOddsType)?.label})</th>
//                 <th className="border p-2">Sportsbook</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredMatchups.length > 0 ? (
//                 filteredMatchups.map((matchup, index) => {
//                   const oddsBySportsbook = getOddsForMatchup(matchup, selectedOddsType);
//                   return (
//                     <tr key={index}>
//                       <td className="border p-2">
//                         <div className="flex items-center">
//                           <img src={matchup.away_team_details?.logo} alt={`${matchup.away_team} logo`} className="h-6 w-6 mr-2" />
//                           {matchup.away_team} vs {matchup.home_team}
//                           <img src={matchup.home_team_details?.logo} alt={`${matchup.home_team} logo`} className="h-6 w-6 ml-2" />
//                         </div>
//                       </td>
//                       <td className="border p-2">
//                         {Object.entries(oddsBySportsbook).map(([sportsbook, odds]) => (
//                           <div key={sportsbook} className="flex flex-col">
//                             <span>{sportsbook}:</span>
//                             {selectedOddsType === 'money_line' && odds.awayOdds && odds.homeOdds && (
//                               <>
//                                 <div>{odds.awayOdds.price}</div>
//                                 <div>{odds.homeOdds.price}</div>
//                               </>
//                             )}
//                             {selectedOddsType === 'point_spread' && odds.awayOdds && odds.homeOdds && (
//                               <>
//                                 <div>{odds.awayOdds.selection_points} ({odds.awayOdds.price})</div>
//                                 <div>{odds.homeOdds.selection_points} ({odds.homeOdds.price})</div>
//                               </>
//                             )}
//                             {selectedOddsType === 'total_points' && odds.awayOdds && odds.homeOdds && (
//                               <>
//                                 <div>O {odds.awayOdds.selection_points} ({odds.awayOdds.price})</div>
//                                 <div>U {odds.homeOdds.selection_points} ({odds.homeOdds.price})</div>
//                               </>
//                             )}
//                             {selectedOddsType === 'method_of_victory' && odds.awayOdds && odds.homeOdds && (
//                               <>
//                                 <div>Method of Victory (Away): {odds.awayOdds.name.split(' - ')[1]} ({odds.awayOdds.price})</div>
//                                 <div>Method of Victory (Home): {odds.homeOdds.name.split(' - ')[1]} ({odds.homeOdds.price})</div>
//                               </>
//                             )}
//                             {selectedOddsType === 'total_rounds' && odds.awayOdds && odds.homeOdds && (
//                               <>
//                                 <div>O {odds.awayOdds.selection_points} ({odds.awayOdds.price})</div>
//                                 <div>U {odds.homeOdds.selection_points} ({odds.homeOdds.price})</div>
//                               </>
//                             )}
//                           </div>
//                         ))}
//                       </td>
//                       <td className="border p-2">
//                         {Object.keys(oddsBySportsbook).map(sportsbook => (
//                           <div key={sportsbook}>{sportsbook}</div>
//                         ))}
//                       </td>
//                     </tr>
//                   );
//                 })
//               ) : (
//                 <tr>
//                   <td className="border p-2" colSpan="3">No matchups for this day</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };





// box with shadows
// import React, { useEffect, useMemo, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { Navbar } from '../../components/Navbar';
// import { Sidebar } from '../../components/Sidebar/Sidebar';
// import { getUpcomingMatchups } from '../../redux/actions/SportsActions';
// import { formatDateToMonthDay } from '../../utils/dateUtils';
// import { getMoneylineForTeam, getSpreadOrTotalGoalsForTeam, getTotalForTeam, getTotalRounds, getMethodOfVictory } from '../../utils/sportStats/oddsFunctions';
// import '../../styles/Odds/oddsPage.css';

// const categoryToSportLeagueMap = {
//   NBA: { sport: 'basketball', league: 'NBA' },
//   NFL: { sport: 'football', league: 'NFL' },
//   NHL: { sport: 'hockey', league: 'NHL' },
//   MLB: { sport: 'baseball', league: 'MLB' },
//   UFC: { sport: 'mma', league: 'UFC' },
//   PGA: { sport: 'golf', league: 'PGA' },
//   WNBA: { sport: 'basketball', league: 'WNBA' },
// };

// const today = moment();
// const yesterday = moment().subtract(1, 'days');
// const tomorrow = moment().add(1, 'days');

// const toValueFormat = date => moment(date).format('YYYY-MM-DD');

// const dateOptions = [
//   { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
//   { value: toValueFormat(today), label: formatDateToMonthDay(today) },
//   { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
// ];

// const oddsOptions = {
//   default: [
//     { value: 'money_line', label: 'Moneyline' },
//     { value: 'point_spread', label: 'Spread' },
//     { value: 'total_points', label: 'Total' },
//   ],
//   mma: [
//     { value: 'money_line', label: 'Moneyline' },
//     { value: 'method_of_victory', label: 'Decision' },
//     { value: 'total_rounds', label: 'Total Rounds' },
//   ],
// };

// const getOddsForMatchup = (matchup, selectedOddsType) => {
//   const sportsbooks = ['DraftKings', 'FanDuel', 'BetESPN', 'Tipico', 'BetMGM', 'Bet365', 'Unibet']; 
//   const oddsBySportsbook = {};

//   sportsbooks.forEach(sportsbook => {
//     let awayOdds, homeOdds;
//     switch (selectedOddsType) {
//       case 'money_line':
//         awayOdds = getMoneylineForTeam(matchup?.odds?.odds, matchup?.away_team_details?.team_name);
//         homeOdds = getMoneylineForTeam(matchup?.odds?.odds, matchup?.home_team_details?.team_name);
//         break;
//       case 'point_spread':
//         const matchingSpreads = matchup?.odds?.odds ? getSpreadOrTotalGoalsForTeam(
//           matchup?.odds?.odds,
//           matchup?.home_team_details?.team_name,
//           matchup?.away_team_details?.team_name,
//           matchup?.sport
//         ) : {};
//         awayOdds = matchingSpreads?.awayOdds;
//         homeOdds = matchingSpreads?.homeOdds;
//         break;
//       case 'total_points':
//         awayOdds = getTotalForTeam(matchup?.odds?.odds, 'over');
//         homeOdds = getTotalForTeam(matchup?.odds?.odds, 'under');
//         break;
//       case 'method_of_victory':
//         awayOdds = getMethodOfVictory(matchup?.odds?.odds, matchup?.away_team_details?.team_name)?.[0];
//         homeOdds = getMethodOfVictory(matchup?.odds?.odds, matchup?.home_team_details?.team_name)?.[0];
//         break;
//       case 'total_rounds':
//         awayOdds = getTotalRounds(matchup?.odds?.odds, 'over');
//         homeOdds = getTotalRounds(matchup?.odds?.odds, 'under');
//         break;
//       default:
//         break;
//     }
//     oddsBySportsbook[sportsbook] = { awayOdds, homeOdds };
//   });

//   return oddsBySportsbook;
// };

// export const OddsView = ({ darkMode }) => {
//   const [selectedLeague, setSelectedLeague] = useState('All');
//   const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
//   const [selectedOddsType, setSelectedOddsType] = useState('money_line');
//   const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     Object.values(categoryToSportLeagueMap).forEach(sportLeague => {
//       dispatch(getUpcomingMatchups(sportLeague.sport, sportLeague.league));
//     });
//   }, [dispatch, selectedDate]);

//   const filteredMatchups = useMemo(() => {
//     let allFilteredMatchups = [];
//     Object.keys(matchupsFromStore).forEach(category => {
//       const filtered = matchupsFromStore[category]?.filter(matchup => {
//         const gameDate = moment.utc(matchup.start_date).local().format('YYYY-MM-DD');
//         return gameDate === selectedDate;
//       });
//       allFilteredMatchups = [...allFilteredMatchups, ...filtered];
//     });
//     if (selectedLeague !== 'All') {
//       allFilteredMatchups = allFilteredMatchups.filter(matchup => matchup.league === selectedLeague);
//     }
//     return allFilteredMatchups;
//   }, [matchupsFromStore, selectedDate, selectedLeague]);

//   const handleCategoryChange = category => {
//     setSelectedLeague(category);
//     if (category === 'UFC') {
//       setSelectedOddsType('money_line');
//     }
//   };

//   const options = [
//     { value: 'All', label: 'All' },
//     ...Object.keys(categoryToSportLeagueMap).map(key => ({ value: key, label: key }))
//   ];

//   const currentOddsOptions = selectedLeague === 'UFC' ? oddsOptions.mma : oddsOptions.default;

//   return (
//     <div className={`w-full h-screen ${darkMode ? 'bg-black text-white' : 'bg-[#ECECF4] text-gray-900'} flex flex-row overflow-hidden`}>
//       <Sidebar />
//       <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
//         <div className={`sticky top-0 z-10 ${darkMode ? 'bg-black' : 'bg-white'}`}>
//           <Navbar title="Sportsbooks" darkMode={darkMode}/>
//         </div>
//         <div className={`w-full p-4 ${darkMode ? 'bg-black text-white' : 'bg-white text-black'}`}>
//           <div className="flex flex-row gap-4 mb-4">
//             <select value={selectedLeague} onChange={e => handleCategoryChange(e.target.value)} className="p-2 rounded">
//               {options.map((option, index) => (
//                 <option key={index} value={option.value}>{option.label}</option>
//               ))}
//             </select>
//             <select value={selectedDate} onChange={e => setSelectedDate(e.target.value)} className="p-2 rounded">
//               {dateOptions.map((option, index) => (
//                 <option key={index} value={option.value}>{option.label}</option>
//               ))}
//             </select>
//             <select value={selectedOddsType} onChange={e => setSelectedOddsType(e.target.value)} className="p-2 rounded">
//               {currentOddsOptions.map((option, index) => (
//                 <option key={index} value={option.value}>{option.label}</option>
//               ))}
//             </select>
//           </div>
//           <table className="w-full text-left">
//             <thead>
//               <tr>
//                 <th className="border-b p-2">Matchup</th>
//                 <th className="border-b p-2">DraftKings</th>
//                 <th className="border-b p-2">FanDuel</th>
//                 <th className="border-b p-2">BetESPN</th>
//                 <th className="border-b p-2">Tipico</th>
//                 <th className="border-b p-2">BetMGM</th>
//                 <th className="border-b p-2">Bet365</th>
//                 <th className="border-b p-2">Unibet</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredMatchups.length > 0 ? (
//                 filteredMatchups.map((matchup, index) => {
//                   const oddsBySportsbook = getOddsForMatchup(matchup, selectedOddsType);
//                   return (
//                     <tr key={index}>
//                       <td className="p-2">
//                         <div className="flex flex-col">
//                           <div className="flex items-center mb-2">
//                             <img src={matchup.away_team_details?.logo} alt={`${matchup.away_team} logo`} className="h-6 w-6 mr-2" />
//                             {matchup.away_team}
//                           </div>
//                           <div className="flex items-center">
//                             <img src={matchup.home_team_details?.logo} alt={`${matchup.home_team} logo`} className="h-6 w-6 mr-2" />
//                             {matchup.home_team}
//                           </div>
//                         </div>
//                       </td>
//                       {Object.entries(oddsBySportsbook).map(([sportsbook, odds], idx) => (
//                         <td key={idx} className="p-2">
//                           {selectedOddsType === 'money_line' && odds.awayOdds && odds.homeOdds && (
//                             <div className="odds-box">
//                               <div>{odds.awayOdds.price}</div>
//                               <div>{odds.homeOdds.price}</div>
//                             </div>
//                           )}
//                           {selectedOddsType === 'point_spread' && odds.awayOdds && odds.homeOdds && (
//                             <div className="odds-box">
//                               <div>{odds.awayOdds.selection_points} ({odds.awayOdds.price})</div>
//                               <div>{odds.homeOdds.selection_points} ({odds.homeOdds.price})</div>
//                             </div>
//                           )}
//                           {selectedOddsType === 'total_points' && odds.awayOdds && odds.homeOdds && (
//                             <div className="odds-box">
//                               <div>O {odds.awayOdds.selection_points} ({odds.awayOdds.price})</div>
//                               <div>U {odds.homeOdds.selection_points} ({odds.homeOdds.price})</div>
//                             </div>
//                           )}
//                           {selectedOddsType === 'method_of_victory' && odds.awayOdds && odds.homeOdds && (
//                             <div className="odds-box">
//                               <div>Method of Victory (Away): {odds.awayOdds.name.split(' - ')[1]} ({odds.awayOdds.price})</div>
//                               <div>Method of Victory (Home): {odds.homeOdds.name.split(' - ')[1]} ({odds.homeOdds.price})</div>
//                             </div>
//                           )}
//                           {selectedOddsType === 'total_rounds' && odds.awayOdds && odds.homeOdds && (
//                             <div className="odds-box">
//                               <div>O {odds.awayOdds.selection_points} ({odds.awayOdds.price})</div>
//                               <div>U {odds.homeOdds.selection_points} ({odds.homeOdds.price})</div>
//                             </div>
//                           )}
//                         </td>
//                       ))}
//                     </tr>
//                   );
//                 })
//               ) : (
//                 <tr>
//                   <td className="p-2" colSpan="8">No matchups for this day</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };



// best version odds are repeated tho
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { getUpcomingMatchups } from '../../redux/actions/SportsActions';
import { formatDateToMonthDay } from '../../utils/dateUtils';
import { getMoneylineForTeam, getSpreadOrTotalGoalsForTeam, getTotalForTeam, getTotalRounds, getMethodOfVictory } from '../../utils/sportStats/oddsFunctions';
import '../../styles/Odds/oddsPage.css';

const categoryToSportLeagueMap = {
  NBA: { sport: 'basketball', league: 'NBA' },
  NFL: { sport: 'football', league: 'NFL' },
  // NHL: { sport: 'hockey', league: 'NHL' },
  // MLB: { sport: 'baseball', league: 'MLB' },
  UFC: { sport: 'mma', league: 'UFC' },
  // PGA: { sport: 'golf', league: 'PGA' },
  WNBA: { sport: 'basketball', league: 'WNBA' },
};

const today = moment();
const yesterday = moment().subtract(1, 'days');
const tomorrow = moment().add(1, 'days');

const toValueFormat = date => moment(date).format('YYYY-MM-DD');

const dateOptions = [
  { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
  { value: toValueFormat(today), label: formatDateToMonthDay(today) },
  { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
];

const oddsOptions = {
  default: [
    { value: 'money_line', label: 'Moneyline' },
    { value: 'point_spread', label: 'Spread' },
    { value: 'total_points', label: 'Total' },
  ],
  mma: [
    { value: 'money_line', label: 'Moneyline' },
    { value: 'method_of_victory', label: 'Decision' },
    { value: 'total_rounds', label: 'Total Rounds' },
  ],
};

const getOddsForMatchup = (matchup, selectedOddsType) => {
  const sportsbooks = ['DraftKings', 'FanDuel', 'BetESPN', 'Tipico', 'BetMGM', 'Bet365', 'Unibet'];
  const oddsBySportsbook = {};

  sportsbooks.forEach(sportsbook => {
    let awayOdds, homeOdds;
    switch (selectedOddsType) {
      case 'money_line':
        awayOdds = getMoneylineForTeam(matchup?.odds?.odds, matchup?.away_team_details?.team_name);
        homeOdds = getMoneylineForTeam(matchup?.odds?.odds, matchup?.home_team_details?.team_name);
        break;
      case 'point_spread':
        const matchingSpreads = matchup?.odds?.odds ? getSpreadOrTotalGoalsForTeam(
          matchup?.odds?.odds,
          matchup?.home_team_details?.team_name,
          matchup?.away_team_details?.team_name,
          matchup?.sport
        ) : {};
        awayOdds = matchingSpreads?.awayOdds;
        homeOdds = matchingSpreads?.homeOdds;
        break;
      case 'total_points':
        awayOdds = getTotalForTeam(matchup?.odds?.odds, 'over');
        homeOdds = getTotalForTeam(matchup?.odds?.odds, 'under');
        break;
      case 'method_of_victory':
        awayOdds = getMethodOfVictory(matchup?.odds?.odds, matchup?.away_team_details?.team_name)?.[0];
        homeOdds = getMethodOfVictory(matchup?.odds?.odds, matchup?.home_team_details?.team_name)?.[0];
        break;
      case 'total_rounds':
        awayOdds = getTotalRounds(matchup?.odds?.odds, 'over');
        homeOdds = getTotalRounds(matchup?.odds?.odds, 'under');
        break;
      default:
        break;
    }
    oddsBySportsbook[sportsbook] = { awayOdds, homeOdds };
  });

  return oddsBySportsbook;
};

export const OddsView = ({ darkMode }) => {
  const [selectedLeague, setSelectedLeague] = useState('All');
  const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
  const [selectedOddsType, setSelectedOddsType] = useState('money_line');
  const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
  const dispatch = useDispatch();

  useEffect(() => {
    Object.values(categoryToSportLeagueMap).forEach(sportLeague => {
      dispatch(getUpcomingMatchups(sportLeague.sport, sportLeague.league));
    });
  }, [dispatch, selectedDate]);

  const filteredMatchups = useMemo(() => {
    let allFilteredMatchups = [];
    Object.keys(matchupsFromStore).forEach(category => {
      const filtered = matchupsFromStore[category]?.filter(matchup => {
        const gameDate = moment.utc(matchup.start_date).local().format('YYYY-MM-DD');
        return gameDate === selectedDate;
      });
      allFilteredMatchups = [...allFilteredMatchups, ...filtered];
    });
    if (selectedLeague !== 'All') {
      allFilteredMatchups = allFilteredMatchups.filter(matchup => matchup.league === selectedLeague);
    }
    return allFilteredMatchups;
  }, [matchupsFromStore, selectedDate, selectedLeague]);

  const handleCategoryChange = category => {
    setSelectedLeague(category);
    if (category === 'UFC') {
      setSelectedOddsType('money_line');
    }
  };

  const options = [
    { value: 'All', label: 'All' },
    ...Object.keys(categoryToSportLeagueMap).map(key => ({ value: key, label: key }))
  ];

  const currentOddsOptions = selectedLeague === 'UFC' ? oddsOptions.mma : oddsOptions.default;

  return (
    <div className={`w-full h-screen ${darkMode ? 'bg-black text-white' : 'bg-[#ECECF4] text-gray-900'} flex flex-row overflow-hidden`}>
      <Sidebar />
      <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
        <div className={`sticky top-0 z-10 ${darkMode ? 'bg-black' : 'bg-white'}`}>
          <Navbar title="Sportsbooks" darkMode={darkMode} />
        </div>
        <div className={`w-full p-4 ${darkMode ? 'bg-black text-white' : 'bg-white text-black'}`}>
          <div className={`flex flex-row gap-4 mb-4 ${darkMode ? 'text-black' : 'text-white'}`}>
            <select value={selectedLeague} onChange={e => handleCategoryChange(e.target.value)} className={`p-2 rounded ${darkMode ? '' : 'bg-black'}`}>
              {options.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
              ))}
            </select>
            <select value={selectedDate} onChange={e => setSelectedDate(e.target.value)} className={`p-2 rounded ${darkMode ? '' : 'bg-black'}`}>
              {dateOptions.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
              ))}
            </select>
            <select value={selectedOddsType} onChange={e => setSelectedOddsType(e.target.value)} className={`p-2 rounded ${darkMode ? '' : 'bg-black'}`}>
              {currentOddsOptions.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <table className="w-full text-left">
            <thead>
              <tr>
                <th className="border-b p-2">Matchup</th>
                <th className="border-b p-2 text-center">DraftKings</th>
                <th className="border-b p-2">FanDuel</th>
                <th className="border-b p-2">BetESPN</th>
                <th className="border-b p-2">Tipico</th>
                <th className="border-b p-2">BetMGM</th>
                <th className="border-b p-2">Bet365</th>
                <th className="border-b p-2">Unibet</th>
              </tr>
            </thead>
            <tbody>
              {filteredMatchups.length > 0 ? (
                filteredMatchups.map((matchup, index) => {
                  const oddsBySportsbook = getOddsForMatchup(matchup, selectedOddsType);
                  return (
                    <tr key={index}>
                      <td className="p-2">
                        <div className="flex flex-col">
                          <div className="flex items-center mb-2">
                            <img src={matchup.away_team_details?.logo} alt={`${matchup.away_team} logo`} className="h-6 w-6 mr-2" />
                            {matchup.away_team}
                          </div>
                          <div className="flex items-center">
                            <img src={matchup.home_team_details?.logo} alt={`${matchup.home_team} logo`} className="h-6 w-6 mr-2" />
                            {matchup.home_team}
                          </div>
                        </div>
                      </td>
                      {Object.entries(oddsBySportsbook).map(([sportsbook, odds], idx) => (
                        <td key={idx} className={`p-2 ${darkMode ? 'text-black' : 'text-black'}`}>
                          {selectedOddsType === 'money_line' && odds.awayOdds && odds.homeOdds && (
                            <>
                              <div className="odds-box">{odds.awayOdds.price}</div>
                              <div className="odds-box">{odds.homeOdds.price}</div>
                            </>
                          )}
                          {selectedOddsType === 'point_spread' && odds.awayOdds && odds.homeOdds && (
                            <>
                              <div className="odds-box">{odds.awayOdds.selection_points} ({odds.awayOdds.price})</div>
                              <div className="odds-box">{odds.homeOdds.selection_points} ({odds.homeOdds.price})</div>
                            </>
                          )}
                          {selectedOddsType === 'total_points' && odds.awayOdds && odds.homeOdds && (
                            <>
                              <div className="odds-box">O {odds.awayOdds.selection_points} ({odds.awayOdds.price})</div>
                              <div className="odds-box">U {odds.homeOdds.selection_points} ({odds.homeOdds.price})</div>
                            </>
                          )}
                          {selectedOddsType === 'method_of_victory' && odds.awayOdds && odds.homeOdds && (
                            <>
                              <div className="odds-box">Method of Victory (Away): {odds.awayOdds.name.split(' - ')[1]} ({odds.awayOdds.price})</div>
                              <div className="odds-box">Method of Victory (Home): {odds.homeOdds.name.split(' - ')[1]} ({odds.homeOdds.price})</div>
                            </>
                          )}
                          {selectedOddsType === 'total_rounds' && odds.awayOdds && odds.homeOdds && (
                            <>
                              <div className="odds-box">O {odds.awayOdds.selection_points} ({odds.awayOdds.price})</div>
                              <div className="odds-box">U {odds.homeOdds.selection_points} ({odds.homeOdds.price})</div>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="p-2" colSpan="8">No matchups for this day</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

