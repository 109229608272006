import React, { useEffect, useState, useMemo } from 'react';
import '../../../styles/Dashboard/dashboardStyle.css';
import { Link } from 'react-router-dom';
import { getMoneylineForTeam, getSpreadOrTotalGoalsForTeam, getTotalForTeam, getTotalRounds, getMethodOfVictory } from '../../../utils/sportStats/oddsFunctions';
import { useSelector } from 'react-redux';
import { formatFullDateWithOrdinalSuffix } from '../../../utils/dateUtils';

export const UpcomingBettingOdds = ({ selectedSport, darkMode }) => {
  const allMatchups = useSelector(state => state.getUpcomingMatchups.matchups);

  const matchupsFromStore = useMemo(() => {
    if (Array.isArray(selectedSport)) {
      return selectedSport.flatMap(sport => allMatchups[sport] || []);
    }
    return allMatchups[selectedSport] || [];
  }, [selectedSport, allMatchups]);

  const [upcomingMatchupsByLeague, setUpcomingMatchupsByLeague] = useState({});
  const [noGamesMessage, setNoGamesMessage] = useState('');

  const groupByLeague = (gamesArray) => {
    const gamesByLeague = {};
    const now = new Date();
    gamesArray
      .filter(game => new Date(game.start_date) > now)
      .forEach(game => {
        const league = game.league;
        if (!gamesByLeague[league]) {
          gamesByLeague[league] = [];
        }
        gamesByLeague[league].push(game);
      });
    return gamesByLeague;
  };

  useEffect(() => {
    const groupedByLeague = groupByLeague(matchupsFromStore);
    if (Object.keys(groupedByLeague).length > 0) {
      setUpcomingMatchupsByLeague(groupedByLeague);
      setNoGamesMessage('');
    } else {
      setUpcomingMatchupsByLeague({});
      setNoGamesMessage('No upcoming games for the selected sport.');
    }
  }, [matchupsFromStore]);

  const isEsports = Array.isArray(selectedSport);

  return (
    <div className="w-full rounded-lg overflow-hidden">
      <div className={`upcoming-odds-header py-3 pl-6 font-semibold text-sm uppercase ${darkMode ? 'bg-primary text-black' : 'bg-black text-white'}`}>
        <div className="grid grid-cols-10 font-semibold py-3">
          <span className="date-span font-manrope col-span-2">
            {Object.keys(upcomingMatchupsByLeague).length > 0 ? 'Upcoming Games' : ''}
          </span>
          {/* <div className="col-span-2">{selectedSport === 'PGA' ? 'Tournament' : 'Team'}</div> */}
          <div className="col-span-2">{'Team'}</div>
          {/* <div className="col-span-2 text-center">{selectedSport === 'PGA' ? '' : 'Moneyline'}</div> */}
          <div className="col-span-2 text-center">{'Moneyline'}</div>
          <div className="col-span-2 text-center">
            {selectedSport === 'UFC' ? 'Method Of Victory' : 'Spread'}
          </div>
          {/* <div className="col-span-2 text-center">
            {selectedSport === 'PGA' ? '' 
            : selectedSport === 'UFC' ? 'Method Of Victory'
            : isEsports  ? 'Map Handicap'
            : selectedSport === 'soccer' ? 'Total Goals'
            : 'Spread'}
          </div> */}
          <div className="col-span-2 text-center">
            {selectedSport === 'UFC' ? 'Total Rounds' : 'Total'}
          </div>
          {/* <div className="col-span-2 text-center">
            {selectedSport === 'PGA' ? '' 
            : selectedSport === 'UFC' ? 'Total Rounds'
            : isEsports ? 'Total Maps'
            : 'Total'}
          </div> */}
        </div>
      </div>

      <div className="w-full flex flex-col">
        {noGamesMessage && (
          <div className="flex justify-center items-center h-20">
            <span className="text-gray-500 text-lg">{noGamesMessage}</span>
          </div>
        )}

        {(!noGamesMessage && upcomingMatchupsByLeague && Object.keys(upcomingMatchupsByLeague).length > 0) &&
          Object.keys(upcomingMatchupsByLeague).map((league, leagueIdx) => (
            <div key={leagueIdx}>
              <div className="league-header py-3 pl-6 font-semibold text-base uppercase">
                {league}
              </div>
              {upcomingMatchupsByLeague[league].map((game, index) => {
                const homeMoneyline = getMoneylineForTeam(game.odds?.odds, game.home_team);
                const awayMoneyline = getMoneylineForTeam(game.odds?.odds, game.away_team);

                let matchingSpreads = {};
                let homeTotal = {};
                let awayTotal = {};
                let methodOfVictoryHome = {};
                let methodOfVictoryAway = {};
                let totalRoundsU = {};
                let totalRoundsO = {};

                if (game.odds?.odds) {
                  matchingSpreads = getSpreadOrTotalGoalsForTeam(
                    game.odds.odds,
                    game.home_team,
                    game.away_team,
                    game.sport
                  );

                  homeTotal = getTotalForTeam(game.odds.odds, 'under');
                  awayTotal = getTotalForTeam(game.odds.odds, 'over');
                  
                  methodOfVictoryHome = getMethodOfVictory(game.odds.odds, game.home_team);
                  methodOfVictoryAway = getMethodOfVictory(game.odds.odds, game.away_team);

                  totalRoundsU = getTotalRounds(game.odds.odds, 'under');
                  totalRoundsO = getTotalRounds(game.odds.odds, 'over');
                }

                const isMMA = game.sport === 'mma';
                const isPGA = game.sport === 'pga';
                const WrapperComponent = isPGA ? 'div' : Link;
                const wrapperProps = isMMA ? {to: `/sports/ufcMatchup/${game.id}`} : { to: `/sports/propBets/${game.game_id}`,  state:{ game: game }  };

                return (
                  <WrapperComponent key={index} {...wrapperProps} className="matchup-card block bg-neutral-50 hover:bg-gray-200 m-2 rounded-lg shadow">
                    <div className="mt-2 flex">
                      <div className="w-full flex items-center">
                        <div className="w-44 ml-5 flex items-center space-x-10">
                          <div className="w-14 h-5 flex flex-col justify-start items-start">
                            <span className="text-zinc-400 text-sm font-normal capitalize">matchup</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-10 items-center py-3">
                      <div className="col-span-2 text-center">
                        <span className="matchup-date flex justify-center items-center text-zinc-500 text-xs xs:text-sm font-normal">
                          {(() => {
                            const startDate = new Date(game.start_date);
                            const isToday = startDate.toDateString() === new Date().toDateString();
                            const formatter = new Intl.DateTimeFormat('en-US', {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                            });

                            const formattedDate = formatter.format(startDate);
                            const formattedTimeWithUpperCaseAMPM = formattedDate.replace('AM', 'AM').replace('PM', 'PM');

                            return isToday ? formattedTimeWithUpperCaseAMPM : startDate.toLocaleDateString();
                          })()}
                        </span>
                      </div>

                      {/* teams */}
                      <div className="col-span-2 flex flex-col items-start matchup-team-container">
                        <div className="flex items-center gap-2">
                          <img
                            className="w-6 h-6 object-cover"
                            src={game.away_team_details?.logo}
                            alt="Away Team Logo"
                            loading="lazy"
                          />
                          <span className="matchup-team text-indigo-950 text-xs xs:text-sm sm:text-base font-bold">
                            {game.away_team}
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <img
                            className="w-6 h-6 object-cover"
                            src={game.home_team_details?.logo}
                            alt="Home Team Logo"
                            loading="lazy"
                          />
                          <span className="matchup-team text-indigo-950 text-xs xs:text-sm sm:text-base font-bold">
                            {game.home_team}
                          </span>
                        </div>
                      </div>

                      {/* Moneyline */}
                      <div className="col-span-1 sm:col-span-2 ml-2 sm:ml-6">
                        <div className="flex justify-center items-center space-x-3 mb-3">
                          <span className="matchup-odds text-zinc-500 text-xs xs:text-sm sm:text-base font-normal">
                            {awayMoneyline?.price || '-'}
                          </span>
                        </div>
                        <div className="flex justify-center items-center space-x-3">
                          <span className="matchup-odds text-zinc-500 text-xs xs:text-sm sm:text-base font-normal">
                            {homeMoneyline?.price || '-'}
                          </span>
                        </div>
                      </div>

                      {/* Spread */}
                      {game.sport === 'mma' ? (
                        <div className="col-span-2 lg:ml-6">
                          <div className="flex justify-around items-center space-x-3 mb-3">
                            <span className="matchup-odds text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                              {methodOfVictoryAway[0]?.name.split(' - ')[1] || '-'}
                            </span>
                            <span className="matchup-odds text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                              {methodOfVictoryAway[0]?.price || '-'}
                            </span>
                          </div>
                          <div className="flex justify-around items-center space-x-3">
                            <span className="matchup-odds text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                              {methodOfVictoryHome[0]?.name.split(' - ')[1] || '-'}
                            </span>
                            <span className="matchup-odds text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                              {methodOfVictoryHome[0]?.price || '-'}
                            </span>
                          </div>
                        </div>
                      ) : (
                      <div className="col-span-1 sm:col-span-2 ml-2 sm:ml-6">
                        <div className="flex justify-around items-center space-x-3 mb-3">
                          <span className="matchup-odds text-zinc-500 text-xs xs:text-sm sm:text-base font-normal">
                            {matchingSpreads?.awayOdds?.selection_points || '-'}
                          </span>
                          <span className="matchup-odds text-zinc-400 text-xs xs:text-sm sm:text-base font-normal">
                            {matchingSpreads?.awayOdds?.price || '-'}
                          </span>
                        </div>
                        <div className="flex justify-around items-center space-x-3">
                          <span className="matchup-odds text-zinc-500 text-xs xs:text-sm sm:text-base font-normal">
                            {matchingSpreads?.homeOdds?.selection_points || '-'}
                          </span>
                          <span className="matchup-odds text-zinc-400 text-xs xs:text-sm sm:text-base font-normal">
                            {matchingSpreads?.homeOdds?.price || '-'}
                          </span>
                        </div>
                      </div>
                      )}

                      {/* Total */}
                      {game.sport === 'mma' ? (
                        <div className="col-span-2 lg:ml-6">
                          <div className="flex justify-around items-center space-x-3 mb-3">
                            <span className="matchup-odds text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                              {totalRoundsO ? <span className="text-green-600">{totalRoundsO?.selection_points}</span> : '-'}
                            </span>
                            <span className="matchup-odds text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                              {totalRoundsO?.price || '-'}
                            </span>
                          </div>
                          <div className="flex justify-around items-center space-x-3">
                            <span className="matchup-odds text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                              {totalRoundsU ? <span className="text-red-600">{totalRoundsU?.selection_points}</span> : '-'}
                            </span>
                            <span className="matchup-odds text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                              {totalRoundsU?.price || '-'}
                            </span>
                          </div>
                        </div>
                      ) : (
                      <div className="col-span-1 sm:col-span-2 ml-2 sm:ml-6">
                        <div className="flex justify-around items-center space-x-3 mb-3 ">
                          <span className="matchup-odds text-zinc-500 text-xs xs:text-sm sm:text-base font-normal">
                            {awayTotal ? <span className="text-green-600">{awayTotal?.selection_points}</span> : '-'}
                          </span>
                          <span className="matchup-odds text-zinc-400 text-xs xs:text-sm sm:text-base font-normal">
                            {awayTotal?.price || '-'}
                          </span>
                        </div>
                        <div className="flex justify-around items-center space-x-3">
                          <span className="matchup-odds text-zinc-500 text-xs xs:text-sm sm:text-base font-normal">
                            {homeTotal ? <span className="text-red-600">{homeTotal?.selection_points}</span> : '-'}
                          </span>
                          <span className="matchup-odds text-zinc-400 text-xs xs:text-sm sm:text-base font-normal">
                            {homeTotal?.price || '-'}
                          </span>
                        </div>
                      </div>
                      )}
                    </div>
                  </WrapperComponent>
                );
              })}
            </div>
          ))}
      </div>
    </div>
  );
};

