import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SidebarContext } from './Sidebar'; 
import { icons } from '../../assets';

// Configuration for navigation links
const navLinks = [
  { path: '/', name: 'Dashboard', icon: icons.dashboard, iconActive: icons.dashboardLight },
  { path: '/feed', name: 'Feed', icon: icons.trade, iconActive: icons.tradeLight },
  { path: '/sports', name: 'Sports', icon: icons.cup, iconActive: icons.cupLight },

  { path: '/sportsbooks', name: 'Sportsbook', icon: icons.sportsbook, iconActive: icons.sportsbookLight },
  { path: '/odds', name: 'Odds', icon: icons.chart, iconActive: icons.chartLight },
  { path: '/education', name: 'Education', icon: icons.education, iconActive: icons.educationLight },

  { path: '/inbox', name: 'Inbox', icon: icons.coin, iconActive: icons.coinLight },
];

const SidebarMainItems = () => {
  return (
    <>
      {navLinks.map((link) => (
        <SidebarItem key={link.name} {...link} />
      ))}
    </>
  );
};

const SidebarItem = ({
  path,
  icon,
  iconActive,
  name,
  alert,
}) => {
  const { expanded, activePath } = useContext(SidebarContext);
  const isActive = path === activePath;
  const iconStyle = !expanded ? { width: '50px', height: '50px' } : {};
  const textStyle = expanded ? 'opacity-100' : 'opacity-0';

  // const textTransition = 'transition-opacity duration-300 ease';
  const textTransition = 'transition-opacity duration-500 ease';



  return (
    <Link to={path}>
      <li
        className={`
          relative flex items-center py-2 px-3 my-1
          text-base font-semibold rounded-md cursor-pointer
          transition-all duration-300 ease-in-out

          ${isActive ? 'text-black bg-primary' : 'text-zinc-400 hover:text-white hover:bg-primary-dark'}
        `}
        //highlight sidebar words above
      //   relative flex items-center py-2 px-3 my-1
      //   text-base font-semibold rounded-md cursor-pointer
      //   transition-colors group
      //   ${isActive ? 'text-white' : 'text-zinc-400'}
      // `}
      >
        <div className="flex items-center justify-center" style={iconStyle}>
          {/* <img loading="lazy" src={isActive ? iconActive : icon} alt={name} /> */}
          <img src={isActive ? iconActive : icon} loading= "lazy" alt={name} className="transition-transform duration-300 ease-in-out group-hover:scale-105" />

        </div>
        <span
          className={`flex items-center overflow-hidden transition-all ${textTransition} ${textStyle} ${expanded ? 'w-52 ml-3' : 'w-0'
            }`}
        > 
        
          {name}
        </span>
        {alert && (
          <div
            className={`absolute right-2 w-2 h-2 rounded bg-zinc-400 ${expanded ? '' : 'top-2'}`}
          />
        )}
        {!expanded && (
          <div
            className={`
              absolute left-full rounded-md px-2 py-1 ml-6
              bg-zinc-100 text-zinc-800 text-sm z-10
              invisible opacity-20 -translate-x-3 transition-opacity duration-300 ease-in-out
              group-hover:visible group-hover:opacity-100 group-hover:translate-x-0

            `}>

            {name}
          </div>
        )}
      </li>
    </Link>
  );
};

export default SidebarMainItems;
