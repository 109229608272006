// import {
//   GET_TEAMS_REQUEST,
//   GET_TEAMS_SUCCESS,
//   GET_TEAMS_FAILURE,
//   GET_ODDS_REQUEST,
//   GET_ODDS_SUCCESS,
//   GET_ODDS_FAILURE,
//   GET_TEAM_REQUEST,
//   GET_TEAM_SUCCESS,
//   GET_TEAM_FAILURE,
//   GET_TEAM_STATS_REQUEST,
//   GET_TEAM_STATS_SUCCESS,
//   GET_TEAM_STATS_FAILURE,
//   GET_GAME_ODDS_REQUEST,
//   GET_GAME_ODDS_SUCCESS,
//   GET_GAME_ODDS_FAILURE,
//   GET_TEAM_PLAYERS_REQUEST,
//   GET_TEAM_PLAYERS_SUCCESS,
//   GET_TEAM_PLAYERS_FAILURE,
//   GET_PLAYER_REQUEST,
//   GET_PLAYER_SUCCESS,
//   GET_PLAYER_FAILURE,
//   GET_TEAM_SCHEDULE_REQUEST,
//   GET_TEAM_SCHEDULE_SUCCESS,
//   GET_TEAM_SCHEDULE_FAILURE,
//   GET_PROP_BETS_REQUEST,
//   GET_PROP_BETS_SUCCESS,
//   GET_PROP_BETS_FAILURE,
//   RESET_PROP_BETS_STATE,
//   GET_LEAGUES_REQUEST,
//   GET_LEAGUES_SUCCESS,
//   GET_LEAGUES_FAILURE,
//   GET_UPCOMING_MATCHUPS_REQUEST,
//   GET_UPCOMING_MATCHUPS_SUCCESS,
//   GET_UPCOMING_MATCHUPS_FAILURE,
//   GET_SCHEDULE_REQUEST,
//   GET_SCHEDULE_SUCCESS,
//   GET_SCHEDULE_FAILURE,
//   SEARCH_SPORTS_REQUEST,
//   SEARCH_SPORTS_SUCCESS,
//   SEARCH_SPORTS_FAILURE,
//   GET_LIVE_GAME_SCORES_REQUEST,
//   GET_LIVE_GAME_SCORES_SUCCESS,
//   GET_LIVE_GAME_SCORES_FAILURE,
//   RESET_LIVE_GAME_SCORES_STATE,
//   GET_PROP_BETS_PLAYERS_REQUEST,
//   GET_PROP_BETS_PLAYERS_SUCCESS,
//   GET_PROP_BETS_PLAYERS_FAILURE,
//   GET_RANDOM_UPCOMING_MATCHUPS_REQUEST,
//   GET_RANDOM_UPCOMING_MATCHUPS_SUCCESS,
//   GET_RANDOM_UPCOMING_MATCHUPS_FAILURE,
//   // GET_TEAM_INJURIES_REQUEST,
//   // GET_TEAM_INJURIES_SUCCESS,
//   // GET_TEAM_INJURIES_FAILURE,
// } from '../constants/SportsConstants.js';

// export const getTeamsReducer = (state = {teams: []}, action) => {
//   switch (action.type) {
//     case GET_TEAMS_REQUEST:
//       return {loading: true};
//     case GET_TEAMS_SUCCESS:
//       return {loading: false, teams: action.payload};
//     case GET_TEAMS_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// export const getTeamReducer = (state = {team: []}, action) => {
//   switch (action.type) {
//     case GET_TEAM_REQUEST:
//       return {loading: true};
//     case GET_TEAM_SUCCESS:
//       return {loading: false, team: action.payload};
//     case GET_TEAM_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// export const getOddsReducer = (state = {odds: []}, action) => {
//   switch (action.type) {
//     case GET_ODDS_REQUEST:
//       return {loading: true};
//     case GET_ODDS_SUCCESS:
//       return {loading: false, odds: action.payload};
//     case GET_ODDS_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// export const getGameOddsReducer = (state = {odds: []}, action) => {
//   switch (action.type) {
//     case GET_GAME_ODDS_REQUEST:
//       return {loading: true};
//     case GET_GAME_ODDS_SUCCESS:
//       return {loading: false, odds: action.payload};
//     case GET_GAME_ODDS_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// export const getTeamStatsReducer = (state = {stats: []}, action) => {
//   switch (action.type) {
//     case GET_TEAM_STATS_REQUEST:
//       return {loading: true};
//     case GET_TEAM_STATS_SUCCESS:
//       return {loading: false, stats: action.payload};
//     case GET_TEAM_STATS_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// export const getTeamPlayersReducer = (state = {players: []}, action) => {
//   switch (action.type) {
//     case GET_TEAM_PLAYERS_REQUEST:
//       return {loading: true};
//     case GET_TEAM_PLAYERS_SUCCESS:
//       return {loading: false, players: action.payload};
//     case GET_TEAM_PLAYERS_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// export const getPlayerReducer = (state = {}, action) => {
//   switch (action.type) {
//     case GET_PLAYER_REQUEST:
//       return {loading: true};
//     case GET_PLAYER_SUCCESS:
//       return {loading: false, player: action.payload};
//     case GET_PLAYER_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// export const getPropBetsPlayersReducer = (state = {}, action) => {
//   switch (action.type) {
//     case GET_PROP_BETS_PLAYERS_REQUEST:
//       return {loading: true};
//     case GET_PROP_BETS_PLAYERS_SUCCESS:
//       return {loading: false, players: action.payload};
//     case GET_PROP_BETS_PLAYERS_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// export const getTeamScheduleReducer = (state = {schedules: []}, action) => {
//   switch (action.type) {
//     case GET_TEAM_SCHEDULE_REQUEST:
//       return {loading: true};
//     case GET_TEAM_SCHEDULE_SUCCESS:
//       return {loading: false, schedules: action.payload};
//     case GET_TEAM_SCHEDULE_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// export const getScheduleReducer = (state = {schedules: []}, action) => {
//   switch (action.type) {
//     case GET_SCHEDULE_REQUEST:
//       return {loading: true};
//     case GET_SCHEDULE_SUCCESS:
//       return {loading: false, schedules: action.payload};
//     case GET_SCHEDULE_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// // Prop Bets Reducer
// export const getPropBetsReducer = (state = {}, action) => {
//   switch (action.type) {
//     case GET_PROP_BETS_REQUEST:
//       return {loading: true};
//     case GET_PROP_BETS_SUCCESS:
//       return {loading: false, propBets: action.payload};
//     case GET_PROP_BETS_FAILURE:
//       return {loading: false, error: action.error};
//     case RESET_PROP_BETS_STATE:
//       return {}; // Assuming {} is your initial state
//     default:
//       return state;
//   }
// };

// // Live Games Reducer
// export const getLiveGamesReducer = (state = {}, action) => {
//   switch (action.type) {
//     case GET_LIVE_GAME_SCORES_REQUEST:
//       return {loading: true};
//     case GET_LIVE_GAME_SCORES_SUCCESS:
//       return {loading: false, liveGame: action.payload};
//     case GET_LIVE_GAME_SCORES_FAILURE:
//       return {loading: false, error: action.error};
//     case RESET_LIVE_GAME_SCORES_STATE:
//       return {}; // Assuming {} is your initial state
//     default:
//       return state;
//   }
// };

// export const getLeaguesReducer = (state = {leagues: []}, action) => {
//   switch (action.type) {
//     case GET_LEAGUES_REQUEST:
//       return {loading: true};
//     case GET_LEAGUES_SUCCESS:
//       return {loading: false, leagues: action.payload};
//     case GET_LEAGUES_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// const initialState = {
//   loading: false,
//   matchups: {
//     NBA: [],
//     NFL: [],
//     NHL: [],
//     MLB: [],
//     UFC: [],
//     // other sports...
//   },
//   error: null,
// };

// // export const getUpcomingMatchupsReducer = (state = initialState, action) => {
// //   switch (action.type) {
// //     case GET_UPCOMING_MATCHUPS_REQUEST:
// //       return {...state, loading: true};
// //     case GET_UPCOMING_MATCHUPS_SUCCESS:
// //       // Use the category from the action to update the specific category matchups
// //       const category = action.category;
// //       return {
// //         ...state,
// //         loading: false,
// //         matchups: {
// //           ...state.matchups,
// //           [category]: action.payload,
// //         },
// //       };
// //     case GET_UPCOMING_MATCHUPS_FAILURE:
// //       return {...state, loading: false, error: action.error};
// //     default:
// //       return state;
// //   }
// // };

// export const getUpcomingMatchupsReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case GET_UPCOMING_MATCHUPS_REQUEST:
//       return { ...state, loading: true };
//     case GET_UPCOMING_MATCHUPS_SUCCESS:
//       console.log(`Reducer received matchups for ${action.category}:`, action.payload); // Ensure all matchups are logged
//       const category = action.category;
//       return {
//         ...state,
//         loading: false,
//         matchups: {
//           ...state.matchups,
//           [category]: action.payload,
//         },
//       };
//     case GET_UPCOMING_MATCHUPS_FAILURE:
//       return { ...state, loading: false, error: action.error };
//     default:
//       return state;
//   }
// };




// export const getRandomUpcomingMatchupsReducer = (state = {}, action) => {
//   switch (action.type) {
//     case GET_RANDOM_UPCOMING_MATCHUPS_REQUEST:
//       return {loading: true};
//     case GET_RANDOM_UPCOMING_MATCHUPS_SUCCESS:
//       return {loading: false, matchups: action.payload};
//     case GET_RANDOM_UPCOMING_MATCHUPS_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// export const searchSportsReducer = (state = {sport: []}, action) => {
//   switch (action.type) {
//     case SEARCH_SPORTS_REQUEST:
//       return {loading: true};
//     case SEARCH_SPORTS_SUCCESS:
//       return {loading: false, sport: action.payload};
//     case SEARCH_SPORTS_FAILURE:
//       return {loading: false, error: action.error};
//     default:
//       return state;
//   }
// };

// // export const getInjuriesReducer = (state = initialState, action) => {
// //   switch (action.type) {
// //     case GET_TEAM_INJURIES_REQUEST:
// //       return {
// //         ...state,
// //         loading: true,
// //       };
// //     case GET_TEAM_INJURIES_SUCCESS:
// //       return {
// //         ...state,
// //         loading: false,
// //         injuries: action.payload,
// //       };
// //     case GET_TEAM_INJURIES_FAILURE:
// //       return {
// //         ...state,
// //         loading: false,
// //         error: action.error,
// //       };
// //     default:
// //       return state;
// //   }
// // };



// trying to add getleagueodds 
import {
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,
  GET_ODDS_REQUEST,
  GET_ODDS_SUCCESS,
  GET_ODDS_FAILURE,
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  GET_TEAM_STATS_REQUEST,
  GET_TEAM_STATS_SUCCESS,
  GET_TEAM_STATS_FAILURE,
  GET_GAME_ODDS_REQUEST,
  GET_GAME_ODDS_SUCCESS,
  GET_GAME_ODDS_FAILURE,
  GET_TEAM_PLAYERS_REQUEST,
  GET_TEAM_PLAYERS_SUCCESS,
  GET_TEAM_PLAYERS_FAILURE,
  GET_PLAYER_REQUEST,
  GET_PLAYER_SUCCESS,
  UPDATE_PLAYER_RESULT,
  GET_PLAYER_FAILURE,
  GET_TEAM_SCHEDULE_REQUEST,
  GET_TEAM_SCHEDULE_SUCCESS,
  GET_TEAM_SCHEDULE_FAILURE,
  GET_PROP_BETS_REQUEST,
  GET_PROP_BETS_SUCCESS,
  GET_PROP_BETS_UPDATE_AWAY_TEAM_PLAYER,
  GET_PROP_BETS_UPDATE_HOME_TEAM_PLAYER,
  GET_PROP_BETS_FAILURE,
  RESET_PROP_BETS_STATE,
  GET_LEAGUES_REQUEST,
  GET_LEAGUES_SUCCESS,
  GET_LEAGUES_FAILURE,
  GET_UPCOMING_MATCHUPS_REQUEST,
  GET_UPCOMING_MATCHUPS_SUCCESS,
  GET_UPCOMING_MATCHUPS_FAILURE,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE,
  SEARCH_SPORTS_REQUEST,
  SEARCH_SPORTS_SUCCESS,
  SEARCH_SPORTS_FAILURE,
  GET_LIVE_GAME_SCORES_REQUEST,
  GET_LIVE_GAME_SCORES_SUCCESS,
  GET_LIVE_GAME_SCORES_FAILURE,
  RESET_LIVE_GAME_SCORES_STATE,
  GET_PROP_BETS_PLAYERS_REQUEST,
  GET_PROP_BETS_PLAYERS_SUCCESS,
  GET_PROP_BETS_PLAYERS_FAILURE,
  GET_RANDOM_UPCOMING_MATCHUPS_REQUEST,
  GET_RANDOM_UPCOMING_MATCHUPS_SUCCESS,
  GET_RANDOM_UPCOMING_MATCHUPS_FAILURE,
  GET_LEAGUE_ODDS_REQUEST,
  GET_LEAGUE_ODDS_SUCCESS,
  GET_LEAGUE_ODDS_FAILURE,
  GET_UFC_FIGHTER_DETAILS_REQUEST,
  GET_UFC_FIGHTER_DETAILS_SUCCESS,
  GET_UFC_FIGHTER_DETAILS_FAILURE,
} from '../constants/SportsConstants.js';

export const getTeamsReducer = (state = { teams: [] }, action) => {
  switch (action.type) {
    case GET_TEAMS_REQUEST:
      return { loading: true };
    case GET_TEAMS_SUCCESS:
      return { loading: false, teams: action.payload };
    case GET_TEAMS_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const getTeamReducer = (state = { team: [] }, action) => {
  switch (action.type) {
    case GET_TEAM_REQUEST:
      return { loading: true };
    case GET_TEAM_SUCCESS:
      return { loading: false, team: action.payload };
    case GET_TEAM_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const getOddsReducer = (state = { odds: [] }, action) => {
  switch (action.type) {
    case GET_ODDS_REQUEST:
      return { loading: true };
    case GET_ODDS_SUCCESS:
      return { loading: false, odds: action.payload };
    case GET_ODDS_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const getGameOddsReducer = (state = { odds: [] }, action) => {
  switch (action.type) {
    case GET_GAME_ODDS_REQUEST:
      return { loading: true };
    case GET_GAME_ODDS_SUCCESS:
      return { loading: false, odds: action.payload };
    case GET_GAME_ODDS_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const getTeamStatsReducer = (state = { stats: [] }, action) => {
  switch (action.type) {
    case GET_TEAM_STATS_REQUEST:
      return { loading: true };
    case GET_TEAM_STATS_SUCCESS:
      return { loading: false, stats: action.payload };
    case GET_TEAM_STATS_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const getTeamPlayersReducer = (state = { players: [] }, action) => {
  switch (action.type) {
    case GET_TEAM_PLAYERS_REQUEST:
      return { loading: true };
    case GET_TEAM_PLAYERS_SUCCESS:
      return { loading: false, players: action.payload };
    case GET_TEAM_PLAYERS_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const getPlayerReducer = (state = {
  player: {
    playerDetails: [],
    teamDetails: [],
    playerOdds: {},
    playerResultsWithScores: [],
    isSubscribed: false
  },
  loading: true
}, action) => {
  switch (action.type) {
    case GET_PLAYER_REQUEST:
      return { ...state, loading: true };
    case GET_PLAYER_SUCCESS:
      // return {loading: false, player: action.payload};

      return { loading: false, player: { ...state.player, ...action.payload } };
    case GET_PLAYER_FAILURE:
      return { loading: false, error: action.error };
    case UPDATE_PLAYER_RESULT:
      return { player: { ...state.player, ... action.payload } };
    default:
      return state;
  }
};

export const getPropBetsPlayersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROP_BETS_PLAYERS_REQUEST:
      return { loading: true };
    case GET_PROP_BETS_PLAYERS_SUCCESS:
      return { loading: false, players: action.payload };
    case GET_PROP_BETS_PLAYERS_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const getTeamScheduleReducer = (state = { schedules: [] }, action) => {
  switch (action.type) {
    case GET_TEAM_SCHEDULE_REQUEST:
      return { loading: true };
    case GET_TEAM_SCHEDULE_SUCCESS:
      return { loading: false, schedules: action.payload };
    case GET_TEAM_SCHEDULE_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const getScheduleReducer = (state = { schedules: [] }, action) => {
  switch (action.type) {
    case GET_SCHEDULE_REQUEST:
      return { loading: true };
    case GET_SCHEDULE_SUCCESS:
      return { loading: false, schedules: action.payload };
    case GET_SCHEDULE_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

// Prop Bets Reducer
export const getPropBetsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROP_BETS_REQUEST:
      return { loading: true };
    case GET_PROP_BETS_SUCCESS:
      return { loading: false, propBets: action.payload };
    case GET_PROP_BETS_FAILURE:
      return { loading: false, error: action.error };
    case RESET_PROP_BETS_STATE:
      return {}; // Assuming {} is your initial state
    case GET_PROP_BETS_UPDATE_AWAY_TEAM_PLAYER:
      return { propBets: { ...state.propBets, away_team_players: action.payload } };
    case GET_PROP_BETS_UPDATE_HOME_TEAM_PLAYER:
      return { propBets: { ...state.propBets, home_team_players: action.payload } };
    default:
      return state;
  }
};

// Live Games Reducer
export const getLiveGamesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LIVE_GAME_SCORES_REQUEST:
      return { loading: true };
    case GET_LIVE_GAME_SCORES_SUCCESS:
      return { loading: false, liveGame: action.payload };
    case GET_LIVE_GAME_SCORES_FAILURE:
      return { loading: false, error: action.error };
    case RESET_LIVE_GAME_SCORES_STATE:
      return {}; // Assuming {} is your initial state
    default:
      return state;
  }
};

export const getLeaguesReducer = (state = { leagues: [] }, action) => {
  switch (action.type) {
    case GET_LEAGUES_REQUEST:
      return { loading: true };
    case GET_LEAGUES_SUCCESS:
      return { loading: false, leagues: action.payload };
    case GET_LEAGUES_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  matchups: {
    NBA: [],
    NFL: [],
    NHL: [],
    MLB: [],
    UFC: [],
    // other sports...
  },
  error: null,
};

export const getUpcomingMatchupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPCOMING_MATCHUPS_REQUEST:
      return { ...state, loading: true };
    case GET_UPCOMING_MATCHUPS_SUCCESS:
      // console.log(`Reducer received matchups for ${action.category}:`, action.payload); // Ensure all matchups are logged
      const category = action.category;
      return {
        ...state,
        loading: false,
        matchups: {
          ...state.matchups,
          [category]: action.payload,
        },
      };
    case GET_UPCOMING_MATCHUPS_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const getRandomUpcomingMatchupsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RANDOM_UPCOMING_MATCHUPS_REQUEST:
      return { loading: true };
    case GET_RANDOM_UPCOMING_MATCHUPS_SUCCESS:
      return { loading: false, matchups: action.payload };
    case GET_RANDOM_UPCOMING_MATCHUPS_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const searchSportsReducer = (state = { sport: [] }, action) => {
  switch (action.type) {
    case SEARCH_SPORTS_REQUEST:
      return { loading: true };
    case SEARCH_SPORTS_SUCCESS:
      return { loading: false, sport: action.payload };
    case SEARCH_SPORTS_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

// Initial state for league odds
const leagueOddsInitialState = {
  odds: [],
  loading: false,
  error: null,
};

// Reducer for handling league odds actions
// export const getLeagueOddsReducer = (state = leagueOddsInitialState, action) => {
//   switch (action.type) {
//     case GET_LEAGUE_ODDS_REQUEST:
//       return {
//         ...state,
//         loading: true,
//         error: null,
//       };
//     case GET_LEAGUE_ODDS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         odds: action.payload,
//       };
//     case GET_LEAGUE_ODDS_FAILURE:
//       return {
//         ...state,
//         loading: false,
//         error: action.error,
//       };
//     default:
//       return state;
//   }
// };
export const getLeagueOddsReducer = (state = { odds: [], loading: true }, action) => {
  switch (action.type) {
    case GET_LEAGUE_ODDS_REQUEST:
      return { loading: true, odds: [] };
    case GET_LEAGUE_ODDS_SUCCESS:
      return { loading: false, odds: action.payload };
    case GET_LEAGUE_ODDS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const getUfcFighterDetailsReducer = (state = { fighter: {} }, action) => {
  switch (action.type) {
    case GET_UFC_FIGHTER_DETAILS_REQUEST:
      return { loading: true };
    case GET_UFC_FIGHTER_DETAILS_SUCCESS:
      return { loading: false, fighter: action.payload };
    case GET_UFC_FIGHTER_DETAILS_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};
