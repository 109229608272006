import { useState } from "react";
import { icons } from "../assets";
import { GetPremiumModal } from "./modals/GetPremiumModal";

export const PaywallComponent = ({ isPremium, darkMode }) => {
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  const [isPremiumModalVisible, setPremiumModalVisible] = useState(false);

  const openPremiumModal = () => setPremiumModalVisible(true);

  return (
    <div className="hidden md:flex md:w-1/2 xl:w-3/4 h-full flex-col justify-center items-center ml-5 mb-10">
      <img
        src={darkMode ? icons.logo : icons.logoDark}
        alt="Brand illustration"
        loading="lazy"
        style={{ width: '200px' }}
      />
      <h2 className={`text-center text-3xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-black'} `}>
        {userInfo ? (
          isPremium ? (
            'You are ready to post things on the feed.'
          ) : (
            <>
              To post on the feed, please <span onClick={openPremiumModal} className="text-primary cursor-pointer">subscribe</span> to unlock this feature.
            </>
          )
        ) : (
          <>
            <a href="/register" className="text-primary">Join us</a> and share your moments on the feed!
          </>
        )}
      </h2>

      <GetPremiumModal
        isVisible={isPremiumModalVisible}
        onClose={() => setPremiumModalVisible(false)}
      />
    </div>
  );
};
