import React, { useState } from 'react';
import '../../../styles/login/loginStyle.css';
import { icons } from '../../../assets';
import { useDispatch } from 'react-redux';
import { findUserByIdentifier, initiatePasswordReset } from '../../../redux/actions/UserActions';
import { emailPattern, phonePattern } from '../../../utils/regexUtils';
import { Avatar } from '@mui/material';
import { useSnackbar } from '../../../contexts/SnackbarContext';

export const ForgotPassword = () => {
  const [identifier, setIdentifier] = useState('');
  const [userData, setUserData] = useState(null);

  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();

  const submitHandler = async (e) => {
    e.preventDefault();
        
    if (!emailPattern.test(identifier) && !phonePattern.test(identifier)) {
      showMessage('Please enter a valid email address', 'error')
      return;
    }

    const lowerCaseIdentifier = identifier.toLowerCase(); // Convert identifier to lowercase
    const user = await dispatch(findUserByIdentifier(identifier))
    if (user) setUserData(user)
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    if (!emailPattern.test(identifier) && !phonePattern.test(identifier)) {
      showMessage('Please enter a valid email address.', 'error')
      return;
    }

    await dispatch(initiatePasswordReset(identifier))
    showMessage(`Please check your email. We've sent you a link to reset your password. If you don't see it, check your spam or junk folder.`, 'success')
  };

  return (
    <div className="SignUp flex w-screen h-screen overflow-x-hidden">
      <div className="LeftSide w- flex-1 flex-col items-center justify-center bg-neutral-800 text-white md:flex hidden">
        <img className="mb-6" src={icons.logo} alt="Brand illustration" loading="lazy"/>
        <h2 className="text-white text-3xl font-bold mb-4">
          Connect. Win. Repeat.
        </h2>
        <p className="text-center w-[400px] text-white text-sm font-semibold">
          Easily check betting lines.
        </p>
      </div>

      <div className="Form flex flex-1 flex-col items-center justify-center bg-neutral-50 h-screen">
        <div className="w-2/4 pt-10">
          <h1 className="text-center text-neutral-900 text-2xl font-bold">
            Reset your password
          </h1>
        </div>
        {userData ? <UserDetailsComponent userData={userData} handleContinue={handleContinue} setUserData={setUserData} /> : (
          <div className="Social mt-6 w-2/4 h-1/3">
            <form className="mb-4" onSubmit={submitHandler}>
              <div className="mb-6 md:w-full">
                <label
                  htmlFor="identifier"
                  className="w-36 h-6 text-zinc-500 text-xs font-medium leading-none"
                >
                  EMAIL
                </label>
                <input
                  onChange={e => setIdentifier(e.target.value)}
                  onInput={e => setIdentifier(e.target.value)}
                  value={identifier}
                  className="w-full p-2 h-12 rounded-xl border border-lightGray focus:shadow-outline"
                  type="text"
                  name="identifier"
                  id="identifier"
                />
              </div>
              <button
                type="submit"
                className="bg-primary rounded-xl mt-4 flex justify-center items-center h-[48px] p-5 text-sm font-semibold w-full"
              >
                SUBMIT
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};


export const UserDetailsComponent = ({ userData, handleContinue, setUserData }) => {
  return (
    <div className="mt-6 w-2/4 h-1/3">
      <div className="rounded overflow-hidden shadow-lg p-3">
      <h4 className="text-gray-700 text-base">Is this you?</h4>
        <div className='flex flex-row gap-3 items-center mt-2'>
          <Avatar alt="avatar" src={userData.profileImg} />
          <h4 className="text-gray-700 text-base">Name: {userData.name}</h4>
        </div>
        <div className="px-6 py-4">
          <p className="text-gray-700 text-base">
            {userData.email ? `Email: ${userData.email}` : `Phone: ${userData.phone}`}
          </p>
        </div>
        <div className='flex flex-row justify-around gap-3'>
          <button
            onClick={() => setUserData(null)}
            className="bg-gray rounded-xl mt-4 flex justify-center items-center h-[48px] p-5 text-sm font-semibold w-full"
          >
            Back
          </button>
          <button
            onClick={handleContinue}
            className="bg-primary rounded-xl mt-4 flex justify-center items-center h-[48px] p-5 text-sm font-semibold w-full"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}
