import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../assets';
import '../../styles/Dashboard/dashboardStyle.css'

const navLinks = [
  { path: '/', name: 'Dashboard', icon: icons.dashboard, iconActive: icons.dashboardLight },
  { path: '/feed', name: 'Feed', icon: icons.trade, iconActive: icons.tradeLight },
  { path: '/sports', name: 'Sports', icon: icons.cup, iconActive: icons.cupLight },
  { path: '/sportsbooks', name: 'Sportsbook', icon: icons.sportsbook, iconActive: icons.sportsbookLight },
  { path: '/odds', name: 'Odds', icon: icons.chart, iconActive: icons.chartLight },
  { path: '/education', name: 'Education', icon: icons.education, iconActive: icons.educationLight },
  { path: '/inbox', name: 'Inbox', icon: icons.coin, iconActive: icons.coinLight },
];

export const SidebarModal = ({ onClose, darkMode }) => {
  return (
    <div className="MobilePopup fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50 transition-opacity z-40" onClick={onClose}></div>
      <div className={`relative ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-xl shadow-lg z-50 w-full max-w-sm mx-4 sm:mx-6 md:mx-8 overflow-y-auto max-h-[90vh] p-4`}>
        <button className="absolute top-2 right-2" onClick={onClose}>
          <img src={icons.closeLight} alt="Close" className="w-6 h-6" />
        </button>
        <ul>
          {navLinks.map((link) => (
            <li key={link.name} className="mb-4">
              <Link to={link.path} onClick={onClose} className="flex items-center">
                <img src={link.icon} alt={link.name} className="w-6 h-6 mr-2" />
                <span className={`${darkMode ? 'text-white' : 'text-black'}`}>{link.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};