import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SidebarContext } from './Sidebar';
import { icons } from "../../assets";
import DarkModeToggle from '../DarkModeToggle';


export const SidebarFooter = ({
  setNotificationsModalVisible, 
  setSupportModalVisible, 
  setPremiumModalVisible, 
  setProfileModalVisible, 
  userDetails
}) => {
  const navigate = useNavigate();
  const { expanded } = useContext(SidebarContext);

  const footerItems = [
    {
      name: 'Support',
      icon: icons.headphones,
      onClick: () => setSupportModalVisible(prev => !prev),
    },
    {
      name: 'Get Premium',
      icon: icons.award,
      onClick: () => setPremiumModalVisible(prev => !prev),
    },
    ...(userDetails ? [{
      name: 'Profile',
      icon: userDetails.profileImg || icons.defaultProfile,
      onClick: () => setProfileModalVisible(prev => !prev),
    }] : []),
  ];

  const textStyle = expanded ? 'opacity-100 ml-3' : 'opacity-0';
  const textTransition = 'transition-opacity duration-500 ease';

  if (!expanded) {
    return null;
  }

  return (
    <div className="SidebarFooter flex flex-col items-start mt-auto mb-4 px-3 w-full">
      {!userDetails ? (
        <button

          className="mb-4 flex items-center justify-center w-full h-10 bg-primary rounded-lg transform hover:bg-primary-dark hover:scale-105 transition-all duration-300 ease-in-out"

          onClick={() => navigate('/login')}
        >
          <span className="text-base text-black font-semibold">Login</span>
        </button>
      ) : (
        footerItems.map((item, index) => (
          <button
            key={index}
            className="mb-4 flex items-center gap-2 justify-start w-full transition-all duration-300 ease-in-out hover:bg-primary-dark hover:scale-105"
            onClick={item.onClick}
          >
            <div className="flex items-center justify-center w-6 h-6">
              <img 
                className="icon-size w-6 h-6 transition-transform duration-300 ease-in-out group-hover:scale-105" 
                loading="lazy" 
                src={item.icon} 
                alt={item.name} 
              />
            </div>
            <span className={`text-base text-zinc-400 font-semibold transition-all ${textTransition} ${textStyle}`}>
              {item.name}
            </span>
          </button>
        ))
      )}
    </div>
  );
};