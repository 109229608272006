import axiosInstance from "../../core/axios/axiosInstance";

const login = async (email, password, phone) => {
  const response = await axiosInstance.post('/users/login', { email, password, phone });
  return response.data;
};

const checkForTrialUsers = async (email) => {
  const response = await axiosInstance.post('/users/check-trial', { email });
  return response.data;
};

const signupTrial = async (email, name) => {
  const response = await axiosInstance.post('/users/signup', { email, name });
  return response.data;
}

const fetchUserDetails = async (id) => {
  const response = await axiosInstance.get(`/users/${id}`);
  return response.data;
};

const register = async (name, email, password, phone) => {
  const response = await axiosInstance.post(`/users/register`, { name, email, password, phone });
  return response.data;
};

const verify = async (email, phone, code) => {
  const response = await axiosInstance.post(`/users/verify`, { email, phone, code, });
  return response.data;
};

const loginWithGoogle = async (token) => {
  const response = await axiosInstance.post(`/users/social/google`, { token });
  return response.data;
};

const loginWithFacebook = async (userID, accessToken) => {
  const response = await axiosInstance.post(`/users/social/facebook`, { userID, accessToken });
  return response.data;
};

const updateUserInterests = async (interests, id) => {
  const response = await axiosInstance.put(`/users/${id}/interests`, { interests });
  return response.data;
};

const getSuggestedUsers = async (userId) => {
  const response = await axiosInstance.get(`/users/suggested-users`, { userId });
  return response.data;
};

const createCheckoutSession = async (plan, userId) => {
  const response = await axiosInstance.post(`/users/create-subscription-checkout-session`, { plan, userId });
  return response.data;
};

const checkAccountIsPremium = async (userId) => {
  const response = await axiosInstance.get(`/users/user-subscription/${userId}`);
  return response.data;
};

const cancelSubscription = async () => {
  const response = await axiosInstance.delete(`/users/cancel-subscription`);
  return response.data;
};

const sendSupportEmail = async (userEmail, userName, userMessage) => {
  const response = await axiosInstance.post(`/users/submit-support-request`, { userEmail, userName, userMessage });
  return response.data;
};

const updateUserProfile = async (formData) => {
  const response = await axiosInstance.post(`/users/update-account`, formData);
  return response.data;
};

const changePassword = async (oldPassword, newPassword) => {
  const response = await axiosInstance.post(`/users/change-password`, { oldPassword, newPassword });
  return response.data;
};

const initiatePasswordReset = async (identifier) => {
  const response = await axiosInstance.post(`/users/initiate-password-reset`, { identifier });
  return response.data;
};

const resetPassword = async (token, newPassword) => {
  const response = await axiosInstance.post(`/users/reset-password`, { token, newPassword });
  return response.data;
};

const findUserByIdentifier = async (identifier) => {
  const response = await axiosInstance.post(`/users/find-user-by-identifier`, { identifier });
  return response.data;
};

export const userService = {
  fetchUserDetails,
  login,
  checkForTrialUsers,
  signupTrial,
  register,
  verify,
  loginWithGoogle,
  loginWithFacebook,
  updateUserInterests,
  getSuggestedUsers,
  createCheckoutSession,
  checkAccountIsPremium,
  sendSupportEmail,
  updateUserProfile,
  cancelSubscription,
  changePassword,
  initiatePasswordReset,
  resetPassword,
  findUserByIdentifier,
};