import {formatDistanceToNow} from 'date-fns';

// Formats a date string to a human-readable form, including the year.
export function formatDateStringWithYear(dateString) {
  const options = {year: 'numeric', month: 'short', day: 'numeric'};
  return new Date(dateString).toLocaleDateString('en-US', options);
}

// Formats a Date object to a human-readable form, excluding the year.
export function formatDateToMonthDay(date) {
  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
  });
  return formatter.format(date);
}

export function formatFullDateWithOrdinalSuffix(dateInput) {
  const getOrdinalSuffix = day => {
    if (11 <= day && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  // Validate date input
  const dateObject = new Date(dateInput);
  if (isNaN(dateObject.getTime())) {
    console.error('Invalid date input:', dateInput);
    return null;
  }

  // Options for formatting the date parts
  const options = {weekday: 'long', month: 'long', day: 'numeric'};
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(dateObject);

  // Extracting the parts
  const dayOfWeek = parts.find(part => part.type === 'weekday').value;
  const month = parts.find(part => part.type === 'month').value;
  const day = parseInt(parts.find(part => part.type === 'day').value, 10);

  // Combining to get the desired format with ordinal suffix
  return `${dayOfWeek}, ${month} ${day}${getOrdinalSuffix(day)}`;
}

export function formatDateRelativeToToday(dateString) {
  const inputDate = new Date(dateString);
  const now = new Date();

  // Using toLocaleDateString to compare dates directly can be inefficient and potentially inaccurate due to locale differences.
  // Instead, compare the year, month, and date parts.
  const isToday =
    inputDate.getDate() === now.getDate() &&
    inputDate.getMonth() === now.getMonth() &&
    inputDate.getFullYear() === now.getFullYear();

  if (isToday) {
    const diffHours = (now - inputDate) / (1000 * 60 * 60);
    if (diffHours < 12) {
      return inputDate.getHours() < 12 ? 'This morning' : 'This afternoon';
    }
    return 'Today';
  } else {
    // Use Intl.DateTimeFormat for flexibility and to avoid locale-specific discrepancies.
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    return formatter.format(inputDate);
  }
}

export const formatDateStringToShort = dateString => {
  const date = new Date(dateString);

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const day = days[date.getDay()]; // Use getDay() to use the local time zone day

  let hours = date.getHours(); // Use getHours() to use the local time zone hours
  const isPM = hours >= 12;
  hours = isPM ? (hours > 12 ? hours - 12 : hours) : hours === 0 ? 12 : hours;
  const formattedHours = hours.toString().padStart(2, '0');

  const minutes = date.getMinutes().toString().padStart(2, '0'); // Use getMinutes() for local time zone
  const formattedTime = `${formattedHours}:${minutes} ${isPM ? 'PM' : 'AM'}`;

  return `${day} ${formattedTime}`;
};

export function formatElapsedTime(date) {
  const now = new Date();
  const past = new Date(date);
  const diff = now - past;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (seconds < 60) {
    return `${seconds} sec${seconds > 1 ? 's' : ''} ago`;
  } else if (minutes < 60) {
    return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (days < 7) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else {
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  }
}

export function formatTimeOrDate(dateString) {
  const startDate = new Date(dateString);
  const now = new Date();

  // Check if the date is today by comparing the formatted date strings
  const isToday = startDate.toDateString() === now.toDateString();

  if (isToday) {
    // Format the time in hours and minutes with AM/PM
    const formatter = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    // Format the start date and ensure AM/PM are upper case
    const formattedTime = formatter.format(startDate);
    return formattedTime.replace('AM', 'AM').replace('PM', 'PM');
  } else {
    // For dates not today, return a simple locale date string
    return startDate.toLocaleDateString('en-US');
  }
}

export const formatToLocaleTimeString = dateInput => {
  let date;

  // Check for Firestore timestamp object
  if (dateInput && typeof dateInput.toDate === 'function') {
    date = dateInput.toDate();
    // Check if input is a string that needs to be converted to a Date object
  } else if (typeof dateInput === 'string') {
    date = new Date(dateInput);
    // Check for JavaScript Date object
  } else if (dateInput instanceof Date && !isNaN(dateInput.valueOf())) {
    date = dateInput;
  } else {
    console.error('Invalid date input:', dateInput);
    return ''; // Returns an empty string for invalid inputs
  }

  // Format the date to a locale time string
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

export const formatGameDate = dateString => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
