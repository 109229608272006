import React from 'react';
import '../styles/mobile/darkmodeToggle.css';
import {icons} from '../assets';

const DarkModeToggle = ({ darkMode, toggleDarkMode }) => {
  return (
    <>
      <input type="checkbox" id="darkmode-toggle" checked={darkMode} onChange={toggleDarkMode} className="fixed"/>
      <label htmlFor="darkmode-toggle" className="dark-mode-toggle-label">
        <img src={icons.sun} alt="Sun Icon" className="sun-icon" />
        <img src={icons.moon} alt="Moon Icon" className="moon-icon" />
      </label>
    </>
  );
};

export default DarkModeToggle;



// tried using an invisible element to mimic the side bar and move the button but it didn't work either or works
// import React from 'react';
// import '../styles/mobile/darkmodeToggle.css';
// import { icons } from '../assets';

// const DarkModeToggle = ({ darkMode, toggleDarkMode }) => {
//   return (
//     <>
//       <div className="sidebar-hover-proxy"></div>
//       <input type="checkbox" id="darkmode-toggle" checked={darkMode} onChange={toggleDarkMode} className="fixed"/>
//       <label htmlFor="darkmode-toggle" className="dark-mode-toggle-label">
//         <img src={icons.sun} alt="Sun Icon" className="sun-icon" />
//         <img src={icons.moon} alt="Moon Icon" className="moon-icon" />
//       </label>
//     </>
//   );
// };

// export default DarkModeToggle;
