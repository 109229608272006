import React from 'react';
import { icons } from '../../../assets';
import '../../../styles/Dashboard/followUsComponent.css';

export const FollowUsComponent = ({ darkMode }) => {
  return (
    <div className="follow-us-component">
      <div className={`text-justify text-indigo-950 text-xl font-semibold ${darkMode ? 'text-white' : 'text-black'}`}>
        Follow Us
      </div>
      <div className="flex flex-row flex-wrap gap-3 justify-center">
        <a href="https://www.facebook.com/profile.php?id=61550913958038&mibextid=LQQJ4d" className="social-icon facebook">
          <img src={icons.facebookLogoWhite} alt="Facebook" loading="lazy" />
        </a>
        <a href="https://www.instagram.com/elparlay/?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" className="social-icon instagram">
          <img src={icons.instagramLogoWhite} alt="Instagram" loading="lazy" />
        </a>
        <a href="https://x.com/elparlay_" className="social-icon twitter">
          <img src={icons.twitterLogoWhite} alt="Twitter" loading="lazy" />
        </a>
        <a href="https://www.linkedin.com/in/nick-schroeder-a6a0b6277?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" className="social-icon linkedin">
          <img src={icons.linkedinLogoWhite} alt="LinkedIn" loading="lazy" />
        </a>
        <a href="https://www.tiktok.com/@elparlay?_t=8juWu6pKJbO&_r=1" className="social-icon tiktok">
          <img src={icons.tiktokLogoWhite} alt="TikTok" loading="lazy" />
        </a>
      </div>
    </div>
  );
};