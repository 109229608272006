import axios from 'axios';
import {URL} from '../Url';
import {
  GET_NEWS_FAILURE,
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  SEARCH_NEWS_REQUEST,
  SEARCH_NEWS_SUCCESS,
  SEARCH_NEWS_FAILURE,
} from '../constants/NewsConstants';
import { newsService } from '../../constants/services/news';

export const getLatestNews = () => async dispatch => {
  dispatch({type: GET_NEWS_REQUEST});
  try {
    const response = await newsService.getLatestNews()
    dispatch({type: GET_NEWS_SUCCESS, payload: response});
  } catch (error) {
    dispatch({type: GET_NEWS_FAILURE, error});
  }
};

export const searchNews = topic => async dispatch => {
  dispatch({type: SEARCH_NEWS_REQUEST});
  try {
    const response = await newsService.searchNews(topic)
    dispatch({type: SEARCH_NEWS_SUCCESS, payload: response});
  } catch (error) {
    dispatch({type: SEARCH_NEWS_FAILURE, error});
  }
};
