import React from 'react';
import { Alert, Snackbar } from '@mui/material';

export default function SnackbarAlert({ open, message, onClose, severity = "success" }) {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
