export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const CHECK_FOR_TRIAL_USERS_REQUEST = 'CHECK_FOR_TRIAL_USERS_REQUEST';
export const CHECK_FOR_TRIAL_USERS_SUCCESS = 'CHECK_FOR_TRIAL_USERS_SUCCESS';
export const CHECK_FOR_TRIAL_USERS_FAIL = 'CHECK_FOR_TRIAL_USERS_FAIL';

export const SIGNUP_TRIAL_USER_REQUEST = 'SIGNUP_TRIAL_USER_REQUEST';
export const SIGNUP_TRIAL_USER_SUCCESS = 'SIGNUP_TRIAL_USER_SUCCESS';
export const SIGNUP_TRIAL_USER_FAIL = 'SIGNUP_TRIAL_USER_FAIL';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS';
export const USER_VERIFY_FAIL = 'USER_VERIFY_FAIL';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';

export const USER_UPDATE_INTERESTS_REQUEST = 'USER_UPDATE_INTERESTS_REQUEST';
export const USER_UPDATE_INTERESTS_SUCCESS = 'USER_UPDATE_INTERESTS_SUCCESS';
export const USER_UPDATE_INTERESTS_FAIL = 'USER_UPDATE_INTERESTS_FAIL';

export const SEND_SUPPORT_REQUEST = 'SEND_SUPPORT_REQUEST';
export const SEND_SUPPORT_SUCCESS = 'SEND_SUPPORT_SUCCESS';
export const SEND_SUPPORT_FAIL = 'SEND_SUPPORT_FAIL';

export const GET_SUGGESTED_USERS_REQUEST = 'GET_SUGGESTED_USERS_REQUEST';
export const GET_SUGGESTED_USERS_SUCCESS = 'GET_SUGGESTED_USERS_SUCCESS';
export const GET_SUGGESTED_USERS_FAIL = 'GET_SUGGESTED_USERS_FAIL';

export const CREATE_SUBSCRIPTION_SESSION_REQUEST =
  'CREATE_SUBSCRIPTION_SESSION_REQUEST';
export const CREATE_SUBSCRIPTION_SESSION_SUCCESS =
  'CREATE_SUBSCRIPTION_SESSION_SUCCESS';
export const CREATE_SUBSCRIPTION_SESSION_FAIL =
  'CREATE_SUBSCRIPTION_SESSION_FAIL';

export const IS_ACCOUNT_PREMIUM_REQUEST = 'IS_ACCOUNT_PREMIUM_REQUEST';
export const IS_ACCOUNT_PREMIUM_SUCCESS = 'IS_ACCOUNT_PREMIUM_SUCCESS';
export const IS_ACCOUNT_PREMIUM_FAIL = 'IS_ACCOUNT_PREMIUM_FAIL';

export const PAYMENT_SUCCESS_REQUEST = 'PAYMENT_SUCCESS_REQUEST';
export const PAYMENT_SUCCESS_SUCCESS = 'PAYMENT_SUCCESS_SUCCESS';
export const PAYMENT_SUCCESS_FAIL = 'PAYMENT_SUCCESS_FAIL';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';

export const GET_SESSION_ID_REQUEST = 'GET_SESSION_ID_REQUEST';
export const GET_SESSION_ID_SUCCESS = 'GET_SESSION_ID_SUCCESS';
export const GET_SESSION_ID_FAIL = 'GET_SESSION_ID_FAIL';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const INITIATE_PASSWORD_RESET_REQUEST = 'INITIATE_PASSWORD_RESET_REQUEST';
export const INITIATE_PASSWORD_RESET_SUCCESS = 'INITIATE_PASSWORD_RESET_SUCCESS';
export const INITIATE_PASSWORD_RESET_FAIL = 'INITIATE_PASSWORD_RESET_FAIL';

export const FIND_USER_BY_IDENTIFIER_REQUEST = 'FIND_USER_BY_IDENTIFIER_REQUEST';
export const FIND_USER_BY_IDENTIFIER_SUCCESS = 'FIND_USER_BY_IDENTIFIER_SUCCESS';
export const FIND_USER_BY_IDENTIFIER_FAIL = 'FIND_USER_BY_IDENTIFIER_FAIL';