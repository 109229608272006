import React, { useState } from 'react';
import '../../../styles/login/loginStyle.css';
import { icons } from '../../../assets';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../redux/actions/UserActions';
import { strongPasswordPattern } from '../../../utils/regexUtils';
import SnackbarAlert from '../../../components/SnackbarAlert';

export const ResetPassword = () => {
  const { resetToken: token } = useParams()
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [severity, setSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = e => {
    e.preventDefault();

    if (!strongPasswordPattern.test(newPassword)) {
      setSeverity('error')
      setError('Password must be at least 8 characters, include uppercase, lowercase, and numbers.');
      setOpenSnackbar(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setSeverity('error')
      setError('Passwords do not match.');
      setOpenSnackbar(true);
      return;
    }

    dispatch(resetPassword(token, newPassword, navigate));
    setSeverity('success')
    setError(`You have successfully changed your password.`);
    setOpenSnackbar(true);
  };

  return (
    <div className="SignUp flex w-screen h-screen overflow-x-hidden">
      <div className="LeftSide w- flex-1 flex-col items-center justify-center bg-neutral-800 text-white md:flex hidden">
        <img className="mb-6" src={icons.logo} alt="Brand illustration" loading="lazy"/>
        <h2 className="BuySellEachDigi text-white text-3xl font-bold mb-4">
          Connect. Win. Repeat.
        </h2>
        <p className="EasilyBuyBitcoinA text-center w-[400px] text-white text-sm font-semibold">
          Easily check betting lines.
        </p>
      </div>

      <div className="Form flex flex-1 flex-col items-center justify-center bg-neutral-50 h-screen">
        <div className="w-2/4 pt-10">
          <h1 className="StartYourCryptoIn text-center text-neutral-900 text-2xl font-bold">
            Reset Password
          </h1>
        </div>
        <div className="Social mt-6 w-2/4 h-1/3">
          <form className="mb-4" onSubmit={submitHandler}>
            <div className="mb-6 md:w-full">
              <label
                htmlFor="password"
                className="w-36 h-6 text-zinc-500 text-xs font-medium leading-none"
              >
                PASSWORD
              </label>
              <input
                onChange={e => setNewPassword(e.target.value)}
                onInput={e => setNewPassword(e.target.value)}
                value={newPassword}
                className="w-full p-2 h-12 rounded-xl border border-lightGray focus:shadow-outline"
                type="password"
                name="password"
                id="password"
              />
            </div>
            <div className="mb-8 md:w-full">
              <label
                htmlFor="confirm-password"
                className="w-36 h-6 text-zinc-500 text-xs font-medium leading-none"
              >
                CONFIRM PASSWORD
              </label>
              <input
                onChange={e => setConfirmPassword(e.target.value)}
                onInput={e => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                className="w-full p-2 h-12 rounded-xl border border-lightGray focus:shadow-outline"
                type="password"
                name="confirm-password"
                id="confirm-password"
              />
            </div>
            <button
              type="submit"
              className="bg-primary rounded-xl mt-4 flex justify-center items-center h-[48px] p-5 text-sm font-semibold w-full"
            >
              SUBMIT
            </button>
          </form>
        </div>
      </div>
      <SnackbarAlert open={openSnackbar} message={error} onClose={() => setOpenSnackbar(false)} severity={severity} />
    </div>
  );
};
