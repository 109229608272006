import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_FAIL,
  USER_REGISTER_SUCCESS,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAIL,
  USER_UPDATE_INTERESTS_REQUEST,
  USER_UPDATE_INTERESTS_SUCCESS,
  USER_UPDATE_INTERESTS_FAIL,
  GET_SUGGESTED_USERS_REQUEST,
  GET_SUGGESTED_USERS_SUCCESS,
  GET_SUGGESTED_USERS_FAIL,
  SIGNUP_TRIAL_USER_REQUEST,
  SIGNUP_TRIAL_USER_SUCCESS,
  SIGNUP_TRIAL_USER_FAIL,
  IS_ACCOUNT_PREMIUM_REQUEST,
  IS_ACCOUNT_PREMIUM_SUCCESS,
  IS_ACCOUNT_PREMIUM_FAIL,
} from '../constants/UserConstants';

// LOGIN
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

// REGISTER
export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// USER DETAILS
export const userDetailsReducer = (state = { userInfo: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
    case USER_DETAILS_RESET:
      return { userInfo: {} };

    default:
      return state;
  }
};

// USER DETAILS
export const signupReducer = (state = { signup: {} }, action) => {
  switch (action.type) {
    case SIGNUP_TRIAL_USER_REQUEST:
      return { loading: true };
    case SIGNUP_TRIAL_USER_SUCCESS:
      return { loading: false, signup: action.payload };
    case SIGNUP_TRIAL_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// VERIFY
export const userVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VERIFY_REQUEST:
      return { loading: true };
    case USER_VERIFY_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_VERIFY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// UPDATE USER INTERESTS
export const userInterestsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_INTERESTS_REQUEST:
      return { ...state, loading: true };
    case USER_UPDATE_INTERESTS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_UPDATE_INTERESTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET SUGGESTED USERS
export const suggestedUsersReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case GET_SUGGESTED_USERS_REQUEST:
      return { loading: true };
    case GET_SUGGESTED_USERS_SUCCESS:
      return { loading: false, users: action.payload };
    case GET_SUGGESTED_USERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// UPDATE USER INTERESTS
export const isAccountPremiumReducer = (state = {}, action) => {
  switch (action.type) {
    case IS_ACCOUNT_PREMIUM_REQUEST:
      return { ...state, loading: true };
    case IS_ACCOUNT_PREMIUM_SUCCESS:
      return { loading: false, isPremium: action.payload.isPremium };
    case IS_ACCOUNT_PREMIUM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
