import React from 'react';
import {useNavigate} from 'react-router-dom';
import {icons} from '../assets';

export const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <button onClick={goBack} className="w-17 h-17 transform -rotate-180 mr-5">
      <img src={icons.chevronRight} alt="back button" loading="lazy"/>
    </button>
  );
};
