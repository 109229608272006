import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './styles/styles.css';
import './index.css';
import { LoginPage } from './pages/login/LoginPage';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import { SportsPage } from './pages/sports/SportsPage';
import { TeamView } from './pages/sports/components/TeamView';
import { LoginForm } from './pages/login/components/LoginForm';
import { RegisterPage } from './pages/register/RegisterPage';
import { CodeVerificationForm } from './pages/login/components/CodeVerificationForm';
import { FeedPage } from './pages/feed/FeedPage';
import { GameSchedulePage } from './pages/gameSchedule/GameSchedulePage';
import { PropBetsPage } from './pages/propBets/PropBetsPage';
import { EvChartPage } from './pages/evChart/EvChartPage';
import { PlayerInfoView } from './pages/sports/components/PlayerInfoView';
import { InboxPage } from './pages/inbox/InboxPage';
import { ProfilePage } from './pages/profile/ProfilePage';
import { SelectPropBetsPage } from './pages/propBets/components/SelectPropBets';
import { PaymentCancel } from './pages/payment/PaymentCancel';
import { PaymentSuccess } from './pages/payment/PaymentSuccess';
import { usePageViews } from './analytics/analytics';
import useDocumentTitle from './analytics/useDocumentTitle';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { ForgotPassword } from './pages/login/components/ForgotPassword';
import { ResetPassword } from './pages/login/components/ResetPassword';
import DarkModeToggle from './components/DarkModeToggle';
import { SportsbooksPage } from './pages/sportsbooks/SportsbooksPage';
import { EducationPage } from './pages/education/EducationPage';
import { OddsPage } from './pages/odds/OddsPage';
import { UFCMatchupDetailsPage } from './pages/ufc/UFCMatchupDetailsPage';
import { dark } from '@mui/material/styles/createPalette';
import { PgaOddsPage } from './pages/pga/PgaOddsPage';

const RoutesComponent = ({ darkMode }) => {
  const location = useLocation();
  useDocumentTitle(location);
  usePageViews(location);

  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
      <Route path="/verification" element={<CodeVerificationForm />} />
      <Route path="/" element={<DashboardPage darkMode={darkMode}/>} exact />
      <Route path="/dashboard" element={<DashboardPage darkMode={darkMode} />} />
      <Route path="/sports" element={<SportsPage darkMode={darkMode}/>} />
      <Route path="/sports/team/:id" element={<TeamView darkMode={darkMode}/>} />
      <Route path="/sports/playerInfo/:id" element={<PlayerInfoView darkMode={darkMode}/>} />
      <Route path="/feed" element={<FeedPage darkMode={darkMode}/>} />
      <Route path="/sports/gameSchedule/:id" element={<GameSchedulePage darkMode={darkMode}/>} />
      <Route path="/sports/propBets/:id" element={<PropBetsPage darkMode={darkMode}/>} />
      <Route path="/sports/selectPropBets" element={<SelectPropBetsPage darkMode={darkMode}/>} />
      <Route path="/sports/ufcMatchup/:id" element={<UFCMatchupDetailsPage darkMode={darkMode}/>} />
      <Route path="/sports/pgaOdds/:id" element={<PgaOddsPage darkMode={darkMode} />} />
      {/* <Route path="/sport/oddsTable/:id" element={<OddsTable/>} /> */}
      <Route path="/sports/evChart" element={<EvChartPage darkMode={darkMode}/>} />
      <Route path="/inbox" element={<InboxPage darkMode={darkMode}/>} />
      <Route path="/profile/:id" element={<ProfilePage darkMode={darkMode}/>} />
      <Route path="/cancel" element={<PaymentCancel />} />
      <Route path="/success" element={<PaymentSuccess />} />
      <Route path="/sportsbooks" element={<SportsbooksPage darkMode={darkMode}/>} />
      <Route path="/education" element={<EducationPage darkMode={darkMode}/>} />
      <Route path="/odds/" element={<OddsPage darkMode={darkMode} />} />
      <Route path="/odds/:gameId" element={<OddsPage darkMode={darkMode} />} />
    </Routes>
  );
};

const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark');
      document.body.classList.remove('light');
    } else {
      document.body.classList.add('light');
      document.body.classList.remove('dark');
    }
  }, [darkMode]);

  return (
    <SnackbarProvider>
      <Router>
        <div className={`w-full h-screen relative ${darkMode ? 'dark' : 'light'}`}>
          <RoutesComponent darkMode={darkMode} />
          <DarkModeToggle toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
        </div>
      </Router>
    </SnackbarProvider>
  );
};

export default App;