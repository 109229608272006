import React, {useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {register} from '../../redux/actions/UserActions';
import {RegisterForm} from './RegisterForm';
import { emailPattern, phonePattern } from '../../utils/regexUtils';

export const RegisterPage = () => {
  const [input, setInput] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector(state => state.userDetails);
  const {userInfo} = userDetails;

  const submitHandler = e => {
    e.preventDefault();

    // Determine if the input is an email or a phone number directly
    const isEmail = emailPattern.test(input);
    const isPhone = phonePattern.test(phone);

    if (!isEmail && !isPhone) {
      console.error('Invalid Email');
      return;
    }
    if (password) {
      dispatch(
        register(
          name,
          isEmail ? input : null,
          password,
          isPhone ? phone : null,
          navigate,
        ),
      );
    } else {
      console.log('Password is missing');
    }
  };

  if (userInfo?.isVerified) {
    return <Navigate to="/verification" />;
  }

  return (
    <RegisterForm
      name={name}
      setName={setName}
      input={input}
      setInput={setInput}
      password={password}
      setPassword={setPassword}
      submitHandler={submitHandler}
      phone={phone}
      setPhone={setPhone}
    />
  );
};
