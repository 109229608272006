export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE';

export const GET_TEAM_REQUEST = 'GET_TEAM_REQUEST';
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAILURE = 'GET_TEAM_FAILURE';

export const GET_ODDS_REQUEST = 'GET_ODDS_REQUEST';
export const GET_ODDS_SUCCESS = 'GET_ODDS_SUCCESS';
export const GET_ODDS_FAILURE = 'GET_ODDS_FAILURE';

export const GET_GAME_ODDS_REQUEST = 'GET_GAME_ODDS_REQUEST';
export const GET_GAME_ODDS_SUCCESS = 'GET_GAME_ODDS_SUCCESS';
export const GET_GAME_ODDS_FAILURE = 'GET_GAME_ODDS_FAILURE';

export const GET_TEAM_STATS_REQUEST = 'GET_TEAM_STATS_REQUEST';
export const GET_TEAM_STATS_SUCCESS = 'GET_TEAM_STATS_SUCCESS';
export const GET_TEAM_STATS_FAILURE = 'GET_TEAM_STATS_FAILURE';

export const GET_TEAM_PLAYERS_REQUEST = 'GET_TEAM_PLAYERS_REQUEST';
export const GET_TEAM_PLAYERS_SUCCESS = 'GET_TEAM_PLAYERS_SUCCESS';
export const GET_TEAM_PLAYERS_FAILURE = 'GET_TEAM_PLAYERS_FAILURE';

export const GET_PLAYER_REQUEST = 'GET_PLAYER_REQUEST';
export const GET_PLAYER_SUCCESS = 'GET_PLAYER_SUCCESS';
export const UPDATE_PLAYER_RESULT='UPDATE_PLAYER_RESULT';
export const GET_PLAYER_FAILURE = 'GET_PLAYER_FAILURE';

export const GET_TEAM_SCHEDULE_REQUEST = 'GET_TEAM_SCHEDULE_REQUEST';
export const GET_TEAM_SCHEDULE_SUCCESS = 'GET_TEAM_SCHEDULE_SUCCESS';
export const GET_TEAM_SCHEDULE_FAILURE = 'GET_TEAM_SCHEDULE_FAILURE';

export const GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_FAILURE = 'GET_SCHEDULE_FAILURE';

export const GET_PROP_BETS_REQUEST = 'GET_PROP_BETS_REQUEST';
export const GET_PROP_BETS_SUCCESS = 'GET_PROP_BETS_SUCCESS';
export const GET_PROP_BETS_UPDATE_AWAY_TEAM_PLAYER = 'GET_PROP_BETS_UPDATE_AWAY_TEAM_PLAYER';
export const GET_PROP_BETS_UPDATE_HOME_TEAM_PLAYER = 'GET_PROP_BETS_UPDATE_HOME_TEAM_PLAYER';
export const GET_PROP_BETS_FAILURE = 'GET_PROP_BETS_FAILURE';
export const RESET_PROP_BETS_STATE = 'RESET_PROP_BETS_STATE';

export const GET_PROP_BETS_PLAYERS_REQUEST = 'GET_PROP_BETS_PLAYERS_REQUEST';
export const GET_PROP_BETS_PLAYERS_SUCCESS = 'GET_PROP_BETS_PLAYERS_SUCCESS';
export const GET_PROP_BETS_PLAYERS_FAILURE = 'GET_PROP_BETS_PLAYERS_FAILURE';

export const GET_LEAGUES_REQUEST = 'GET_LEAGUES_REQUEST';
export const GET_LEAGUES_SUCCESS = 'GET_LEAGUES_SUCCESS';
export const GET_LEAGUES_FAILURE = 'GET_LEAGUES_FAILURE';

export const GET_PLAYER_ODDS_REQUEST = 'GET_PLAYER_ODDS_REQUEST';
export const GET_PLAYER_ODDS_SUCCESS = 'GET_PLAYER_ODDS_SUCCESS';
export const GET_PLAYER_ODDS_FAILURE = 'GET_PLAYER_ODDS_FAILURE';

export const GET_LIVE_GAME_SCORES_REQUEST = 'GET_LIVE_GAME_SCORES_REQUEST';
export const GET_LIVE_GAME_SCORES_SUCCESS = 'GET_LIVE_GAME_SCORES_SUCCESS';
export const GET_LIVE_GAME_SCORES_FAILURE = 'GET_LIVE_GAME_SCORES_FAILURE';

export const GET_LIVE_GAME_ODDS_REQUEST = 'GET_LIVE_GAME_ODDS_REQUEST';
export const GET_LIVE_GAME_ODDS_SUCCESS = 'GET_LIVE_GAME_ODDS_SUCCESS';
export const GET_LIVE_GAME_ODDS_FAILURE = 'GET_LIVE_GAME_ODDS_FAILURE';
export const RESET_LIVE_GAME_SCORES_STATE = 'RESET_LIVE_GAME_SCORES_STATE';

export const GET_UPCOMING_MATCHUPS_REQUEST = 'GET_UPCOMING_MATCHUPS_REQUEST';
export const GET_UPCOMING_MATCHUPS_SUCCESS = 'GET_UPCOMING_MATCHUPS_SUCCESS';
export const GET_UPCOMING_MATCHUPS_FAILURE = 'GET_UPCOMING_MATCHUPS_FAILURE';

export const GET_RANDOM_UPCOMING_MATCHUPS_REQUEST = 'GET_RANDOM_UPCOMING_MATCHUPS_REQUEST';
export const GET_RANDOM_UPCOMING_MATCHUPS_SUCCESS = 'GET_RANDOM_UPCOMING_MATCHUPS_SUCCESS';
export const GET_RANDOM_UPCOMING_MATCHUPS_FAILURE = 'GET_RANDOM_UPCOMING_MATCHUPS_FAILURE';

export const SEARCH_SPORTS_REQUEST = 'SEARCH_SPORTS_REQUEST';
export const SEARCH_SPORTS_SUCCESS = 'SEARCH_SPORTS_SUCCESS';
export const SEARCH_SPORTS_FAILURE = 'SEARCH_SPORTS_FAILURE';

export const GET_LEAGUE_ODDS_REQUEST = 'GET_LEAGUE_ODDS_REQUEST';
export const GET_LEAGUE_ODDS_SUCCESS = 'GET_LEAGUE_ODDS_SUCCESS';
export const GET_LEAGUE_ODDS_FAILURE = 'GET_LEAGUE_ODDS_FAILURE';

export const GET_UFC_FIGHTER_DETAILS_REQUEST = 'GET_UFC_FIGHTER_DETAILS_REQUEST';
export const GET_UFC_FIGHTER_DETAILS_SUCCESS = 'GET_UFC_FIGHTER_DETAILS_SUCCESS';
export const GET_UFC_FIGHTER_DETAILS_FAILURE = 'GET_UFC_FIGHTER_DETAILS_FAILURE';