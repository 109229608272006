import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

let startTime = null;

// Function to normalize paths
const normalizePath = (path) => {
  const newPath = path.replace(/\/\d+/, '/:id');
  return newPath;
};

export const usePageViews = (location) => {
  useEffect(() => {
    const endTime = new Date();
    if (startTime) {
      const timeInSeconds = Math.ceil((endTime - startTime) / 1000);
      ReactGA.event({
        category: "Engagement",
        action: "Average Time",
        value: timeInSeconds,
      });
    }
    startTime = new Date(); // Reset startTime when page is viewed
    const pagePath = normalizePath(location.pathname);
    const pageTitle = document.title;

    ReactGA.send({
      hitType: 'pageview',
      page_title: pageTitle,
      page_location: window.location.href,
      page_path: pagePath
    });
  }, [location]);
};
