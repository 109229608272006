import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import DraftKingsLight from '../../assets/icons/draftkingsLight.png';
import DraftKingsDark from '../../assets/icons/draftkings.png';
import FanDuelLight from '../../assets/icons/fanduelLight.png';
import FanDuelDark from '../../assets/icons/fanduel.png';
import ESPNbet from '../../assets/icons/espn.png';
import TipicoLogo from '../../assets/icons/tipico.png';
import BetMGMLight from '../../assets/icons/betMGMLight.png';
import BetMGM from '../../assets/icons/betMGM.png';
import Bet365Logo from '../../assets/icons/bet3605.png';

const sportsbooks = [
  { 
    name: 'DraftKings', 
    rating: '9.2', 
    description: "DraftKings Sportsbook is your gateway to an exceptional sports betting experience. Whether you prefer our top-rated mobile app, desktop interface, or the thrill of our retail locations, DraftKings invites you to immerse yourself in the pinnacle of American sports betting. Explore a comprehensive range of sports wagers tailored to your preferences and savor every victory like a decadent dessert. With DraftKings, you can count on cutting-edge technology and unparalleled customer service to enhance your betting journey. Don't miss out—click here to discover DraftKings Sportsbook and elevate your game today.", 
    url: 'https://sportsbook.draftkings.com/',
    logoLight: DraftKingsLight,
    logoDark: DraftKingsDark
  },
  { 
    name: 'FanDuel', 
    rating: '9.0', 
    description: 'Experience the ultimate in sports entertainment with FanDuel Sportsbook, where every day is a chance to win big. As a leading sports-tech entertainment company, FanDuel revolutionizes how fans engage with their favorite sports, leagues, and teams. FanDuel prioritizes responsible gaming with innovative tools that empower users to set limits on time, deposits, and wagers, ensuring a safe and enjoyable experience. Committed to diversity, equity, and inclusion, FanDuel builds inclusive teams and supports communities nationwide. Moreover, FanDuel is dedicated to reducing its environmental footprint through sustainable practices. Click here to explore FanDuel Sportsbook and elevate your sports betting journey with interactive gameplay and responsible gaming initiatives.', 
    url: 'https://sportsbook.fanduel.com/',
    logoLight: FanDuelLight,
    logoDark: FanDuelDark
  },
  { 
    name: 'ESPN Bet', 
    rating: '8.8', 
    description: "Experience the thrill of ESPN Bet, where sports excitement meets top-tier online betting technology. Packed with exclusive odds boosts, custom offers, and a suite of cutting-edge features, ESPN Bet delivers the ultimate online sports wagering experience. Enjoy lightning-fast load times, secure transactions, and seamless navigation that ensure every bet is placed with ease and confidence. Whether you're a seasoned bettor or new to sports wagering, ESPN Bet is your go-to destination for unparalleled sportsbook action. Click here to explore ESPN Bet and elevate your game day experience to the next level.", 
    url: 'https://about.espnbet.com/',
    logoLight: ESPNbet, 
    logoDark: ESPNbet
  },
  { 
    name: 'Tipico', 
    rating: '8.5', 
    description: "Experience the thrill of sports betting with Tipico, a trusted name that brings passion and innovation to every game. Originally founded in Europe in 2004, Tipico has become a premier sports betting provider globally and is now available as a licensed, legal U.S. Sportsbook in states like New Jersey, Iowa, Ohio, and Colorado. Renowned for its commitment to player safety and responsible gaming, Tipico offers a seamless digital and mobile betting experience across 30 sports categories. Whether you're a seasoned bettor or new to sports gaming, Tipico's dedication to technology and customer satisfaction ensures an enjoyable and secure betting environment. Click here to explore Tipico Sportsbook and discover why sports fans around the world choose Tipico for their betting excitement.", 
    url: 'https://www.tipico.com/us',
    logoLight: TipicoLogo,
    logoDark: TipicoLogo
  },
  { 
    name: 'BetMGM', 
    rating: '8.9', 
    description: 'Discover the unparalleled world of BetMGM Sportsbook, where legendary entertainment meets cutting-edge technology. As a partnership between MGM Resorts International and Entain Holdings, BetMGM leads the charge in revolutionizing sports betting and online gaming across the United States. From exclusive online betting experiences to prominent presence in MGM casinos nationwide, BetMGM offers not just sports betting but also premier online casino gaming through brands like Borgata Online, Party Casino, and Party Poker. With a commitment to enhancing sports and gaming through advanced digital technology and immersive player experiences, BetMGM invites you to play to win and embrace the excitement of every game. Click here to explore BetMGM Sportsbook and elevate your betting experience today.', 
    url: 'https://sports.betmgm.com/en/sports',
    logoLight: BetMGMLight,
    logoDark: BetMGM
  },
  { 
    name: 'Bet365', 
    rating: '9.1', 
    description: "Discover the excellence of bet365 Sportsbook, a global leader in online sports betting. With a steadfast vision and mission to become the world's favourite online sports betting brand, bet365 offers unparalleled integrity and a commitment to safer gambling practices. While predominantly recognized in Europe, bet365 is rapidly expanding its footprint across the United States, currently available in states like Arizona, New Jersey, Colorado, and more, with plans for further expansion. Renowned for its fair pricing and dependable odds, bet365 ensures an exceptional betting experience. Click here to explore bet365 Sportsbook and elevate your sports betting journey with a trusted industry leader.", 
    url: 'https://www.bet365.com/',
    logoLight: Bet365Logo,
    logoDark: Bet365Logo
  }
];

export const SportsbooksView = ({ darkMode }) => {
  return (
    <div className={`w-full h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-[#ECECF4] text-gray-900'} flex flex-row overflow-hidden`}>
      <Sidebar />
      <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
        <div className={`sticky top-0 z-10 ${darkMode ? 'bg-black' : 'bg-white'}`}>
          <Navbar title="Sportsbooks" darkMode={darkMode}/>
        </div>
        <div className="p-6">
          <h2 className="text-xl font-bold mb-4">Top Rated Sportsbooks</h2>
          {sportsbooks.map((sportsbook, index) => (
            <div key={index} className={`rounded-lg shadow-md p-4 mb-4 flex ${darkMode ? 'bg-black' : 'bg-white'}`}>
              <div className="flex-shrink-0 mr-4">
                <img 
                  src={darkMode ? sportsbook.logoDark : sportsbook.logoLight} 
                  alt={`${sportsbook.name} Logo`} 
                  className="w-16 h-16 object-contain"
                />
              </div>
              <div className="flex-grow">
                <div className="flex items-center justify-between mb-2">
                  <div className={`font-bold text-xl ${darkMode ? 'text-primary' : 'text-black'}`}>{sportsbook.name}</div>
                  <a 
                    href={sportsbook.url} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    //go to sportsbooks links
                    className={`text-blue-600 hover:underline ${darkMode ? 'text-blue-600' : 'text-blue-600'}`}
                  >
                    Go To {sportsbook.name}
                  </a>
                </div>
                <p className={`text-gray-700 ${darkMode ? 'text-white' : 'text-gray-700'}`}>{sportsbook.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
