import React from 'react';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import '../../styles/PGA/pga.css';

export const PgaOddsView = ({ data }) => {
  const matchup = data[0];
  const { home_team: homePlayer, odds, start_date } = matchup;

  // Group the odds by player name
  const groupedOdds = odds.reduce((acc, odd) => {
    const playerName = odd.name.split(' ').slice(0, 2).join(' ');
    if (!acc[playerName]) {
      acc[playerName] = { over: null, under: null };
    }
    if (odd.name.includes('Over')) {
      acc[playerName].over = odd;
    } else if (odd.name.includes('Under')) {
      acc[playerName].under = odd;
    }
    return acc;
  }, {});

  return (
    <div className="w-full h-screen bg-neutral-50 flex flex-row overflow-hidden">
      <Sidebar />
      <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
        <div className="sticky top-0 z-20 bg-white">
          <Navbar />
        </div>
        <div className="p-4">
          <p className="text-slate-500 mb-3">{homePlayer}</p>
          <div className="w-full rounded-lg overflow-hidden h-full">
            {/* Header */}
            <div className="grid grid-cols-10 bg-black text-white text-xs sm:text-sm lg:text-base font-semibold py-3">
              <div className="col-span-2 text-center">Date</div>
              <div className="col-span-2 text-center">Golfer</div>
              <div className="col-span-3 text-center">Strokes Over</div>
              <div className="col-span-3 text-center">Strokes Under</div>
            </div>
            {/* Data */}
            <div className="overflow-y-scroll max-h-full">
              {Object.keys(groupedOdds).length > 0 ? (
                Object.keys(groupedOdds).map((playerName, index) => {
                  const playerDetails = groupedOdds[playerName].over || groupedOdds[playerName].under;
                  return (
                    <div key={index} className="grid grid-cols-10 text-xs sm:text-sm lg:text-base py-3 border-b cursor-pointer">
                      <div className="col-span-2 text-center">{new Date(start_date).toLocaleDateString()}</div>
                      <div className="col-span-2 text-center">{playerName}</div>
                      <div className="col-span-3 text-center">
                        {groupedOdds[playerName].over ? (
                          <div className="flex justify-center items-center space-x-3">
                            <span className="text-black text-xs sm:text-sm lg:text-base font-normal">
                              O {groupedOdds[playerName].over.name.split(' ').slice(-1)}
                            </span>
                            <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                              {groupedOdds[playerName].over.price}
                            </span>
                          </div>
                        ) : (
                          <div>N/A</div>
                        )}
                      </div>
                      <div className="col-span-3 text-center">
                        {groupedOdds[playerName].under ? (
                          <div className="flex justify-center items-center space-x-3">
                            <span className="text-black text-xs sm:text-sm lg:text-base font-normal">
                              U {groupedOdds[playerName].under.name.split(' ').slice(-1)}
                            </span>
                            <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                              {groupedOdds[playerName].under.price}
                            </span>
                          </div>
                        ) : (
                          <div>N/A</div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="block text-center">N/A</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};