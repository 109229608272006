import React from 'react';
import {sportsData} from '../../../utils/cardsMappingData.js';

const PropBetsQuickPicks = ({onSportSelect}) => {
  return (
    <div className="w-full p-4">
      <div className="text-indigo-950 text-xl font-semibold mb-2">
        Quick Picks
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {sportsData.map((sport, index) => (
          <button
            onClick={() => onSportSelect(sport.sport, sport.league)}
            key={index}
            className="flex flex-col items-center bg-white rounded-xl border border-zinc-400 text-center p-2"
          >
            <img
              className="w-auto h-12"
              src={sport.imageUrl}
              alt={sport.title}
              loading="lazy"
            />
            <span className="mt-2 text-zinc-800 text-xs font-normal leading-tight">
              {sport.title}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default PropBetsQuickPicks;
