// // before attempting to fetch for basic ufc fighter stats
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGameOdds} from '../../redux/actions/SportsActions';
import { UFCMatchupView } from './UFCMatchupView';
import { Loading } from '../../components/LoadingComponent';
import { dark } from '@mui/material/styles/createPalette';

export const UFCMatchupDetailsPage = ( {darkMode} ) => {
  const { id: matchupId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [fighters, setFighters] = useState([]);

  const oddsData = useSelector(state => state.getGameOdds);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getGameOdds(matchupId));
      setLoading(false);
    };

    fetchData();
  }, [dispatch, matchupId]);

  if (loading || oddsData.loading) {
    return (
      <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
        <Loading />
      </div>
    );
  }

  if (!oddsData.odds || oddsData.odds.length === 0) {
    return (
      <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
        <p>No data available</p>
      </div>
    );
  }

  return (
    <div className="w-full h-screen flex flex-col overflow-hidden">
      <UFCMatchupView data={oddsData.odds} fighters={fighters} darkMode={darkMode}/>
    </div>
  );
};



// I think I was finally able to fetch the fighter ID's
// import { useParams } from 'react-router-dom';
// import { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getGameOdds, getPropBetsPlayers } from '../../redux/actions/SportsActions';
// import { UFCMatchupView } from './UFCMatchupView';
// import { Loading } from '../../components/LoadingComponent';

// export const UFCMatchupDetailsPage = () => {
//   const { id: matchupId } = useParams();
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(true);
//   const [homeFighterData, setHomeFighterData] = useState([]);
//   const [awayFighterData, setAwayFighterData] = useState([]);

//   const oddsData = useSelector(state => state.getGameOdds);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const gameOddsResponse = await dispatch(getGameOdds(matchupId));
//         console.log('Fetched Game Odds:', gameOddsResponse);

//         const oddsResponse = gameOddsResponse.payload || gameOddsResponse;
//         console.log('Processed Odds Response:', oddsResponse);

//         if (oddsResponse && oddsResponse.length > 0) {
//           const matchup = oddsResponse[0];
//           console.log('Matchup Object:', matchup);
//           const homeFighterTeamName = matchup.home_team;
//           const awayFighterTeamName = matchup.away_team;

//           console.log('Home Fighter Team Name:', homeFighterTeamName);
//           console.log('Away Fighter Team Name:', awayFighterTeamName);

//           // Extract player IDs from odds
//           const homeFighterIds = matchup.odds.filter(odd => odd.selection === homeFighterTeamName).map(odd => odd.player_id).filter(Boolean);
//           const awayFighterIds = matchup.odds.filter(odd => odd.selection === awayFighterTeamName).map(odd => odd.player_id).filter(Boolean);

//           console.log('Home Fighter IDs:', homeFighterIds);
//           console.log('Away Fighter IDs:', awayFighterIds);

//           // Fetch player data using extracted IDs
//           const homeFighterDataResponse = await dispatch(getPropBetsPlayers(homeFighterTeamName, homeFighterIds));
//           const awayFighterDataResponse = await dispatch(getPropBetsPlayers(awayFighterTeamName, awayFighterIds));

//           setHomeFighterData(homeFighterDataResponse.payload.players);
//           setAwayFighterData(awayFighterDataResponse.payload.players);
//         } else {
//           console.warn('No odds data available');
//         }
//       } catch (error) {
//         console.error('Error fetching game odds or team players:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [dispatch, matchupId]);

//   if (loading || oddsData.loading) {
//     return (
//       <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
//         <Loading />
//       </div>
//     );
//   }

//   if (!oddsData.payload || oddsData.payload.length === 0) {
//     return (
//       <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
//         <p>No data available</p>
//       </div>
//     );
//   }

//   return (
//     <div className="w-full h-screen flex flex-col overflow-hidden">
//       <UFCMatchupView 
//         data={oddsData.payload} 
//         homeFighterData={homeFighterData} 
//         awayFighterData={awayFighterData} 
//       />
//     </div>
//   );
// };


// pulled some ids, seem to be team ids but can't run getPlayer on them
// import { useParams } from 'react-router-dom';
// import { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getGameOdds, getTeamPlayers, getPlayer } from '../../redux/actions/SportsActions';
// import { UFCMatchupView } from './UFCMatchupView';
// import { Loading } from '../../components/LoadingComponent';

// export const UFCMatchupDetailsPage = () => {
//   const { id: matchupId } = useParams();
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(true);
//   const [homeFighterData, setHomeFighterData] = useState(null);
//   const [awayFighterData, setAwayFighterData] = useState(null);

//   const oddsData = useSelector(state => state.getGameOdds);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const gameOddsResponse = await dispatch(getGameOdds(matchupId));
//         console.log('Fetched Game Odds:', gameOddsResponse);

//         const oddsResponse = gameOddsResponse.payload || gameOddsResponse;
//         console.log('Processed Odds Response:', oddsResponse);

//         if (oddsResponse && oddsResponse.length > 0) {
//           const matchup = oddsResponse[0];
//           console.log('Matchup Object:', matchup);
//           const homeFighterTeamName = matchup.home_team;
//           const awayFighterTeamName = matchup.away_team;

//           console.log('Home Fighter Team Name:', homeFighterTeamName);
//           console.log('Away Fighter Team Name:', awayFighterTeamName);

//           // Fetch team players
//           const homeTeamPlayersResponse = await dispatch(getTeamPlayers('mma', 'UFC', homeFighterTeamName));
//           const awayTeamPlayersResponse = await dispatch(getTeamPlayers('mma', 'UFC', awayFighterTeamName));

//           const homeTeamPlayers = homeTeamPlayersResponse.payload || homeTeamPlayersResponse;
//           const awayTeamPlayers = awayTeamPlayersResponse.payload || awayTeamPlayersResponse;

//           console.log('Home Team Players:', homeTeamPlayers);
//           console.log('Away Team Players:', awayTeamPlayers);

//           // Extract and de-duplicate player IDs from odds
//           const homeFighterIds = [...new Set(matchup.odds.filter(odd => odd.selection === homeFighterTeamName).map(odd => odd.player_id).filter(Boolean))];
//           const awayFighterIds = [...new Set(matchup.odds.filter(odd => odd.selection === awayFighterTeamName).map(odd => odd.player_id).filter(Boolean))];

//           console.log('Home Fighter IDs:', homeFighterIds);
//           console.log('Away Fighter IDs:', awayFighterIds);

//           // Find the correct player IDs from team players
//           const homeFighterId = homeTeamPlayers.find(player => homeFighterIds.includes(player.id))?.id;
//           const awayFighterId = awayTeamPlayers.find(player => awayFighterIds.includes(player.id))?.id;

//           console.log('Matched Home Fighter ID:', homeFighterId);
//           console.log('Matched Away Fighter ID:', awayFighterId);

//           if (homeFighterId) {
//             const homeFighterStatsResponse = await dispatch(getPlayer(homeFighterId, false));
//             setHomeFighterData(homeFighterStatsResponse.payload);
//           }

//           if (awayFighterId) {
//             const awayFighterStatsResponse = await dispatch(getPlayer(awayFighterId, false));
//             setAwayFighterData(awayFighterStatsResponse.payload);
//           }
//         } else {
//           console.warn('No odds data available');
//         }
//       } catch (error) {
//         console.error('Error fetching game odds or team players:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [dispatch, matchupId]);

//   if (loading || oddsData.loading) {
//     return (
//       <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
//         <Loading />
//       </div>
//     );
//   }

//   if (!oddsData.payload || oddsData.payload.length === 0 || !homeFighterData || !awayFighterData) {
//     console.log('No data available:', { oddsData, homeFighterData, awayFighterData });
//     return (
//       <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
//         <p>No data available</p>
//       </div>
//     );
//   }

//   return (
//     <div className="w-full h-screen flex flex-col overflow-hidden">
//       <UFCMatchupView 
//         data={oddsData.payload} 
//         homeFighterData={homeFighterData} 
//         awayFighterData={awayFighterData} 
//       />
//     </div>
//   );
// };



// import { useParams } from 'react-router-dom';
// import { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getGameOdds, getUfcFighterDetails } from '../../redux/actions/SportsActions';
// import { UFCMatchupView } from './UFCMatchupView';
// import { Loading } from '../../components/LoadingComponent';

// export const UFCMatchupDetailsPage = () => {
//   const { id: matchupId } = useParams();
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(true);

//   const oddsData = useSelector(state => state.getGameOdds);
//   const ufcFighterDetails = useSelector(state => state.ufcFighterDetails);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       await dispatch(getGameOdds(matchupId));
//       setLoading(false);
//     };

//     fetchData();
//   }, [dispatch, matchupId]);

//   useEffect(() => {
//     const fetchFighterDetails = async () => {
//       const odds = oddsData?.odds || [];
//       console.log('Fetched Odds:', odds);
//       if (odds.length > 0) {
//         const homeFighterId = odds[0].home_team_id;
//         const awayFighterId = odds[0].away_team_id;

//         if (homeFighterId) {
//           await dispatch(getUfcFighterDetails(homeFighterId));
//         }
//         if (awayFighterId) {
//           await dispatch(getUfcFighterDetails(awayFighterId));
//         }
//       }
//     };

//     if (oddsData.odds && oddsData.odds.length > 0) {
//       fetchFighterDetails();
//     }
//   }, [dispatch, oddsData.odds]);

//   useEffect(() => {
//     console.log('UFC Fighter Details:', ufcFighterDetails);
//   }, [ufcFighterDetails]);

//   if (loading || oddsData.loading || ufcFighterDetails.loading) {
//     return (
//       <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
//         <Loading />
//       </div>
//     );
//   }

//   if (!oddsData.odds || oddsData.odds.length === 0) {
//     return (
//       <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
//         <p>No data available</p>
//       </div>
//     );
//   }

//   const fighters = Array.isArray(ufcFighterDetails.fighter) ? ufcFighterDetails.fighter : [];

//   return (
//     <div className="w-full h-screen flex flex-col overflow-hidden">
//       <UFCMatchupView data={oddsData.odds} fighters={fighters} />
//     </div>
//   );
// };




