export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAIL = 'CREATE_POST_FAIL';

export const GET_POST_REQUEST = 'GET_POST_REQUEST';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_FAIL = 'GET_POST_FAIL';

export const GET_USER_POSTS_REQUEST = 'GET_USER_POSTS_REQUEST';
export const GET_USER_POSTS_SUCCESS = 'GET_USER_POSTS_SUCCESS';
export const GET_USER_POSTS_FAIL = 'GET_USER_POSTS_FAIL';

export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAIL = 'DELETE_POST_FAIL';

export const GET_FEED_POSTS_FAIL = 'GET_FEED_POSTS_FAIL';
export const GET_FEED_POSTS_REQUEST = 'GET_FEED_POSTS_REQUEST';
export const GET_FEED_POSTS_SUCCESS = 'GET_FEED_POSTS_SUCCESS';
