import {useLocation} from 'react-router-dom';
import {InboxView} from './InboxView';

export const InboxPage = ( {darkMode} ) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get('userId');

  const user = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

  return <InboxView userId={userId} myId={user?.id} user={user}  darkMode={darkMode} />;
};
