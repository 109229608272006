// Regular expression for email validation
export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Regular expression for phone number validation
export const phonePattern = /^\d{10,15}$/;

// Regular expression for strong password validation
// - At least 8 characters in length
// - Contains at least one digit
// - Contains at least one lowercase character
// - Contains at least one uppercase character
export const strongPasswordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;