import {useLocation, useParams} from 'react-router-dom';
import {ProfileView} from './ProfileView';
import {getUserDetails} from '../../redux/actions/UserActions';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {
  amIFollowing,
  followTarget,
  getTargetFollowers,
  unfollowTarget,
} from '../../redux/actions/FollowsActions';
import {Loading} from '../../components/LoadingComponent';
import {getUserPosts} from '../../redux/actions/PostActions';

export const ProfilePage = () => {
  const {id: userId} = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const userDetails = useSelector(
    state => state.userDetails.userInfo || state.userLogin.userInfo,
  );
  const followers = useSelector(state => state.getTargetFollowers);
  const postList = useSelector(state => state.getUserPosts.posts);

  const [isFollowing, setIsFollowing] = useState(false);
  const [totalLikes, setTotalLikes] = useState(0);

  const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

  //useEffect(() => {
  //  dispatch(getUserDetails(userInfoFromStorage.id));
  //}, []);

  useEffect(() => {
    dispatch(getUserDetails(userId));
    dispatch(getTargetFollowers(userId));
  }, [userId, dispatch, isFollowing]);

  useEffect(() => {
    if (userDetails?.id !== userInfoFromStorage?.id) {
      dispatch(amIFollowing(userInfoFromStorage?.id, userDetails?.id)).then(
        response => {
          setIsFollowing(response.amIFollowing);
        },
      );
    }
  }, [userDetails?.id, userInfoFromStorage?.id, dispatch, isFollowing]);

  useEffect(() => {
    if (userDetails.id) {
      dispatch(getUserPosts(userDetails?.id));
    }
  }, [dispatch, userDetails?.id]);

  useEffect(() => {
    if (postList && postList.length > 0) {
      const likesSum = postList.reduce((acc, post) => acc + post.likes, 0);
      setTotalLikes(likesSum);
    } else {
      setTotalLikes(0); // Reset to 0 if there are no posts
    }
  }, [postList]);

  const handleFollow = async () => {
    try {
      await dispatch(followTarget(userInfoFromStorage?.id, userDetails?.id));
      setIsFollowing(true);
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  const handleUnfollow = async () => {
    try {
      await dispatch(unfollowTarget(userInfoFromStorage?.id, userDetails?.id));
      setIsFollowing(false);
    } catch (error) {
      console.error('Error unfollowing user:', error);
    }
  };

  return userDetails ? (
    <ProfileView
      userDetails={userDetails}
      followers={followers}
      myId={userInfoFromStorage.id}
      isFollowing={isFollowing}
      follow={handleFollow}
      unfollow={handleUnfollow}
      postList={postList}
      totalLikes={totalLikes}
    />
  ) : (
    <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
      <Loading />
    </div>
  );
};
