// import React, { useState } from 'react';
// import "../styles/Dashboard/dashboardStyle.css"
// import { useDispatch } from 'react-redux';
// import { icons } from "../assets";
// import { createCheckoutSession } from '../redux/actions/UserActions';
// import { useNavigate } from 'react-router-dom';

// export const TryFreeButton = () => {
//   const [isPremiumModalVisible, setPremiumModalVisible] = useState(false);

//   const handleOpenModal = () => {
//     setPremiumModalVisible(true);
//   };

//   const handleCloseModal = () => {
//     setPremiumModalVisible(false);
//   };

//   return (
//     <div>
//       <button

//         className="sticky bg-primary text-black rounded-full p-4 flex items-center space-x-2 mx-10"
//         onClick={handleOpenModal}
//       >
//         <span className="text-xl font-bold">Try For Free</span>
//       </button>
//       <TryFreeModal isVisible={isPremiumModalVisible} onClose={handleCloseModal} />
//     </div>


//   );
// };

// export const TryFreeModal = ({ isVisible, onClose }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const handlePlanSelection = async (planType) => {
//     try {
//       const sessionResponse = await dispatch(createCheckoutSession(planType, navigate));
//       if (sessionResponse?.session?.url) {
//         window.location.href = sessionResponse.session.url; // Redirect to the external URL
//       } else {
//         console.error('No URL found in the session response');
//       }
//     } catch (error) {
//       console.error('Error while creating checkout session:', error);
//     }
//   };

//   return (
//     isVisible && (
//       <div
//         className="fixed inset-0 flex items-center justify-center z-50"
//         onClick={onClose}
//       >
//         <div
//           className="relative bg-white rounded-xl shadow-lg z-50 w-full max-w-3xl mx-4 sm:mx-6 md:mx-8 overflow-y-auto max-h-[90vh]"
//           onClick={e => e.stopPropagation()}
//         >
//           <div className="flex flex-col w-full p-5 h-full justify-center items-center">
//             <h1 className="opacity-95 text-neutral-900 text-3xl font-bold mb-2 text-center">The Right Plan for You</h1>
//             <p className="opacity-70 text-center text-zinc-500 text-base font-normal mb-5">Connect. Win. Repeat.</p>

//             <div className="flex flex-col sm:flex-row gap-4 mt-4 w-full">
//               <div className="bg-neutral-100 rounded-3xl flex flex-col w-full sm:w-1/2 p-6 justify-between">
//                 <h3 className="text-neutral-900 text-2xl font-bold mb-4">Sports Monthly</h3>
//                 <div className="space-y-4 mb-4">
//                   <div className="flex items-center space-x-2">
//                     <img src={icons.checkMark} alt='img' className="w-2.5 h-2" />
//                     <p className="text-neutral-900 text-base font-normal">In-depth players analytics for NBA, MLB, NCAAB, NCAAF, NHL, NFL</p>
//                   </div>
//                   <div className="flex items-center space-x-2">
//                     <img src={icons.checkMark} alt='img' className="w-2.5 h-2" />
//                     <p className="text-neutral-900 text-base font-normal">Access to social feed where you can chat with other users and share insights</p>
//                   </div>
//                   <div className="flex items-center space-x-2">
//                     <img src={icons.checkMark} alt='img' className="w-2.5 h-2" />
//                     <p className="text-neutral-900 text-base font-normal">First to access new exciting features to come</p>
//                   </div>
//                   <div className="flex items-center space-x-2">
//                     <img src={icons.checkMark} alt='img' className="w-2.5 h-2" />
//                     <p className="text-neutral-900 text-base font-normal">7 day free trial</p>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-1 mb-3">
//                   <span className="text-neutral-900 text-base font-normal">$</span>
//                   <span className="text-neutral-900 text-2xl font-bold">9.99</span>
//                   <p className="text-neutral-900 text-base font-normal">/month</p>
//                 </div>
//                 <div className="mt-auto">
//                   <button
//                     className="w-full h-12 bg-primary rounded-lg flex items-center justify-center"
//                     onClick={() => handlePlanSelection('sportsMonthly')}
//                   >

//                     <p className="text-center text-neutral-900 text-xl font-bold">TRY FOR FREE</p>

//                   </button>
//                 </div>
//               </div>

//               <div className="bg-neutral-900 rounded-3xl flex flex-col w-full sm:w-1/2 p-6 justify-between">
//                 <h3 className="text-white text-2xl font-bold mb-4">Sports Annually</h3>
//                 <div className="w-28 bg-white rounded px-2 py-1 mb-4">
//                   <p className="opacity-95 text-neutral-900 text-xs font-bold">Save $20/year</p>
//                 </div>
//                 <div className="space-y-4 mb-4">
//                   <div className="flex items-center space-x-2">
//                     <img src={icons.checkMarkWhite} alt='img' className="w-2.5 h-2" />
//                     <p className="text-white text-base font-normal">In-depth players analytics for NBA, MLB, NCAAB, NCAAF, NHL, NFL</p>
//                   </div>
//                   <div className="flex items-center space-x-2">
//                     <img src={icons.checkMarkWhite} alt='img' className="w-2.5 h-2" />
//                     <p className="text-white text-base font-normal">Access to social feed where you can chat with other users and share insights</p>
//                   </div>
//                   <div className="flex items-center space-x-2">
//                     <img src={icons.checkMarkWhite} alt='img' className="w-2.5 h-2" />
//                     <p className="text-white text-base font-normal">First to access new exciting features to come</p>
//                   </div>
//                   <div className="flex items-center space-x-2">
//                     <img src={icons.checkMarkWhite} alt='img' className="w-2.5 h-2" />
//                     <p className="text-white text-base font-normal">7 day free trial</p>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-1 mb-3">
//                   <span className="text-white text-base font-normal">$</span>
//                   <span className="text-white text-2xl font-bold">99.99</span>
//                   <p className="text-white text-base font-normal">/year</p>
//                 </div>
//                 <button 
//                   className="w-full h-12 bg-primary rounded-lg flex items-center justify-center"
//                   onClick={() => handlePlanSelection('sportsAnnual')}
//                 >

//                   <p className="text-center text-neutral-900 text-xl font-bold">TRY FOR FREE</p>

//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="absolute inset-0 bg-black opacity-50 transition-opacity"></div>
//       </div>
//     )
//   );

// };


import React, { useState } from 'react';
import "../styles/Dashboard/dashboardStyle.css";
import { useDispatch } from 'react-redux';
import { icons } from "../assets";
import { createCheckoutSession } from '../redux/actions/UserActions';
import { useNavigate } from 'react-router-dom';

export const TryFreeButton = () => {
  const [isPremiumModalVisible, setPremiumModalVisible] = useState(false);

  const handleOpenModal = () => {
    setPremiumModalVisible(true);
  };

  const handleCloseModal = () => {
    setPremiumModalVisible(false);
  };

  return (
    <div>
      <button
        className="TryFreeButton sticky bg-primary text-black rounded-lg p-4 flex items-center space-x-2"
        onClick={handleOpenModal}
      >
        <span className="text-xl font-bold">Try For Free</span>
      </button>
      <TryFreeModal isVisible={isPremiumModalVisible} onClose={handleCloseModal} />
    </div>
  );
};

export const TryFreeModal = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePlanSelection = async (planType) => {
    try {
      const sessionResponse = await dispatch(createCheckoutSession(planType, navigate));
      if (sessionResponse?.session?.url) {
        window.location.href = sessionResponse.session.url; // Redirect to the external URL
      } else {
        console.error('No URL found in the session response');
      }
    } catch (error) {
      console.error('Error while creating checkout session:', error);
    }
  };

  return (
    isVisible && (
      <div
        className="fixed inset-0 flex items-center justify-center z-50"
        onClick={onClose}
      >
        <div
          className="relative bg-white rounded-xl shadow-lg z-50 w-full max-w-3xl mx-4 sm:mx-6 md:mx-8 overflow-y-auto max-h-[90vh]"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col w-full p-5 h-full justify-center items-center">
            <h1 className="opacity-95 text-neutral-900 text-3xl font-bold mb-2 text-center">The Right Plan for You</h1>
            <p className="opacity-70 text-center text-zinc-500 text-base font-normal mb-5">Connect. Win. Repeat.</p>

            <div className="flex flex-col sm:flex-row gap-4 mt-4 w-full">
              <div className="bg-neutral-100 rounded-3xl flex flex-col w-full sm:w-1/2 p-6 justify-between">
                <h3 className="text-neutral-900 text-2xl font-bold mb-4">Sports Monthly</h3>
                <div className="space-y-4 mb-4">
                  <div className="flex items-center space-x-2">
                    <img src={icons.checkMark} alt='img' className="w-2.5 h-2" />
                    <p className="text-neutral-900 text-base font-normal">In-depth players analytics for NBA, MLB, NCAAB, NCAAF, NHL, NFL</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <img src={icons.checkMark} alt='img' className="w-2.5 h-2" />
                    <p className="text-neutral-900 text-base font-normal">Access to social feed where you can chat with other users and share insights</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <img src={icons.checkMark} alt='img' className="w-2.5 h-2" />
                    <p className="text-neutral-900 text-base font-normal">First to access new exciting features to come</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <img src={icons.checkMark} alt='img' className="w-2.5 h-2" />
                    <p className="text-neutral-900 text-base font-normal">7 day free trial</p>
                  </div>
                </div>
                <div className="flex items-center space-x-1 mb-3">
                  <span className="text-neutral-900 text-base font-normal">$</span>
                  <span className="text-neutral-900 text-2xl font-bold">9.99</span>
                  <p className="text-neutral-900 text-base font-normal">/month</p>
                </div>
                <div className="mt-auto">
                  <button
                    className="w-full h-12 bg-primary rounded-lg flex items-center justify-center"
                    onClick={() => handlePlanSelection('sportsMonthly')}
                  >
                    <p className="text-center text-neutral-900 text-xl font-bold">TRY FOR FREE</p>
                  </button>
                </div>
              </div>

              <div className="bg-neutral-900 rounded-3xl flex flex-col w-full sm:w-1/2 p-6 justify-between">
                <h3 className="text-white text-2xl font-bold mb-4">Sports Annually</h3>
                <div className="w-28 bg-white rounded px-2 py-1 mb-4">
                  <p className="opacity-95 text-neutral-900 text-xs font-bold">Save $20/year</p>
                </div>
                <div className="space-y-4 mb-4">
                  <div className="flex items-center space-x-2">
                    <img src={icons.checkMarkWhite} alt='img' className="w-2.5 h-2" />
                    <p className="text-white text-base font-normal">In-depth players analytics for NBA, MLB, NCAAB, NCAAF, NHL, NFL</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <img src={icons.checkMarkWhite} alt='img' className="w-2.5 h-2" />
                    <p className="text-white text-base font-normal">Access to social feed where you can chat with other users and share insights</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <img src={icons.checkMarkWhite} alt='img' className="w-2.5 h-2" />
                    <p className="text-white text-base font-normal">First to access new exciting features to come</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <img src={icons.checkMarkWhite} alt='img' className="w-2.5 h-2" />
                    <p className="text-white text-base font-normal">7 day free trial</p>
                  </div>
                </div>
                <div className="flex items-center space-x-1 mb-3">
                  <span className="text-white text-base font-normal">$</span>
                  <span className="text-white text-2xl font-bold">99.99</span>
                  <p className="text-white text-base font-normal">/year</p>
                </div>
                <button 
                  className="w-full h-12 bg-primary rounded-lg flex items-center justify-center"
                  onClick={() => handlePlanSelection('sportsAnnual')}
                >
                  <p className="text-center text-neutral-900 text-xl font-bold">TRY FOR FREE</p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 bg-black opacity-50 transition-opacity"></div>
      </div>
    )
  );
};
