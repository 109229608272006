import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelSubscription,
  changePassword,
  updateUserProfile,
} from '../../redux/actions/UserActions';
import { icons } from '../../assets';
import ConfirmationDialog from '../ConfirmationDialog';
import SnackbarAlert from '../SnackbarAlert';

export const EditProfileModal = ({ isVisible, onClose, isPremium }) => {
  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

  // State for form fields
  const [fullName, setFullName] = useState(userInfo.name || '');
  const [headline, setHeadline] = useState(userInfo.headline || '');
  const [bio, setBio] = useState(userInfo.bio || '');
  const [profileImage, setProfileImage] = useState(userInfo.profileImg || '');
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const dispatch = useDispatch();

  const togglePasswordChange = () => setShowPasswordChange(!showPasswordChange);

  const handleImageChange = e => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setImageFile(file); // Set the file to state
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result); // Set preview image for display
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handlePasswordChange = () => {
    if (newPassword !== confirmNewPassword) {
      alert('New passwords do not match.');
      return;
    }
    // Dispatch action to change password or handle it as per your logic
    dispatch(changePassword(oldPassword, newPassword));
    // Reset password fields and close password change form
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setShowPasswordChange(false);
  };

  // Reset the form state when the modal is closed
  useEffect(() => {
    if (!isVisible) {
      setFullName(userInfo.name || '');
      setHeadline(userInfo.headline || '');
      setBio(userInfo.bio || '');
      setProfileImage(userInfo.profileImg || '');
      setPreviewImage(userInfo.profileImg || '');
      setImageFile(null);
    }
  }, [isVisible, userInfo]);

  const handleSave = () => {
    // Dispatch the updateUserProfile action with form data
    dispatch(
      updateUserProfile({
        userId: userInfo.id,
        name: fullName,
        bio,
        headline,
        profileImg: imageFile, // Send the file, not the image URL
      }),
    );
    onClose(); // Close modal after save
  };

  const handleCancelSubscriptionClick = () => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleConfirmCancelSubscription = async () => {
    const subscription = await dispatch(cancelSubscription());
    setSnackbarMessage(subscription.message);
    setOpenSnackbar(true);
    setOpenCancelDialog(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    isVisible && (
      <>
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
          onClick={onClose}
        >
          <div
            className="bg-white rounded-xl shadow-lg z-50 overflow-hidden w-3/5 lg:w-1/2 xl:w-2/5"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex flex-col w-full p-5 h-full justify-start items-start">
              <h1 className="opacity-95 text-neutral-900 text-3xl font-bold mb-2">
                Edit Profile
              </h1>
              <p className="text-neutral-600 text-sm mb-4">
                Provide details about yourself and any other pertinent
                information.
              </p>

              <div className="w-full mb-4">
                <label
                  className="block text-neutral-700 text-sm font-bold mb-2"
                  htmlFor="profileImage"
                >
                  Profile photo
                </label>
                <div className="flex items-center justify-between">
                  <div>
                    <button
                      className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="button"
                    >
                      <label htmlFor="imageUpload">Change</label>
                    </button>
                    <input
                      type="file"
                      id="imageUpload"
                      hidden
                      onChange={handleImageChange}
                    />
                  </div>
                  {previewImage ? (
                    <img
                      src={previewImage}
                      alt="Profile"
                      className="w-20 h-20 rounded-full mr-4"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      src={profileImage}
                      alt="Profile"
                      className="w-20 h-20 rounded-full mr-4"
                      loading="lazy"
                    />
                  )}
                </div>
              </div>

              <div className="w-full mb-4">
                <label
                  className="block text-neutral-700 text-sm font-bold mb-2"
                  htmlFor="fullName"
                >
                  Full name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="fullName"
                  type="text"
                  autoComplete="off"
                  value={fullName}
                  onChange={e => setFullName(e.target.value)}
                />
              </div>

              <div className="w-full mb-4">
                <label
                  className="block text-neutral-700 text-sm font-bold mb-2"
                  htmlFor="headline"
                >
                  Headline
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="headline"
                  type="text"
                  autoComplete="off"
                  value={headline}
                  onChange={e => setHeadline(e.target.value)}
                />
              </div>

              <div className="w-full mb-4">
                <label
                  className="block text-neutral-700 text-sm font-bold mb-2"
                  htmlFor="bio"
                >
                  Bio
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="bio"
                  autoComplete="off"
                  value={bio}
                  onChange={e => setBio(e.target.value)}
                />
              </div>

              <div className="w-full mb-4">
                <div className="flex flex-row justify-between">
                  <label
                    className="block text-neutral-700 text-sm font-bold mb-2"
                    htmlFor="bio"
                  >
                    Password
                  </label>
                  <button
                    className="bg-secondary hover:bg-secondary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4"
                    onClick={togglePasswordChange}
                  >
                    <img
                      src={icons.chevronRight}
                      className="w-4 h-3 transform rotate-90"
                      alt="dropdown"
                      loading="lazy"
                    />
                  </button>
                </div>

                {showPasswordChange && (
                  <div className="w-full mb-4">
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 mb-2 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                      autoComplete="old-password"
                      type="password"
                      placeholder="Old Password"
                      value={oldPassword}
                      onChange={e => setOldPassword(e.target.value)}
                    />
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 mb-2 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                      autoComplete="new-password"
                      type="password"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={e => setNewPassword(e.target.value)}
                    />
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                      autoComplete="confirm-password"
                      type="password"
                      placeholder="Confirm New Password"
                      value={confirmNewPassword}
                      onChange={e => setConfirmNewPassword(e.target.value)}
                    />
                    <button
                      className="bg-primary hover:bg-primary-dark text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={handlePasswordChange}
                    >
                      Save New Password
                    </button>
                  </div>
                )}
              </div>

              <div className="flex items-center justify-between w-full">
                <button
                  className="bg-primary hover:bg-primary-dark text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={handleSave}
                >
                  Save
                </button>

                {isPremium ? (
                  <button
                    className="bg-red hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleCancelSubscriptionClick}
                  >
                    Cancel Subscription
                  </button>
                ) : null}

                <ConfirmationDialog
                  open={openCancelDialog}
                  title="Cancel Subscription"
                  content="Are you sure you want to cancel your subscription?"
                  onCancel={handleCloseCancelDialog}
                  onConfirm={handleConfirmCancelSubscription}
                />
                <SnackbarAlert
                  open={openSnackbar}
                  message={snackbarMessage}
                  onClose={handleCloseSnackbar}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      </>
    )
  );
};
