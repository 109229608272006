import React from 'react';
import '../styles/Dashboard/footer.css';
import { FollowUsComponent } from '../pages/dashboard/components/FollowUsComponent';

export const Footer = ({ darkMode }) => {
  return (
    <div className={`footer w-full py-4 mt-10 ${darkMode ? 'bg-black text-white' : 'bg-neutral-50 text-black'} flex flex-col justify-center items-center border-t ${darkMode ? 'border-gray-800' : 'border-gray-300'}`}>
      <FollowUsComponent darkMode={darkMode} /> 
      <div className="text-center mb-4">
        <p>If you or someone you know has a gambling problem, call 1-800-GAMBLER for help.</p>
        <p className="text-sm">© 2024 El Parlay. All rights reserved.</p>
      </div>
    </div>
  );
};