
import { useEffect } from 'react';

// Custom hook to set the document title
const useDocumentTitle = (location) => {
  useEffect(() => {
    const path = location.pathname;
    let title = 'El Parlay'; // Default title

    // Define titles for specific routes
    if (path === '/') {
      title = 'Home - El Parlay';
    } else if (path.startsWith('/login')) {
      title = 'Login - El Parlay';
    } else if (path.startsWith('/register')) {
      title = 'Register - El Parlay';
    } else if (path.startsWith('/forgot-password')) {
      title = 'Forgot Password - El Parlay';
    } else if (path.startsWith('/reset-password')) {
      title = 'Reset Password - El Parlay';
    } else if (path.startsWith('/verification')) {
      title = 'Verification - El Parlay';
    } else if (path === '/sports') {
      title = 'Sports - El Parlay';
    } else if (path.match(/^\/sports\/team\/[\w-]+$/)) {
      title = 'Team Details - El Parlay';
    } else if (path.match(/^\/sports\/playerInfo\/[\w-]+$/)) {
      title = 'Player Information - El Parlay';
    } else if (path.match(/^\/sports\/gameSchedule\/[\w-]+$/)) {
      title = 'Game Schedule - El Parlay';
    } else if (path.match(/^\/sports\/propBets\/[\w-]+$/)) {
      title = 'Prop Bets Details - El Parlay';
    } else if (path.startsWith('/sports/selectPropBets')) {
      title = 'Select Prop Bets - El Parlay';
    } else if (path.startsWith('/sports/evChart')) {
      title = 'EV Chart - El Parlay';
    } else if (path.startsWith('/feed')) {
      title = 'Feed - El Parlay';
    } else if (path.startsWith('/inbox')) {
      title = 'Inbox - El Parlay';
    } else if (path.match(/^\/profile\/[\w-]+$/)) {
      title = 'Profile - El Parlay';
    } else if (path.startsWith('/cancel')) {
      title = 'Payment Cancelled - El Parlay';
    } else if (path.startsWith('/success')) {
      title = 'Payment Success - El Parlay';
    } else if (path.startsWith('/sportsbooks')) {
      title = 'Sportsbooks - El Parlay';
    } else if (path.startsWith('/education')) {
      title = 'Education - El Parlay';
    } else if (path.startsWith('/odds')) {
      title = 'Odds - El Parlay';
    }

    // Set the document title
    document.title = title;
  }, [location]);
};

export default useDocumentTitle;