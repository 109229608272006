import axiosInstance from "../../core/axios/axiosInstance";

const likePost = async (postId, userId) => {
  const response = await axiosInstance.post(`/like/like/${postId}`, { userId });
  return response.data;
};

const unlikePost = async (postId, userId) => {
  const response = await axiosInstance.delete(`/like/unlike/${postId}`, {params: { userId }});
  return response.data;
};

const getPostLikes = async (postId, userId) => {
  const response = await axiosInstance.get(`/like/likes/${postId}`, {params: { userId }});
  return response.data;
};

export const likesService = {
  likePost,
  unlikePost,
  getPostLikes
}