export const LIKE_POST_REQUEST = 'LIKE_POST_REQUEST';
export const LIKE_POST_SUCCESS = 'LIKE_POST_SUCCESS';
export const LIKE_POST_FAIL = 'LIKE_POST_FAIL';

export const UNLIKE_POST_REQUEST = 'UNLIKE_POST_REQUEST';
export const UNLIKE_POST_SUCCESS = 'UNLIKE_POST_SUCCESS';
export const UNLIKE_POST_FAIL = 'UNLIKE_POST_FAIL';

export const GET_POST_LIKES_REQUEST = 'GET_POST_LIKES_REQUEST';
export const GET_POST_LIKES_SUCCESS = 'GET_POST_LIKES_SUCCESS';
export const GET_POST_LIKES_FAIL = 'GET_POST_LIKES_FAIL';

export const IS_LIKED_REQUEST = 'IS_LIKED_REQUEST';
export const IS_LIKED_SUCCESS = 'IS_LIKED_SUCCESS';
export const IS_LIKED_FAIL = 'IS_LIKED_FAIL';
