import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SportsView from './SportsView';
import '../../styles/mobile/sportsMobile.css';
import { BackButton } from '../../components/BackButton';

export const SportsPage = () => {
  const backgroundImage = require('../../assets/icons/logo-background.jpg');
  const logoLight = require('../../assets/icons/logoDark.png');

  useEffect(() => {
    document.body.classList.add('sports-page');
    return () => {
      document.body.classList.remove('sports-page');
    };
  }, []);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center background-container"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '50% 100%',
        backgroundRepeat: 'repeat',
        backgroundPosition: 'left',
      }}
    >
      <div
        className="bg-white rounded-lg shadow-lg overflow-hidden p-4 mb-30 centered-content"
        style={{ width: '900px', height: '750px' }}
      >
        <div className="backLogo">
          <div className="sports-page-back-button">
            <BackButton />
          </div>
          <div className="logo-container modal-logo-wrapper">
            {/* <img src={logoLight} alt="New" className="logo" /> */}
            <Link to="/dashboard" className="modal-logo-img">
              <img
                className="logo"
                src={logoLight}
                alt="Logo"
                loading="lazy"
              />
            </Link>
          </div>
        </div>
        <SportsView />
      </div>
    </div>
  );
};
