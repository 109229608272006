// import React, { useState } from 'react';
// import '../../styles/UFC/ufc.css';
// import { getMoneylineForTeam, getMethodOfVictory, getTotalRounds } from '../../utils/sportStats/oddsFunctions';
// import { Navbar } from '../../components/Navbar';
// import { Sidebar } from '../../components/Sidebar/Sidebar';
// import { icons } from '../../assets';

// export const UFCMatchupView = ({ data, darkMode }) => {
//   const [showMore, setShowMore] = useState(false);

//   const matchup = data[0];
//   const { home_team: homeFighter, away_team: awayFighter, odds, start_date } = matchup;

//   const homeMoneyline = getMoneylineForTeam(odds, homeFighter);
//   const awayMoneyline = getMoneylineForTeam(odds, awayFighter);

//   const totalRoundsU = getTotalRounds(odds, 'under');
//   const totalRoundsO = getTotalRounds(odds, 'over');

//   // Categorize the odds
//   const methodOfVictory = odds.filter(odd => odd.id.includes('method_of_victory'));
//   const significantStrikes = odds.filter(odd => odd.id.includes('player_significant_strikes'));
//   const takedowns = odds.filter(odd => odd.id.includes('player_takedowns'));
//   const roundBetting = odds.filter(odd => odd.id.includes('round_betting'));
//   const goTheDistance = odds.filter(odd => odd.id.includes('go_the_distance'));
//   const methodOfEnding = odds.filter(odd => odd.id.includes('method_of_ending'));
//   const winningRound = odds.filter(odd => odd.id.includes('winning_round'));

//   const formatMethodOfVictory = () => {
//     const methods = {};
//     methodOfVictory.forEach(odd => {
//       const method = odd.name.split(' - ')[1];
//       if (!method) return;
//       if (!methods[method]) methods[method] = { [homeFighter]: '-', [awayFighter]: '-' };
//       if (odd.name.includes(homeFighter)) methods[method][homeFighter] = odd.price;
//       if (odd.name.includes(awayFighter)) methods[method][awayFighter] = odd.price;
//     });
//     return methods;
//   };

//   const formatRoundBetting = () => {
//     const rounds = {};
//     roundBetting.forEach(odd => {
//       const roundMatch = odd.name.match(/Round (\d+)/);
//       if (!roundMatch) return;
//       const round = roundMatch[1];
//       if (!rounds[round]) rounds[round] = { [homeFighter]: '-', [awayFighter]: '-' };
//       if (odd.name.includes(homeFighter)) rounds[round][homeFighter] = odd.price;
//       if (odd.name.includes(awayFighter)) rounds[round][awayFighter] = odd.price;
//     });
//     return rounds;
//   };

//   const formatOddsByCategory = (oddsArray) => {
//     const formattedOdds = {};
//     oddsArray.forEach(odd => {
//       if (!formattedOdds[odd.market_name]) {
//         formattedOdds[odd.market_name] = [];
//       }
//       formattedOdds[odd.market_name].push(odd);
//     });
//     return formattedOdds;
//   };

//   const formattedMethodOfEnding = formatOddsByCategory(methodOfEnding);
//   const formattedWinningRound = formatOddsByCategory(winningRound);

//   return (
//     <div className={`w-full h-screen flex flex-row overflow-hidden`}>
//       <Sidebar darkMode={darkMode}/>
//       <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
//         <div className={`sticky top-0 z-10`}>
//           <Navbar title="UFC Matchup" darkMode={darkMode}/>
//         </div>
//         <div className="ufc-container">
//           <div className="ufc-header">
//             <div className="ufc-fighter ufc-fighter-name">
//               <h1>{homeFighter}</h1>
//             </div>
//             <span>
//               <img className="vsImg" src={icons.vs} alt="vs" loading="lazy" />
//             </span>
//             <div className="ufc-fighter ufc-fighter-name">
//               <h1>{awayFighter}</h1>
//             </div>
//           </div>
//           {/* <p>{`Date: ${new Date(start_date).toLocaleDateString()}`}</p> */}
//           <div className="ufc-date">
//             {`Date: ${new Date(start_date).toLocaleDateString()}`}
//           </div>

//           <div className="ufc-odds-section">
//             <div className="ufc-odds-title">
//               <h3>Money Line</h3>
//               <h3 className="ufc-odds-title-right">Money Line</h3>
//             </div>
//             <div className="ufc-odds">
//               <span>{awayMoneyline ? awayMoneyline.price : 'N/A'}</span>
//               <span>{homeMoneyline ? homeMoneyline.price : 'N/A'}</span>
//             </div>
//           </div>

//           <div className="ufc-odds-section">
//             <div className="ufc-odds-title">
//               <h3>Total Rounds</h3>
//               <h3 className="ufc-odds-title-right">Total Rounds</h3>
//             </div>
//             <div className="ufc-odds">
//               <span>{totalRoundsO ? `O ${totalRoundsO.selection_points} ${totalRoundsO.price}` : 'N/A'}</span>
//               <span>{totalRoundsU ? `U ${totalRoundsU.selection_points} ${totalRoundsU.price}` : 'N/A'}</span>
//             </div>
//           </div>

//           {showMore && (
//             <>
//               <div className="ufc-odds-section">
//                 <div className="ufc-odds-title">
//                   <h3>Method of Victory</h3>
//                   <h3 className="ufc-odds-title-right">Method of Victory</h3>
//                 </div>
//                 <div className="ufc-odds-table-wrapper">
//                   <table className="ufc-odds-table">
//                     <thead>
//                       <tr>
//                         <th>{homeFighter}</th>
//                         <th>Method</th>
//                         <th>{awayFighter}</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {Object.entries(formatMethodOfVictory()).map(([method, prices], index) => (
//                         <tr key={index}>
//                           <td>{prices[homeFighter]}</td>
//                           <td>{method}</td>
//                           <td>{prices[awayFighter]}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>

//               <div className="ufc-odds-section">
//                 <div className="ufc-odds-title">
//                   <h3>Significant Strikes</h3>
//                   <h3 className="ufc-odds-title-right">Significant Strikes</h3>
//                 </div>
//                 <div className="ufc-odds-tables">
//                   <table className="ufc-odds-table">
//                     <thead>
//                       <tr>
//                         <th>{homeFighter}</th>
//                         <th>Price</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {significantStrikes.filter(odd => odd.name.includes(homeFighter)).map((odd, index) => (
//                         <tr key={index}>
//                           <td>{`${odd.selection_line === 'over' ? 'Over' : 'Under'} ${odd.selection_points}`}</td>
//                           <td>{odd.price}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                   <table className="ufc-odds-table">
//                     <thead>
//                       <tr>
//                         <th>{awayFighter}</th>
//                         <th>Price</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {significantStrikes.filter(odd => odd.name.includes(awayFighter)).map((odd, index) => (
//                         <tr key={index}>
//                           <td>{`${odd.selection_line === 'over' ? 'Over' : 'Under'} ${odd.selection_points}`}</td>
//                           <td>{odd.price}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>

//               <div className="ufc-odds-section">
//                 <div className="ufc-odds-title">
//                   <h3>Takedowns</h3>
//                   <h3 className="ufc-odds-title-right">Takedowns</h3>
//                 </div>
//                 <div className="ufc-odds-tables">
//                   <table className="ufc-odds-table">
//                     <thead>
//                       <tr>
//                         <th>{homeFighter}</th>
//                         <th>Price</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {takedowns.filter(odd => odd.name.includes(homeFighter)).map((odd, index) => (
//                         <tr key={index}>
//                           <td>{`${odd.selection_line === 'over' ? 'Over' : 'Under'} ${odd.selection_points}`}</td>
//                           <td>{odd.price}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                   <table className="ufc-odds-table">
//                     <thead>
//                       <tr>
//                         <th>{awayFighter}</th>
//                         <th>Price</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {takedowns.filter(odd => odd.name.includes(awayFighter)).map((odd, index) => (
//                         <tr key={index}>
//                           <td>{`${odd.selection_line === 'over' ? 'Over' : 'Under'} ${odd.selection_points}`}</td>
//                           <td>{odd.price}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>

//               <div className="ufc-odds-section">
//                 <div className="ufc-odds-title">
//                   <h3>Round Betting</h3>
//                   <h3 className="ufc-odds-title-right">Round Betting</h3>
//                 </div>
//                 <div className="ufc-odds-table-wrapper">
//                   <table className="ufc-odds-table">
//                     <thead>
//                       <tr>
//                         <th>{homeFighter}</th>
//                         <th>Round</th>
//                         <th>{awayFighter}</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {Object.entries(formatRoundBetting()).map(([round, prices], index) => (
//                         <tr key={index}>
//                           <td>{prices[homeFighter]}</td>
//                           <td>{round}</td>
//                           <td>{prices[awayFighter]}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>

//               <div className="ufc-odds-section">
//                 <div className="ufc-odds-title-centered">
//                   <h3>Method of Ending</h3>
//                 </div>
//                 <div className="ufc-odds-table-wrapper-centered">
//                   <table className="ufc-odds-table">
//                     <thead>
//                       <tr>
//                         <th>Market</th>
//                         <th>Odds</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {Object.entries(formattedMethodOfEnding).map(([market, odds], index) => (
//                         <tr key={index}>
//                           <td>{market}</td>
//                           <td>{odds.map(odd => (
//                             <div key={odd.id}>
//                               {odd.name}: {odd.price}
//                             </div>
//                           ))}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>

//               <div className="ufc-odds-section">
//                 <div className="ufc-odds-title-centered">
//                   <h3>Winning Round</h3>
//                 </div>
//                 <div className="ufc-odds-table-wrapper-centered">
//                   <table className="ufc-odds-table">
//                     <thead>
//                       <tr>
//                         <th>Market</th>
//                         <th>Odds</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {Object.entries(formattedWinningRound).map(([market, odds], index) => (
//                         <tr key={index}>
//                           <td>{market}</td>
//                           <td>{odds.map(odd => (
//                             <div key={odd.id}>
//                               {odd.name}: {odd.price}
//                             </div>
//                           ))}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>

//               <div className="ufc-odds-section">
//                 <div className="ufc-odds-title-centered">
//                   <h3>Go The Distance</h3>
//                 </div>
//                 <div className="ufc-odds">
//                   {goTheDistance.map((odd, index) => (
//                     <div key={index}>{`${odd.name} ${odd.price}`}</div>
//                   ))}
//                 </div>
//               </div>
//             </>
//           )}

//           <button className="ufc-show-more" onClick={() => setShowMore(!showMore)}>
//             {showMore ? 'Show Less' : 'Show More'}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };


import React, { useState } from 'react';
import '../../styles/UFC/ufc.css';
import { getMoneylineForTeam, getMethodOfVictory, getTotalRounds } from '../../utils/sportStats/oddsFunctions';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { icons } from '../../assets';

export const UFCMatchupView = ({ data, darkMode }) => {
  const [showMore, setShowMore] = useState(false);

  const matchup = data[0];
  const { home_team: homeFighter, away_team: awayFighter, odds, start_date } = matchup;

  const homeMoneyline = getMoneylineForTeam(odds, homeFighter);
  const awayMoneyline = getMoneylineForTeam(odds, awayFighter);

  const totalRoundsU = getTotalRounds(odds, 'under');
  const totalRoundsO = getTotalRounds(odds, 'over');

  // Categorize the odds
  const methodOfVictory = odds.filter(odd => odd.id.includes('method_of_victory'));
  const significantStrikes = odds.filter(odd => odd.id.includes('player_significant_strikes'));
  const takedowns = odds.filter(odd => odd.id.includes('player_takedowns'));
  const roundBetting = odds.filter(odd => odd.id.includes('round_betting'));
  const goTheDistance = odds.filter(odd => odd.id.includes('go_the_distance'));
  const methodOfEnding = odds.filter(odd => odd.id.includes('method_of_ending'));
  const winningRound = odds.filter(odd => odd.id.includes('winning_round'));

  const formatMethodOfVictory = () => {
    const methods = {};
    methodOfVictory.forEach(odd => {
      const method = odd.name.split(' - ')[1];
      if (!method) return;
      if (!methods[method]) methods[method] = { [homeFighter]: '-', [awayFighter]: '-' };
      if (odd.name.includes(homeFighter)) methods[method][homeFighter] = odd.price;
      if (odd.name.includes(awayFighter)) methods[method][awayFighter] = odd.price;
    });
    return methods;
  };

  const formatRoundBetting = () => {
    const rounds = {};
    roundBetting.forEach(odd => {
      const roundMatch = odd.name.match(/Round (\d+)/);
      if (!roundMatch) return;
      const round = roundMatch[1];
      if (!rounds[round]) rounds[round] = { [homeFighter]: '-', [awayFighter]: '-' };
      if (odd.name.includes(homeFighter)) rounds[round][homeFighter] = odd.price;
      if (odd.name.includes(awayFighter)) rounds[round][awayFighter] = odd.price;
    });
    return rounds;
  };

  const formatOddsByCategory = (oddsArray) => {
    const formattedOdds = {};
    oddsArray.forEach(odd => {
      if (!formattedOdds[odd.market_name]) {
        formattedOdds[odd.market_name] = [];
      }
      formattedOdds[odd.market_name].push(odd);
    });
    return formattedOdds;
  };

  const formattedMethodOfEnding = formatOddsByCategory(methodOfEnding);
  const formattedWinningRound = formatOddsByCategory(winningRound);

  return (
    <div className={`w-full h-screen flex flex-row overflow-hidden ${darkMode ? 'dark' : ''}`}>
      <Sidebar darkMode={darkMode}/>
      <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
        <div className={`sticky top-0 z-10`}>
          <Navbar title="UFC Matchup" darkMode={darkMode}/>
        </div>
        <div className={`ufc-container ${darkMode ? 'dark-mode' : ''}`}>
          <div className="ufc-header">
            <div className="ufc-fighter ufc-fighter-name">
              <h1>{homeFighter}</h1>
            </div>
            <span>
              <img className="vsImg" src={icons.vs} alt="vs" loading="lazy" />
            </span>
            <div className="ufc-fighter ufc-fighter-name">
              <h1>{awayFighter}</h1>
            </div>
          </div>
          <div className="ufc-date">
            {`Date: ${new Date(start_date).toLocaleDateString()}`}
          </div>

          <div className="ufc-odds-section">
            <div className="ufc-odds-title">
              <h3>Money Line</h3>
              <h3 className="ufc-odds-title-right">Money Line</h3>
            </div>
            <div className="ufc-odds">
              <span>{homeMoneyline ? homeMoneyline.price : 'N/A'}</span>
              <span>{awayMoneyline ? awayMoneyline.price : 'N/A'}</span>
            </div>
          </div>

          <div className="ufc-odds-section">
            <div className="ufc-odds-title">
              <h3>Total Rounds</h3>
              <h3 className="ufc-odds-title-right">Total Rounds</h3>
            </div>
            <div className="ufc-odds">
              <span>{totalRoundsO ? `O ${totalRoundsO.selection_points} ${totalRoundsO.price}` : 'N/A'}</span>
              <span>{totalRoundsU ? `U ${totalRoundsU.selection_points} ${totalRoundsU.price}` : 'N/A'}</span>
            </div>
          </div>

          {showMore && (
            <>
              <div className="ufc-odds-section">
                <div className="ufc-odds-title">
                  <h3>Method of Victory</h3>
                  <h3 className="ufc-odds-title-right">Method of Victory</h3>
                </div>
                <div className="ufc-odds-table-wrapper">
                  <table className="ufc-odds-table">
                    <thead>
                      <tr>
                        <th>{homeFighter}</th>
                        <th>Method</th>
                        <th>{awayFighter}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(formatMethodOfVictory()).map(([method, prices], index) => (
                        <tr key={index}>
                          <td>{prices[homeFighter]}</td>
                          <td>{method}</td>
                          <td>{prices[awayFighter]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="ufc-odds-section">
                <div className="ufc-odds-title">
                  <h3>Significant Strikes</h3>
                  <h3 className="ufc-odds-title-right">Significant Strikes</h3>
                </div>
                <div className="ufc-odds-tables">
                  <table className="ufc-odds-table">
                    <thead>
                      <tr>
                        <th>{homeFighter}</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {significantStrikes.filter(odd => odd.name.includes(homeFighter)).map((odd, index) => (
                        <tr key={index}>
                          <td>{`${odd.selection_line === 'over' ? 'Over' : 'Under'} ${odd.selection_points}`}</td>
                          <td>{odd.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table className="ufc-odds-table">
                    <thead>
                      <tr>
                        <th>{awayFighter}</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {significantStrikes.filter(odd => odd.name.includes(awayFighter)).map((odd, index) => (
                        <tr key={index}>
                          <td>{`${odd.selection_line === 'over' ? 'Over' : 'Under'} ${odd.selection_points}`}</td>
                          <td>{odd.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="ufc-odds-section">
                <div className="ufc-odds-title">
                  <h3>Takedowns</h3>
                  <h3 className="ufc-odds-title-right">Takedowns</h3>
                </div>
                <div className="ufc-odds-tables">
                  <table className="ufc-odds-table">
                    <thead>
                      <tr>
                        <th>{homeFighter}</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {takedowns.filter(odd => odd.name.includes(homeFighter)).map((odd, index) => (
                        <tr key={index}>
                          <td>{`${odd.selection_line === 'over' ? 'Over' : 'Under'} ${odd.selection_points}`}</td>
                          <td>{odd.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table className="ufc-odds-table">
                    <thead>
                      <tr>
                        <th>{awayFighter}</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {takedowns.filter(odd => odd.name.includes(awayFighter)).map((odd, index) => (
                        <tr key={index}>
                          <td>{`${odd.selection_line === 'over' ? 'Over' : 'Under'} ${odd.selection_points}`}</td>
                          <td>{odd.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="ufc-odds-section">
                <div className="ufc-odds-title">
                  <h3>Round Betting</h3>
                  <h3 className="ufc-odds-title-right">Round Betting</h3>
                </div>
                <div className="ufc-odds-table-wrapper">
                  <table className="ufc-odds-table">
                    <thead>
                      <tr>
                        <th>{homeFighter}</th>
                        <th>Round</th>
                        <th>{awayFighter}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(formatRoundBetting()).map(([round, prices], index) => (
                        <tr key={index}>
                          <td>{prices[homeFighter]}</td>
                          <td>{round}</td>
                          <td>{prices[awayFighter]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="ufc-odds-section">
                <div className="ufc-odds-title-centered">
                  <h3>Method of Ending</h3>
                </div>
                <div className="ufc-odds-table-wrapper-centered">
                  <table className="ufc-odds-table">
                    <thead>
                      <tr>
                        <th>Market</th>
                        <th>Odds</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(formattedMethodOfEnding).map(([market, odds], index) => (
                        <tr key={index}>
                          <td>{market}</td>
                          <td>{odds.map(odd => (
                            <div key={odd.id}>
                              {odd.name}: {odd.price}
                            </div>
                          ))}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="ufc-odds-section">
                <div className="ufc-odds-title-centered">
                  <h3>Winning Round</h3>
                </div>
                <div className="ufc-odds-table-wrapper-centered">
                  <table className="ufc-odds-table">
                    <thead>
                      <tr>
                        <th>Market</th>
                        <th>Odds</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(formattedWinningRound).map(([market, odds], index) => (
                        <tr key={index}>
                          <td>{market}</td>
                          <td>{odds.map(odd => (
                            <div key={odd.id}>
                              {odd.name}: {odd.price}
                            </div>
                          ))}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="ufc-odds-section">
                <div className="ufc-odds-title-centered">
                  <h3>Go The Distance</h3>
                </div>
                <div className="ufc-odds gtd">
                  {goTheDistance.map((odd, index) => (
                    <div key={index}>{`${odd.name} ${odd.price}`}</div>
                  ))}
                </div>
              </div>
            </>
          )}

          <button className="ufc-show-more" onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
    </div>
  );
};
