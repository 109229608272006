import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  calculateStatPerGame,
  formatPlayerAbbStats,
} from '../../../utils/sportStats/playerStats';

export const TeamStats = ({bgColor, players}) => {
  const navigate = useNavigate();
  const [formattedStats, setFormattedStats] = useState([]);

  useEffect(() => {
    if (players?.length > 0) {
      setFormattedStats(formatPlayerAbbStats(players[0]));
    }
  }, [players]);

  const generateStatMap = stats => {
    let mapArray = [];
    stats.forEach(stat => {
      mapArray.push({
        stat: stat?.stat,
        display: stat?.display,
      });
    });
    return mapArray;
  };

  const statMap = generateStatMap(formattedStats);

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full">
        <thead className="bg-slate-200" style={{backgroundColor: bgColor}}>
          <tr>
            <th className="px-2 py-2 text-xs sm:text-sm md:text-base font-bold text-center">
              Player
            </th>
            <th className="px-2 py-2 text-xs sm:text-sm md:text-base font-bold text-center">
              GP
            </th>
            {statMap &&
              statMap.map((stat, index) => (
                <th
                  key={index}
                  className="px-2 py-2 text-xs sm:text-sm md:text-base font-bold text-center"
                >
                  {stat.display}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {players &&
            players.length > 0 &&
            players.map((player, index) => {
              return (
                <tr
                  key={index}
                  onClick={() => navigate(`/sports/playerInfo/${player.id}`)}
                  className={`cursor-pointer ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors duration-150`}
                >
                  <td className="px-1 py-1 text-sm sm:text-base player-profile-td">
                    <div className="flex items-center gap-3">
                      <img
                        className="w-10 h-10 object-cover"
                        src={
                          player.logo ||
                          'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'
                        }
                        alt="Player"
                        loading="lazy"
                      />
                      <span>{player.player_name}</span>
                    </div>
                  </td>
                  <td className="text-center text-sm sm:text-base">
                    {(player?.results).length}
                  </td>
                  {statMap.map((stat, statIndex) => (
                    <td
                      key={statIndex}
                      className="text-center text-sm sm:text-base"
                    >
                      {calculateStatPerGame(player.results, stat.stat)}
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
