// import React, { useEffect, useState } from 'react';
// import '../../styles/Dashboard/dashboardStyle.css'
// import '../../styles/Dashboard/footer.css';
// import { Navbar } from '../../components/Navbar';
// import { Sidebar } from '../../components/Sidebar/Sidebar';
// import { TrendingTicker } from '../../components/TrendingTicker';
// import { UpcomingBettingOdds } from './components/UpcomingBettingOdds';
// import { MobileUpcomingBettingOdds } from './components/MobileUpcomingbettingodds';
// import { useDispatch, useSelector } from 'react-redux';
// import { getLatestNews } from '../../redux/actions/NewsActions';
// import { LatestNews } from './components/LatestNewsComponent';
// import { Footer } from '../../components/footer';
// import { getUpcomingMatchups } from '../../redux/actions/SportsActions';
// import moment from 'moment';

// const categoryToSportLeagueMap = {
//   NBA: { sport: 'basketball', league: 'NBA' },
//   NFL: { sport: 'football', league: 'NFL' },
//   NHL: { sport: 'hockey', league: 'NHL' },
//   MLB: { sport: 'baseball', league: 'MLB' },
//   UFC: { sport: 'mma', league: 'UFC' },
//   PGA: { sport: 'golf', league: 'PGA'},
//   WNBA: { sport: 'basketball', league: 'WNBA'},
// };

// export const DashboardView = ({ darkMode }) => {
//   const latestNews = useSelector(state => state.latestNews.latest);
//   const matchups = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();

//   const sports = ['NBA', 'NFL', 'MLB', 'NHL', 'UFC', 'PGA', 'WNBA',];
//   const [selectedSport, setSelectedSport] = useState(null);
//   const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

//   useEffect(() => {
//     dispatch(getLatestNews());

//     sports.forEach(sport => {
//       const { sport: sportType, league } = categoryToSportLeagueMap[sport];
//       dispatch(getUpcomingMatchups(sportType, league));
//     });
//   }, [dispatch]);

//   useEffect(() => {
//     if (matchups) {
//       const sportWithMostGames = sports.reduce((maxSport, sport) => {
//         const sportMatchups = matchups[sport] || [];
//         return sportMatchups.length > (matchups[maxSport] || []).length ? sport : maxSport;
//       }, sports[0]);

//       setSelectedSport(sportWithMostGames);
//     }
//   }, [matchups]);

//   const isMobile = window.innerWidth <= 600;

//   return (
//     <div className={`DashboardElParlay w-screen h-screen ${darkMode ? 'bg-black text-white' : 'bg-neutral-50 text-black'} flex flex-col lg:flex-row overflow-hidden`}>
//       <Sidebar darkMode={darkMode} />
//       <div className={`Dashboard flex flex-col h-screen overflow-y-auto ${darkMode ? 'bg-black' : 'bg-white'}`}>
//         <div className={`sticky top-0 z-20 ${darkMode ? 'bg-black' : 'bg-white'}`}>
//           <Navbar title="" darkMode={darkMode} />
//         </div>
//         <div className={`TrendingTicker  ${darkMode ? 'bg-black' : 'bg-white'}`}>
//           <TrendingTicker darkMode={darkMode} onSelectSport={setSelectedSport} selectedDate={selectedDate} onSelectDate={setSelectedDate} />
//         </div>
//         <div className="flex flex-col lg:flex-row space-y-4 ml-3 sm:ml-10 sm:mt-10 xl:space-y-0 xl:space-x-4">
//           <div className="Feed flex flex-col space-y-4 w-full pb-20">
//             <div className="flex flex-col">
//               <div className="flex flex-col md:flex-row items-center">
//                 <h2 className={`text-justify text-indigo-950 text-2xl md:text-3xl font-extrabold uppercase ${darkMode ? 'text-white' : 'text-black'}`}>
//                   TODAY'S BETTING ODDS
//                 </h2>
//                 <p className={`click-game-info ml-4 text-slate-500 italic mt-4 md:mt-0 ${darkMode ? 'text-white' : 'text-black'}`}>CLICK GAME FOR PLAYER PROP INSIGHTS</p>
//               </div>
//               <div className="sports-buttons flex flex-row w-full mt-4">
//                 {sports.map(sport => (
//                   <button
//                     key={sport}
//                     onClick={() => setSelectedSport(sport)}
//                     className={`sports-button flex-grow text-center text-base font-normal leading-snug px-1 ${selectedSport === sport ? (darkMode ? 'border-b-2 border-primary' : 'border-b-2 border-primary') : ''} ${darkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
//                   >
//                     {sport}
//                   </button>
//                 ))}
//               </div>
//               <div className="mt-5 mr-3">
//                 {isMobile ? (
//                   <MobileUpcomingBettingOdds selectedSport={selectedSport} darkMode={darkMode} />
//                 ) : (
//                   <UpcomingBettingOdds selectedSport={selectedSport} darkMode={darkMode} />
//                 )}
//               </div>
//             </div>
//             <LatestNews title="LATEST SPORTS NEWS" data={latestNews} darkMode={darkMode} />
//           </div>
//         </div>
//         <Footer darkMode={darkMode} />
//       </div>
//     </div>
//   );
// };





// adding the esports tab with val
// import React, { useEffect, useState } from 'react';
// import '../../styles/Dashboard/dashboardStyle.css';
// import '../../styles/Dashboard/footer.css';
// import { Navbar } from '../../components/Navbar';
// import { Sidebar } from '../../components/Sidebar/Sidebar';
// import { TrendingTicker } from '../../components/TrendingTicker';
// import { UpcomingBettingOdds } from './components/UpcomingBettingOdds';
// import { MobileUpcomingBettingOdds } from './components/MobileUpcomingbettingodds';
// import { useDispatch, useSelector } from 'react-redux';
// import { getLatestNews } from '../../redux/actions/NewsActions';
// import { LatestNews } from './components/LatestNewsComponent';
// import { Footer } from '../../components/footer';
// import { getUpcomingMatchups } from '../../redux/actions/SportsActions';
// import moment from 'moment';

// const categoryToSportLeagueMap = {
//   NBA: { sport: 'basketball', league: 'NBA' },
//   NFL: { sport: 'football', league: 'NFL' },
//   NHL: { sport: 'hockey', league: 'NHL' },
//   MLB: { sport: 'baseball', league: 'MLB' },
//   UFC: { sport: 'mma', league: 'UFC' },
//   PGA: { sport: 'golf', league: 'PGA' },
//   WNBA: { sport: 'basketball', league: 'WNBA' },
//   Valorant: { sport: 'esports', league: 'Valorant' },
//   'League of Legends': { sport: 'esports', league: 'League of Legends' },
//   CS2: { sport: 'esports', league: 'CS2' },
// };

// export const DashboardView = ({ darkMode }) => {
//   const latestNews = useSelector(state => state.latestNews.latest);
//   const matchups = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();

//   const sports = ['NBA', 'NFL', 'MLB', 'NHL', 'UFC', 'PGA', 'WNBA'];
//   const esports = ['Valorant', 'League of Legends', 'CS2'];
//   const [selectedSport, setSelectedSport] = useState(null);
//   const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
//   const [isEsportsSelected, setIsEsportsSelected] = useState(false);

//   useEffect(() => {
//     dispatch(getLatestNews());

//     [...sports, ...esports].forEach(sport => {
//       const { sport: sportType, league } = categoryToSportLeagueMap[sport];
//       dispatch(getUpcomingMatchups(sportType, league));
//     });
//   }, [dispatch]);

//   useEffect(() => {
//     if (matchups) {
//       const sportWithMostGames = sports.reduce((maxSport, sport) => {
//         const sportMatchups = matchups[sport] || [];
//         return sportMatchups.length > (matchups[maxSport] || []).length ? sport : maxSport;
//       }, sports[0]);

//       setSelectedSport(sportWithMostGames);
//     }
//   }, [matchups]);

//   const isMobile = window.innerWidth <= 600;

//   const handleSportClick = (sport) => {
//     if (sport === 'ESPORTS') {
//       setSelectedSport('Valorant'); // Set a specific esports league by default for debugging
//       setIsEsportsSelected(true);
//     } else {
//       setSelectedSport(sport);
//       setIsEsportsSelected(false);
//     }
//   };

//   return (
//     <div className={`DashboardElParlay w-screen h-screen ${darkMode ? 'bg-black text-white' : 'bg-neutral-50 text-black'} flex flex-col lg:flex-row overflow-hidden`}>
//       <Sidebar darkMode={darkMode} />
//       <div className={`Dashboard flex flex-col h-screen overflow-y-auto ${darkMode ? 'bg-black' : 'bg-white'}`}>
//         <div className={`sticky top-0 z-20 ${darkMode ? 'bg-black' : 'bg-white'}`}>
//           <Navbar title="" darkMode={darkMode} />
//         </div>
//         <div className={`TrendingTicker  ${darkMode ? 'bg-black' : 'bg-white'}`}>
//           <TrendingTicker darkMode={darkMode} onSelectSport={handleSportClick} selectedDate={selectedDate} onSelectDate={setSelectedDate}/>
//         </div>
//         <div className="flex flex-col lg:flex-row space-y-4 ml-3 sm:ml-10 sm:mt-10 xl:space-y-0 xl:space-x-4">
//           <div className="Feed flex flex-col space-y-4 w-full pb-20">
//             <div className="flex flex-col">
//               <div className="flex flex-col md:flex-row items-center">
//                 <h2 className={`text-justify text-indigo-950 text-2xl md:text-3xl font-extrabold uppercase ${darkMode ? 'text-white' : 'text-black'}`}>
//                   TODAY'S BETTING ODDS
//                 </h2>
//                 <p className={`click-game-info ml-4 text-slate-500 italic mt-4 md:mt-0 ${darkMode ? 'text-white' : 'text-black'}`}>CLICK GAME FOR PLAYER PROP INSIGHTS</p>
//               </div>
//               <div className="sports-buttons flex flex-row w-full mt-4">
//                 {sports.concat('ESPORTS').map(sport => (
//                   <button
//                     key={sport}
//                     onClick={() => handleSportClick(sport)}
//                     className={`sports-button flex-grow text-center text-base font-normal leading-snug px-1 ${selectedSport === sport || (isEsportsSelected && sport === 'ESPORTS') || (Array.isArray(selectedSport) && selectedSport.includes(sport)) ? (darkMode ? 'border-b-2 border-primary' : 'border-b-2 border-primary') : ''} ${darkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
//                   >
//                     {sport}
//                   </button>
//                 ))}
//               </div>
//               <div className="mt-5 mr-3">
//                 {isMobile ? (
//                   <MobileUpcomingBettingOdds selectedSport={selectedSport} darkMode={darkMode} />
//                 ) : (
//                   <UpcomingBettingOdds selectedSport={selectedSport} darkMode={darkMode} />
//                 )}
//               </div>
//             </div>
//             <LatestNews title="LATEST SPORTS NEWS" data={latestNews} darkMode={darkMode} />
//           </div>
//         </div>
//         <Footer darkMode={darkMode} />
//       </div>
//     </div>
//   );
// };



import React, { useEffect, useState } from 'react';
import '../../styles/Dashboard/dashboardStyle.css';
import '../../styles/Dashboard/footer.css';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { TrendingTicker } from '../../components/TrendingTicker';
import { UpcomingBettingOdds } from './components/UpcomingBettingOdds';
import { MobileUpcomingBettingOdds } from './components/MobileUpcomingbettingodds';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestNews } from '../../redux/actions/NewsActions';
import { LatestNews } from './components/LatestNewsComponent';
import { Footer } from '../../components/footer';
import { getUpcomingMatchups } from '../../redux/actions/SportsActions';
import moment from 'moment';

const categoryToSportLeagueMap = {
  NBA: { sport: 'basketball', league: 'NBA' },
  NFL: { sport: 'football', league: 'NFL' },
  // NHL: { sport: 'hockey', league: 'NHL' },
  MLB: { sport: 'baseball', league: 'MLB' },
  UFC: { sport: 'mma', league: 'UFC' },
  // PGA: { sport: 'golf', league: 'PGA' },
  WNBA: { sport: 'basketball', league: 'WNBA' },
  // Valorant: { sport: 'esports', league: 'Valorant' },
  // 'League of Legends': { sport: 'esports', league: 'League of Legends' },
  // CS2: { sport: 'esports', league: 'CS2' },
};

export const DashboardView = ({ darkMode }) => {
  const latestNews = useSelector(state => state.latestNews.latest);
  const matchups = useSelector(state => state.getUpcomingMatchups.matchups);
  const dispatch = useDispatch();

  // const sports = ['NBA', 'NFL', 'MLB', 'NHL', 'UFC', 'PGA', 'WNBA'];
  const sports = ['NBA', 'NFL', 'MLB', 'UFC', 'WNBA'];
  // const esports = ['Valorant', 'League of Legends', 'CS2'];
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  // const [isEsportsSelected, setIsEsportsSelected] = useState(false);

  useEffect(() => {
    dispatch(getLatestNews());

    // [...sports, ...esports].forEach(sport => {
      sports.forEach(sport => {
      const { sport: sportType, league } = categoryToSportLeagueMap[sport];
      dispatch(getUpcomingMatchups(sportType, league));
    });
  }, [dispatch]);

  useEffect(() => {
    if (matchups) {
      const sportWithMostGames = sports.reduce((maxSport, sport) => {
        const sportMatchups = matchups[sport] || [];
        return sportMatchups.length > (matchups[maxSport] || []).length ? sport : maxSport;
      }, sports[0]);

      setSelectedSport(sportWithMostGames);
    }
  }, [matchups]);

  const isMobile = window.innerWidth <= 600;

  const handleSportClick = (sport) => {
    // if (sport === 'ESPORTS') {
    //   setSelectedSport(esports); // Set an array of esports leagues
    //   setIsEsportsSelected(true);
    // } else {
      setSelectedSport(sport);
      // setIsEsportsSelected(false);
    // }
  };

  return (
    <div className={`DashboardElParlay w-screen h-screen ${darkMode ? 'bg-black text-white' : 'bg-neutral-50 text-black'} flex flex-col lg:flex-row overflow-hidden`}>
      <Sidebar darkMode={darkMode} />
      <div className={`Dashboard flex flex-col h-screen overflow-y-auto ${darkMode ? 'bg-black' : 'bg-white'}`}>
        <div className={`sticky top-0 z-20 ${darkMode ? 'bg-black' : 'bg-white'}`}>
          <Navbar title="" darkMode={darkMode} />
        </div>
        <div className={`TrendingTicker  ${darkMode ? 'bg-black' : 'bg-white'}`}>
          <TrendingTicker darkMode={darkMode} onSelectSport={handleSportClick} selectedDate={selectedDate} onSelectDate={setSelectedDate}/>
        </div>
        <div className="flex flex-col lg:flex-row space-y-4 px-2 sm:px-0 ml-0 sm:ml-10 sm:mt-10 xl:space-y-0 xl:space-x-4">
          <div className="Feed flex flex-col space-y-4 w-full pb-20">
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row items-center">
                <h2 className={`text-justify text-indigo-950 text-xl sm:text-2xl md:text-3xl font-extrabold uppercase ${darkMode ? 'text-white' : 'text-black'}`}>
                  TODAY'S BETTING ODDS
                </h2>
                <p className={`click-game-info ml-4 text-slate-500 italic mt-2 sm:mt-4 md:mt-0 ${darkMode ? 'text-white' : 'text-black'}`}>CLICK GAME FOR PLAYER PROP INSIGHTS</p>
              </div>
              <div className="sports-buttons flex flex-row w-full mt-4">
                {/* {sports.concat('ESPORTS').map(sport => ( */}
                {sports.map(sport => (
                  <button
                    key={sport}
                    onClick={() => handleSportClick(sport)}
                    // className={`sports-button flex-grow text-center text-base font-normal leading-snug px-1 ${selectedSport === sport || (isEsportsSelected && sport === 'ESPORTS') || (Array.isArray(selectedSport) && selectedSport.includes(sport)) ? (darkMode ? 'border-b-2 border-primary' : 'border-b-2 border-primary') : ''} ${darkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
                    className={`sports-button flex-grow text-center text-base font-normal leading-snug px-1 ${selectedSport === sport || (Array.isArray(selectedSport) && selectedSport.includes(sport)) ? (darkMode ? 'border-b-2 border-primary' : 'border-b-2 border-primary') : ''} ${darkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
                  >
                    {sport}
                  </button>
                ))}
              </div>
              <div className="mt-5 mr-0 sm:mr-3">
                {isMobile ? (
                  <MobileUpcomingBettingOdds selectedSport={selectedSport} darkMode={darkMode} />
                ) : (
                  <UpcomingBettingOdds selectedSport={selectedSport} darkMode={darkMode} />
                )}
              </div>
            </div>
            <LatestNews title="LATEST SPORTS NEWS" data={latestNews} darkMode={darkMode} />
          </div>
        </div>
        <Footer darkMode={darkMode} />
      </div>
    </div>
  );
};
