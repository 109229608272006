import {
  FOLLOW_TARGET_REQUEST,
  FOLLOW_TARGET_SUCCESS,
  FOLLOW_TARGET_FAIL,
  UNFOLLOW_TARGET_REQUEST,
  UNFOLLOW_TARGET_SUCCESS,
  UNFOLLOW_TARGET_FAIL,
  GET_TARGET_FOLLOWERS_REQUEST,
  GET_TARGET_FOLLOWERS_SUCCESS,
  GET_TARGET_FOLLOWERS_FAIL,
  GET_USER_FOLLOWERS_REQUEST,
  GET_USER_FOLLOWERS_SUCCESS,
  GET_USER_FOLLOWERS_FAIL,
  AM_I_FOLLOWING_REQUEST,
  AM_I_FOLLOWING_SUCCESS,
  AM_I_FOLLOWING_FAIL,
  AM_I_FOLLOWED_BY_REQUEST,
  AM_I_FOLLOWED_BY_SUCCESS,
  AM_I_FOLLOWED_BY_FAIL,
  REMOVE_FOLLOWER_REQUEST,
  REMOVE_FOLLOWER_SUCCESS,
  REMOVE_FOLLOWER_FAIL,
  GET_USER_FRIENDS_DATA_REQUEST,
  GET_USER_FRIENDS_DATA_SUCCESS,
  GET_USER_FRIENDS_DATA_FAIL,
} from '../constants/FollowsConstants.js';
import { followService } from '../../constants/services/follow.js';

// Follow Target
export const followTarget = (followerId, followedId) => async dispatch => {
  try {
    dispatch({type: FOLLOW_TARGET_REQUEST});

    const response = await followService.followTarget(followerId, followedId)

    dispatch({type: FOLLOW_TARGET_SUCCESS, payload: response});
  } catch (error) {
    dispatch({
      type: FOLLOW_TARGET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Unfollow Target
export const unfollowTarget = (followerId, followedId) => async dispatch => {
  try {
    dispatch({type: UNFOLLOW_TARGET_REQUEST});

    const response = await followService.unfollowTarget(followerId, followedId)

    dispatch({type: UNFOLLOW_TARGET_SUCCESS, payload: response});
  } catch (error) {
    dispatch({
      type: UNFOLLOW_TARGET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get Target Followers
export const getTargetFollowers = targetId => async dispatch => {
  try {
    dispatch({type: GET_TARGET_FOLLOWERS_REQUEST});

    const response = await followService.getTargetFollowers(targetId)

    dispatch({type: GET_TARGET_FOLLOWERS_SUCCESS, payload: response});
  } catch (error) {
    dispatch({
      type: GET_TARGET_FOLLOWERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get User Friends Data
export const getUserFriends = userId => async dispatch => {
  try {
    dispatch({type: GET_USER_FRIENDS_DATA_REQUEST});

    const response = await followService.getUserFriends(userId)

    dispatch({type: GET_USER_FRIENDS_DATA_SUCCESS, payload: response});
    return response;
  } catch (error) {
    dispatch({
      type: GET_USER_FRIENDS_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get User Followers
export const getUserFollowers = userId => async dispatch => {
  try {
    dispatch({type: GET_USER_FOLLOWERS_REQUEST});

    const response = await followService.getUserFollowers(userId)

    dispatch({type: GET_USER_FOLLOWERS_SUCCESS, payload: response});
  } catch (error) {
    dispatch({
      type: GET_USER_FOLLOWERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Check if I am following another user
export const amIFollowing = (myId, targetUserId) => async dispatch => {
  try {
    dispatch({type: AM_I_FOLLOWING_REQUEST});

    const response = await followService.amIFollowing(myId, targetUserId)

    dispatch({type: AM_I_FOLLOWING_SUCCESS, payload: response});
    return response;
  } catch (error) {
    dispatch({
      type: AM_I_FOLLOWING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Check if I am followed by another user
export const amIFollowedBy = (myId, targetUserId) => async dispatch => {
  try {
    dispatch({type: AM_I_FOLLOWED_BY_REQUEST});

    const response = await followService.amIFollowedBy(myId, targetUserId)

    dispatch({type: AM_I_FOLLOWED_BY_SUCCESS, payload: response});
    return response;
  } catch (error) {
    dispatch({
      type: AM_I_FOLLOWED_BY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Remove a follower
export const removeFollower = followerId => async dispatch => {
  try {
    dispatch({type: REMOVE_FOLLOWER_REQUEST});

    const response = await followService.removeFollower(followerId)

    dispatch({type: REMOVE_FOLLOWER_SUCCESS, payload: response});
  } catch (error) {
    dispatch({
      type: REMOVE_FOLLOWER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
