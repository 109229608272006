import axiosInstance from "../../core/axios/axiosInstance";

const createPost = async (formData, customConfig = {}) => {
  const defaultConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const config = {
    ...defaultConfig,
    ...customConfig,
    headers: {
      ...defaultConfig.headers,
      ...customConfig.headers,
    },
  };

  const response = await axiosInstance.post('/post/create', formData, config);
  return response.data;
};

const getPost = async (id) => {
  const response = await axiosInstance.get(`/post/${id}`);
  return response.data;
};

const getUserPosts = async (userId) => {
  const response = await axiosInstance.get(`/post/user/${userId}`);
  return response.data;
};

const deletePost = async (id) => {
  const response = await axiosInstance.delete(`/post/${id}`);
  return response.data;
};

const getFeedPosts = async (userId, page, limit) => {
  const response = await axiosInstance.get(`/post/feed/${userId}?page=${page}&limit=${limit}`);
  return response.data;
};

export const postService = {
  createPost,
  getPost,
  getUserPosts,
  deletePost,
  getFeedPosts
}