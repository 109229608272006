// export const BoxScoresTable = ({boxScores}) => {
//   return (
//     <div className="flex flex-col w-full bg-white p-4 drop-shadow-sm">
//       <table>
//         <thead>
//           <tr className="text-center">
//             <th></th>
//             <th>1</th>
//             <th>2</th>
//             <th>3</th>
//             <th>4</th>
//             <th>T</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr className="text-center">
//             <td>{boxScores.away_team_abb}</td>
//             <td>{boxScores.score_away_period_1}</td>
//             <td>{boxScores.score_away_period_2}</td>
//             <td>{boxScores.score_away_period_3}</td>
//             <td>{boxScores.score_away_period_4}</td>
//             <td>{boxScores.score_away_total}</td>
//           </tr>

//           <tr className="text-center">
//             <td>{boxScores.home_team_abb}</td>
//             <td>{boxScores.score_home_period_1}</td>
//             <td>{boxScores.score_home_period_2}</td>
//             <td>{boxScores.score_home_period_3}</td>
//             <td>{boxScores.score_home_period_4}</td>
//             <td>{boxScores.score_home_total}</td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// };


export const BoxScoresTable = ({boxScores, sport}) => {
  const isMLB = sport === 'MLB';
  const periods = isMLB ? 9 : 4;
  const columnWidth = 10;
  const teamAndTotalWidth = 10;
  const totalWidth = (periods * columnWidth) + (teamAndTotalWidth * 2);

  return (
    <div className="flex flex-col w-full bg-white p-4 drop-shadow-sm overflow-x-auto">
      <table style={{ width: `${totalWidth}px` }}>
        <thead>
          <tr className="text-center">
            <th style={{ width: `${teamAndTotalWidth}px` }}></th>
            {Array.from({ length: periods }).map((_, index) => (
              <th key={index + 1} style={{ width: `${columnWidth}px` }}>{index + 1}</th>
            ))}
            <th style={{ width: `${teamAndTotalWidth}px` }}>T</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td style={{ width: `${teamAndTotalWidth}px` }}>{boxScores.away_team_abb}</td>
            {Array.from({ length: periods }).map((_, index) => (
              <td key={index + 1} style={{ width: `${columnWidth}px` }}>{boxScores[`score_away_period_${index + 1}`]}</td>
            ))}
            <td style={{ width: `${teamAndTotalWidth}px` }}>{boxScores.score_away_total}</td>
          </tr>

          <tr className="text-center">
            <td style={{ width: `${teamAndTotalWidth}px` }}>{boxScores.home_team_abb}</td>
            {Array.from({ length: periods }).map((_, index) => (
              <td key={index + 1} style={{ width: `${columnWidth}px` }}>{boxScores[`score_home_period_${index + 1}`]}</td>
            ))}
            <td style={{ width: `${teamAndTotalWidth}px` }}>{boxScores.score_home_total}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
