import {
  CREATE_POST_FAIL,
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  DELETE_POST_FAIL,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  GET_POST_FAIL,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  GET_USER_POSTS_FAIL,
  GET_USER_POSTS_REQUEST,
  GET_USER_POSTS_SUCCESS,
  GET_FEED_POSTS_FAIL,
  GET_FEED_POSTS_REQUEST,
  GET_FEED_POSTS_SUCCESS,
} from '../constants/PostConstants';
import { postService } from '../../constants/services/posts';

// Create a post
export const createPost = (formData) => async (dispatch) => {
  try {
    const userId = formData.get('userId');
    dispatch({ type: CREATE_POST_REQUEST });

    const response = await postService.createPost(formData);

    dispatch({ type: CREATE_POST_SUCCESS, payload: response });
    //dispatch(getFeedPosts(userId, 1, 5))
  } catch (error) {
    dispatch({
      type: CREATE_POST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

// Get a specific post by ID
export const getPost = id => async dispatch => {
  try {
    dispatch({type: GET_POST_REQUEST});

    const response = await postService.getPost(id)

    dispatch({type: GET_POST_SUCCESS, payload: response});
  } catch (error) {
    dispatch({
      type: GET_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get all posts by a specific user
export const getUserPosts = userId => async dispatch => {
  try {
    dispatch({type: GET_USER_POSTS_REQUEST});

    const response = await postService.getUserPosts(userId)

    dispatch({type: GET_USER_POSTS_SUCCESS, payload: response});
  } catch (error) {
    dispatch({
      type: GET_USER_POSTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Remove a post
export const deletePost = id => async dispatch => {
  try {
    dispatch({type: DELETE_POST_REQUEST});

    await postService.deletePost(id);

    dispatch({type: DELETE_POST_SUCCESS});
  } catch (error) {
    dispatch({
      type: DELETE_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get feed posts
export const getFeedPosts = (userId, page, limit) => async dispatch => {
  try {
    dispatch({type: GET_FEED_POSTS_REQUEST});

    const response = await postService.getFeedPosts(userId, page, limit)

    dispatch({type: GET_FEED_POSTS_SUCCESS, payload: response});
    return response;
  } catch (error) {
    dispatch({
      type: GET_FEED_POSTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
