import { Loading } from '../../components/LoadingComponent';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { OddsTable } from './components/OddsTable';
import TeamsCards from './components/TeamsCards';
import { TrendingTicker } from '../../components/TrendingTicker';
import React, { useState, useEffect } from 'react';
import '../../styles/mobile/gameSheduleViewMobile.css';

export const GameScheduleView = ({ teams, odds, league, darkMode, selectedSport }) => {
  const EASTERN_CONFERENCE_TEAMS = [
    'Atlanta Hawks',
    'Boston Celtics',
    'Brooklyn Nets',
    'Charlotte Hornets',
    'Chicago Bulls',
    'Cleveland Cavaliers',
    'Detroit Pistons',
    'Indiana Pacers',
    'Miami Heat',
    'Milwaukee Bucks',
    'New York Knicks',
    'Orlando Magic',
    'Philadelphia 76ers',
    'Toronto Raptors',
    'Washington Wizards',
  ];

  const WESTERN_CONFERENCE_TEAMS = [
    'Dallas Mavericks',
    'Denver Nuggets',
    'Golden State Warriors',
    'Houston Rockets',
    'Los Angeles Clippers',
    'Los Angeles Lakers',
    'Memphis Grizzlies',
    'Minnesota Timberwolves',
    'New Orleans Pelicans',
    'Oklahoma City Thunder',
    'Phoenix Suns',
    'Portland Trail Blazers',
    'Sacramento Kings',
    'San Antonio Spurs',
    'Utah Jazz',
  ];

  const EAST_WNBA = [
    'New York Liberty',
    'Connecticut Sun',
    'Chicago Sky',
    'Indiana Fever',
    'Atlanta Dream',
    'Washington Mystics',
  ];

  const WEST_WNBA = [
    'Dallas Wings',
    'Las Vegas Aces',
    'Los Angeles Sparks',
    'Minnesota Lynx',
    'Phoenix Mercury',
    'Seattle Storm',
  ];

  // const MLS_EAST = [
  //   'Club Internacional de Fútbol Miami',
  //   'FC Cincinnati',
  //   'New York Red Bulls',
  //   'Charlotte FC',
  //   'Columbus Crew',
  //   'New York City Football Club',
  //   'Nashville SC',
  //   'Toronto FC',
  //   'Atlanta United FC',
  //   'Orlando City SC',
  //   'Philadelphia Union',
  //   'New England Revolution',
  //   'DC United',
  //   'Club de Foot Montréal',
  //   'Chicago Fire FC',
  // ];

  // const MLS_WEST = [
  //   'Los Angeles FC',
  //   'Real Salt Lake',
  //   'LA Galaxy',
  //   'Colorado Rapids',
  //   'Minnesota United FC',
  //   'Portland Timbers',
  //   'Houston Dynamo FC',
  //   'Austin FC',
  //   'Vancouver Whitecaps FC',
  //   'Seattle Sounders FC',
  //   'FC Dallas',
  //   'St. Louis City SC',
  //   'Sporting Kansas City',
  //   'San Jose Earthquakes',
  // ];

  const easternTeams = league === 'NBA' ? teams?.filter(team => EASTERN_CONFERENCE_TEAMS.includes(team.team_name)) : null;
  const westernTeams = league === 'NBA' ? teams?.filter(team => WESTERN_CONFERENCE_TEAMS.includes(team.team_name)) : null;

  const eastWNBAteams = league === 'WNBA' ? teams?.filter(team => EAST_WNBA.includes(team.team_name)) : null;
  const westWNBAteams = league === 'WNBA' ? teams?.filter(team => WEST_WNBA.includes(team.team_name)) : null;

  // const eastMLSTeams = league === 'USA - Major League Soccer' ? teams?.filter(team => MLS_EAST.includes(team.team_name)) : null;
  // const westMLSTeams = league === 'USA - Major League Soccer' ? teams?.filter(team => MLS_WEST.includes(team.team_name)) : null;

  const [selectedButton, setSelectedButton] = useState(selectedSport);

  useEffect(() => {
    if (selectedSport) {
      setSelectedButton(selectedSport);
    }
  }, [selectedSport]);

  return (
    <div className={`w-full h-screen ${darkMode ? 'bg-black text-white' : 'bg-neutral-50 text-black'} flex flex-row overflow-hidden`}>
      <Sidebar darkMode={darkMode} />
      <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
          <div className={`sticky top-0 z-20 ${darkMode ? 'bg-black' : 'bg-white'}`}>
            <Navbar title={league} darkMode={darkMode} />
          </div>
          <div className={`TrendingTicker sticky z-20 ${darkMode ? 'bg-black' : 'bg-white'}`}>
            <TrendingTicker darkMode={darkMode} onSelectSport={setSelectedButton} league={league}  selectedSport={selectedButton}/>
          </div>
      {teams && teams.length > 0 ? (
        <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
          <div className="px-3 sm:px-0 mt-10 ml-0 sm:ml-5 md:ml-10 flex flex-col md:flex-row h-full w-full">
            <div className="flex flex-col sm:flex-row md:flex-col w-full sm:w-11/12 md:w-1/5 gap-3">
              {league === 'NBA' ? (
                <>
                  <TeamsCards sportsData={easternTeams} title="Eastern Conference" darkMode={darkMode} />
                  <TeamsCards sportsData={westernTeams} title="Western Conference" darkMode={darkMode} />
                </>
              ) : league === 'WNBA' ? (
                <>
                  <TeamsCards sportsData={eastWNBAteams} title="Eastern Conference" darkMode={darkMode} />
                  <TeamsCards sportsData={westWNBAteams} title="Western Conference" darkMode={darkMode} />
                </>
              // ) : league === 'USA - Major League Soccer' ? (
              //   <>
              //     <TeamsCards sportsData={eastMLSTeams} title="Eastern Conference" />
              //     <TeamsCards sportsData={westMLSTeams} title="Western Conference" />
              //   </>
              ) : (
                <TeamsCards sportsData={teams} title={league} darkMode={darkMode} />
              )}
            </div>
            <div className="md:ml-10 w-full sm:w-11/12 md:w-4/6 pb-24 sm:pb-0">
              {odds && odds.length > 0 ? (
                <OddsTable oddsData={odds} sportsData={teams} darkMode={darkMode} league={league}/>
              ) : (
                // <div className={`content h-full w-full flex items-center justify-center ${darkMode ? 'bg-black' : 'bg-white'} animate-bg`}>
                //     <p>No matchups to display</p>
                // </div>
                <div className={`content h-full w-full flex items-center justify-center ${darkMode ? 'bg-black' : 'bg-white'} animate-bg`}>
                  <Loading />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={`content h-screen w-screen flex items-center justify-center ${darkMode ? 'bg-black' : 'bg-white'} animate-bg`}>
          <Loading />
        </div>
      )}
      </div>
    </div>
  );
};
