import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';

export const EducationView = ({ darkMode }) => {
  return (
    <div className={` w-full h-screen ${darkMode ? 'bg-black text-white' : 'bg-[#ECECF4] text-gray-900'} flex flex-row overflow-hidden`}>
      <Sidebar />
      <div className="flex flex-col w-full h-screen overflow-y-auto overflow-x-hidden">
        <div className={`sticky top-0 z-10 ${darkMode ? 'bg-black' : 'bg-white'}`}>
          <Navbar title="Sportsbooks" darkMode={darkMode}/>
        </div>

        {/* Construction message */}
        <div className="flex-grow p-4">
          <div className="bg-yellow-200 border border-yellow-400 text-yellow-800 p-4 rounded-md shadow-md">
            <p className="font-semibold">This page is under construction.</p>
            <p className="text-sm">We're working hard to bring you the best content. Check back soon!</p>
          </div>
        </div>
      </div>
    </div>
  );
};